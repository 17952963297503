import React from 'react';

const Item = (product) => {
    const {
        productName,
        productThumbnail,
        productPrice,
        quantity
    } = product;

    return (
        <tr>
            <td>
                <div className="product-item"><a className="product-thumb" href="shop-single.html"><img src={productThumbnail} alt={productName} /></a>
                    <div className="product-info">
                        <h4 className="product-title"><a href="shop-single.html">{productName}<small>x {quantity}</small></a></h4>
                    </div>
                </div>
            </td>
            <td className="text-center text-lg text-medium">{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
            <td className="text-center text-lg text-medium">{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productPrice * quantity)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
        </tr>
    )

}

export default Item;