import React from 'react';
import { createStructuredSelector } from 'reselect';
import { selectWishItems } from "../../redux/Wish/wish.selectors";
import { useSelector } from "react-redux";
import Item from './Item';

const mapState = createStructuredSelector({
    wishItems: selectWishItems
});

const WishListDetail = () => {
    const { wishItems } = useSelector(mapState);

    return (
        <React.Fragment>
            <div className="table-responsive wishlist-table margin-bottom-none">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Product Name</th>

                        </tr>
                    </thead>

                    {wishItems.map((item, pos) => {
                        return (
                            <tbody key={pos}>

                                <Item {...item} />

                            </tbody>
                        );
                    })}


                </table>
            </div>
            <hr className="mb-4" />
            <div className="custom-control custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="inform_me" />
                <label className="custom-control-label" htmlFor="inform_me">Informarme cuando el artículo en mi lista de deseos esté disponible</label>
            </div>
        </React.Fragment>
    )
}

export default WishListDetail;