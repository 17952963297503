import React from 'react';
import Button from './../Forms/Button';

const LoadMore = ({
    onLoadMoreEvt = () => { },
}) => {
    return (
        <div className="column text-right">
            <Button className="btn btn btn-primary scale-up delay-1" onClick={() => onLoadMoreEvt()}>
                Cargar Mas <i className="icon-arrow-right"></i>
            </Button>
        </div>
    );
};

export default LoadMore;