import React, { useEffect } from 'react';
import Footer from '../../Footer';
import Header from '../../Header';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const mapState1 = ({ ordersData }) => ({
    orderHistory: ordersData.orderHistory.data,
    orderDetails: ordersData.orderDetails
});

const CompleteWizard = props => {
    const { orderDetails } = useSelector(mapState1);

    useEffect(() => {

    }, []);

    return (
        <React.Fragment>

            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Checkout</h1>
                    </div>
                    <div className="column">
                        <ul className="breadcrumbs">
                            <li><NavLink to="/search">Home</NavLink>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Checkout</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container padding-bottom-3x mb-2">
                <div className="card text-center">
                    <div className="card-body padding-top-2x">
                        <h3 className="card-title">Gracias por tu compra!</h3>
                        <p className="card-text">Tu pedido ha sido realizado y será procesado lo antes posible</p>
                        <p className="card-text">Se generó el número de orden <span className="text-medium">{orderDetails}</span></p>
                        <p className="card-text">En breve recibirás un correo electrónico con la confirmación de tu pedido.
                            <u>Ahora puedes:</u>
                        </p>
                        <div className="padding-top-1x padding-bottom-1x"><NavLink className="btn btn-outline-secondary" to="/search">Volver a Tienda</NavLink><NavLink className="btn btn-outline-primary" to="/orders"><i className="icon-location"></i>&nbsp;Ver Ordenes</NavLink></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CompleteWizard;