import React from 'react';
import Checkout from './../../components/Checkout';

const Cart = () => {
    return (
        <div>
            <Checkout />
        </div>
    );
}

export default Cart;