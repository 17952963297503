import React from 'react';
import AddressDetails from './../../components/AddressDetails';

const Address = props => {
    return (
        <div className="container padding-bottom-3x mb-1">
            <div className="row">

                <div className="col-lg-8">
                    <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                    <AddressDetails />
                </div>
            </div>
        </div>
    )
}

export default Address;