import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import userIMG from './../../assets/img/account/login-user.png';

const UserProfile = props => {
    const { currentUser } = props;
    const { displayName } = currentUser;

    return (

        <div className="user-info">
            <div className="user-avatar"><Link className="edit-avatar" to="/"></Link><img src={userIMG} alt="User" /></div>
            <div className="user-data">
                <h4>{displayName && displayName}</h4>
            </div>
        </div>

    );
}

export default UserProfile;