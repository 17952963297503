import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Button from './../../Forms/Button';
import { useDispatch } from 'react-redux';
import { addProduct } from './../../../redux/Cart/cart.actions';
import { addWishProduct } from './../../../redux/Wish/wish.actions';
import ImageWithFallback from '../../../Utils/ImageWithFallback';

const ProductSearch = (product) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        objectID,
        productThumbnail,
        productName,
        productPrice,
        productNormalPrice,
        validImage
    } = product;
    if (!objectID || !productName ||
        typeof productPrice === 'undefined') return null;
    // if (!documentID || !productThumbnail || !productName ||
    //     typeof productPrice === 'undefined') return null;

    // useEffect(() => {
    //     if (product) {
    //         product.map((item) => {
    //             console.log('item', item);
    //             if (!item.productThumbnail) {
    //                 item.productThumbnail = '';
    //             }
    //         })
    //     }
    // }, [product])

    const configAddToCartBtn = {
        type: 'button'
    };

    const configAddToWishBtn = {
        type: 'button'

    };



    const handleAddToWish = (product) => {
        if (!product) return;
        dispatch(
            addWishProduct(product)
        );
        history.push('/wish');
    };

    console.log('product', product);


    const handleAddToCart = (product) => {
        if (!product) return;
        dispatch(
            addProduct(product)
        );
        history.push('/cart');
    };


    return (

        <div className="grid-item">
            <div className="product-card-grid">
                <div className="product-badge text-danger"></div>
                <NavLink to={`/product/${objectID}`} className="product-thumb">
                    {/* {productThumbnail &&
                        <img src={productThumbnail} alt={productName} />
                    }
                    {!productThumbnail &&
                        <img src="assets/img/white.png" alt={productName} />
                    } */}
                    {validImage === 1 &&
                        <ImageWithFallback
                            src='assets/img/white.png'
                            fallbackSrc={'assets/img/white.png'}
                            alt='Imagen'
                        />
                    }
                    {productThumbnail !== null && validImage !== 1 &&
                        <ImageWithFallback
                            src={productThumbnail}
                            fallbackSrc={'assets/img/white.png'}
                            alt='Imagen'
                        />
                    }
                </NavLink>
                <h3 className="product-title">
                    <NavLink to={`/product/${objectID}`}>
                        {productName}
                    </NavLink>
                </h3>
                <h4 className="product-price">
                    {productNormalPrice && <del>{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productNormalPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</del>}{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h4>
                <div className="product-buttons">
                    <Button {...configAddToWishBtn} onClick={() => handleAddToWish(product)} className="btn btn-outline-secondary btn-sm btn-wishlist" data-toggle="tooltip" title="Whishlist"><i className="icon-heart"></i></Button>
                    <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)} className="btn btn-outline-primary btn-sm" data-toast data-toast-type="success" data-toast-position="topRight" data-toast-icon="icon-circle-check" data-toast-title="Producto" data-toast-message="agregado al carrito">
                        Agregar
                    </Button>
                </div>
            </div>
        </div>

    );
};

export default ProductSearch;