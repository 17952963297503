import React from 'react'

const AboutDetail = () => {
  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>Nosotros</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li><a href="index.html">Home</a>
              </li>
              <li className="separator">&nbsp;</li>
              <li><a href="blog-ns.html">About</a>
              </li>
              <li className="separator">&nbsp;</li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container padding-bottom-3x mb-2">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div>
              <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/miShop%2FBanner%20nosotros.png?alt=media&token=631a24f0-af46-4c93-84ee-fa8331d45e20" />
            </div>
            <h2 className="padding-top-2x">Quienes Somos?</h2>
            <p>Somos un grupo de guatemaltecos  que ante la necesidad de adquirir artículos sin salir de casa o sin enfrentarse con la problemática del tráfico, pensamos en emprender..</p>
            <p>Como todos, hemos comprado productos en línea que o bien no cumplían nuestras necesidades, o no eran entregados de una forma que nos diera confianza. Es ahí cuando se nos ocurrió proveer no sólo un servicio sino una experiencia grata a cualquiera que confíe en nosotros.</p>
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <blockquote className="margin-top-1x margin-bottom-1x">
                  <p>Con la meta de mejorar la experiencia de venta y compra en el territorio guatemalteco, ideamos formar una empresa y nace miShop.gt en el año 2,020.</p>
                  {/* <cite>Someone famous</cite> */}
                </blockquote>
              </div>
            </div>
            <p className="mt-2">A la vez, siendo conscientes de la situación ambiental de nuestra región; pensamos en soluciones amigables con el ambiente y de esta forma evitar ser una empresa más, que produzca material de desecho que contamine nuestro planeta.</p>
            <p className="mt-2">Dado esto, vimos la necesidad de modificar el servicio de paquetería y ser así una solución que sume a mantener nuestros lagos, ríos y tierra más limpios.</p>
            <p className="mt-2">Al comprar con nosotros el empaque es amigable con el medio ambiente, y dependiendo del tamaño puedes recibirlo dentro de una bolsa reciclable que te servirá para tus compras en cualquier supermercado o tienda.</p>
            <p className="mt-2">Gracias a la confianza de los guatemaltecos, hemos podido ir creciendo y dándonos a conocer.</p>
            <div className="single-post-footer">
              <div className="column"><a className="sp-tag" href="#">#ecommerce,</a><a className="sp-tag" href="#">&nbsp;#mishop,</a><a className="sp-tag" href="#">&nbsp;</a></div>
              <div className="column">
                <div className="entry-share"><span className="text-muted">Nuestras Redes Sociales:</span>
                  <div className="share-links">
                    <a className="social-button shape-circle sb-facebook" href="https://www.facebook.com/MiShopgt-111402658117176" data-toggle="tooltip" data-placement="top" title="Facebook"><i className="socicon-facebook"></i></a>
                    <a className="social-button shape-circle sb-twitter" href="https://mobile.twitter.com/mishopgt" data-toggle="tooltip" data-placement="top" title="Twitter"><i className="socicon-twitter"></i></a>
                    <a className="social-button shape-circle sb-instagram" href="https://www.instagram.com/mishop.gt_/" data-toggle="tooltip" data-placement="top" title="Instagram"><i className="socicon-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutDetail;