import React from 'react';
import './styles.scss';

const FormSelect = ({ options, defaultValue, handleChange, label, ...otherProps }) => {
    if (!Array.isArray(options) || options.length < 1) return null;

    return (
        // <div className="shop-toolbar padding-bottom-1x mb-2">
        <div className="column">
            <div className="shop-sorting">
                {label && (
                    <label htmlFor="sorting">
                        {label}
                    </label>
                )}

                <select className="form-control" value={defaultValue} onChange={handleChange} {...otherProps}>
                    {options.map((option, index) => {
                        const { value, name } = option;

                        return (
                            <option key={index} value={value}>{name}</option>
                        );
                    })}
                </select>
            </div>
        </div>

    );
}

export default FormSelect;