import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';

const Footer = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");

  const handleSuscription = async evt => {
    evt.preventDefault();
    if (email) {
      let templateParams = {
        email: email,
        reply_to: 'info@mishop.gt'
      }

      emailjs.send('smtpmailmishopgt', 'template_xncrigg', templateParams, 'user_ZX1BFz0awK4x68kNH5vl7')
        .then((result) => {
          console.log(result.text);
          setEmail("");
        }, (error) => {
          console.log(error.text);
        });
    }
  }

  function Example() {
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-100w"
          aria-labelledby="example-custom-modal-styling-title"
          scrollable={true}
          centered
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
              Preguntas Frecuentes
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 col-md-8 order-md-4">
                <div className="accordion" id="accordion1" role="tablist">
                  <div className="card">
                    <div className="card-header" role="tab">
                      <h6><a href="#collapseOne" data-toggle="collapse">¿Entregan a domicilio?</a></h6>
                    </div>
                    <div className="collapse show" id="collapseOne" data-parent="#accordion1" role="tabpanel">
                      <div className="card-body">
                        Sí contamos con entrega a domicilio, hasta la puerta de tu casa.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab">
                      <h6><a className="collapsed" href="#collapseTwo" data-toggle="collapse">¿Entregan a todo el país, incluyendo el interior?</a></h6>
                    </div>
                    <div className="collapse" id="collapseTwo" data-parent="#accordion1" role="tabpanel">
                      <div className="card-body">
                        Sí, en todo el país, en todos los departamentos y municipios.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab">
                      <h6><a className="collapsed" href="#collapseThree" data-toggle="collapse">¿Tiene costo el envío?</a></h6>
                    </div>
                    <div className="collapse" id="collapseThree" data-parent="#accordion1" role="tabpanel">
                      <div className="card-body">Si la compra es menor de Q. 1,500.00, tiene un costo de Q.23.00 en la capital, Q. 28.00 en municipios de la capital y Q. 33.00 a nivel departamental.</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab">
                      <h6><a className="collapsed" href="#collapseFour" data-toggle="collapse">¿Tiempo de entrega?</a></h6>
                    </div>
                    <div className="collapse" id="collapseFour" data-parent="#accordion1" role="tabpanel">
                      <div className="card-body">De 24 a 48 hrs</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }


  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <section className="widget widget-light-skin">
              <h3 className="widget-title">Contáctanos</h3>
              {/* <p className="text-white">Teléfono: (502) 40916176</p> */}
              <ul className="list-unstyled text-sm text-white">
                <li>
                  <span className="opacity-50">Lunes-Viernes:</span>9.00 am - 8.00
                  pm
                </li>
                {/* <li>
                  <span className="opacity-50">Sábados:</span>10.00 am - 6.00 pm
                </li> */}
              </ul>
              <p>
                <Link className="navi-link-light" to="/">
                  ayuda@mishop.gt
                </Link>
              </p>
              <a
                className="social-button shape-circle sb-facebook sb-light-skin"
                href="https://www.facebook.com/MiShopgt-111402658117176" disabled={true}
              >
                <i className="socicon-facebook"></i>
              </a>
              <a
                className="social-button shape-circle sb-twitter sb-light-skin"
                href="https://mobile.twitter.com/mishopgt" disabled={true}
              >
                <i className="socicon-twitter"></i>
              </a>
              <a
                className="social-button shape-circle sb-instagram sb-light-skin"
                href="https://www.instagram.com/mishop.gt_/" disabled={true}
              >
                <i className="socicon-instagram"></i>
              </a>
              {/* <Link
                className="social-button shape-circle sb-google-plus sb-light-skin"
                to="/"
              >
                <i className="socicon-googleplus"></i>
              </Link> */}
            </section>
          </div>
          <div className="col-lg-3 col-md-6">
            <section className="widget widget-light-skin">
              <h3 className="widget-title">Nuestra App (Próximamente)</h3>
              <Link className="market-button apple-button mb-light-skin" to="/">
                <span className="mb-subtitle">Bájala en</span>
                <span className="mb-title">App Store</span>
              </Link>
              <Link className="market-button google-button mb-light-skin" to="/">
                <span className="mb-subtitle">Bájala en</span>
                <span className="mb-title">Google Play</span>
              </Link>
              {/* <Link className="market-button windows-button mb-light-skin" to="/">
                <span className="mb-subtitle">Bájala en</span>
                <span className="mb-title">Windows Store</span>
              </Link> */}
            </section>
          </div>
          {/* <div className="col-lg-3 col-md-6">
            <section className="widget widget-links widget-light-skin">
              <h3 className="widget-title">Acerca de Nosotros</h3>
              <ul>
                <li>
                  <Link to="/">Únete al equipo de miShop.gt</Link>
                </li>
                <li>
                  <Link to="/">Acerca de mishop.gt</Link>
                </li>
                <li>
                  <Link to="/">Nuestra Historia</Link>
                </li>
                <li>
                  <Link to="/">Servicios</Link>
                </li>
                <li>
                  <Link to="/">Nuestro Blog</Link>
                </li>
              </ul>
            </section>
          </div> */}
          <Example />
          <div className="col-lg-3 col-md-6">
            <section className="widget widget-links widget-light-skin">
              <h3 className="widget-title">Mi Cuenta &amp; Información de Envío</h3>
              <ul>
                <li>
                  {/* <a href="#" onClick={handleOpen}>Preguntas frecuentes</a> */}
                  <Link to="#" onClick={() => setShow(true)}>Preguntas Frecuentes</Link>
                  {/* <button type="button" onClick={handleOpen}>
                    Preguntas Frecuentes
                  </button> */}

                </li>
                <li>
                  <Link to="/dashboard">Mi Cuenta</Link>
                </li>
                <li>
                  <Link to="/">Términos y Condiciones</Link>
                </li>
                <li>
                  <Link to="/">Devoluciones y Reemplazos</Link>
                </li>
                {/* <li>
                  <Link to="/">Impuestos</Link>
                </li> */}
                <li>
                  <Link to="/">Información de Envíos</Link>
                </li>
                {/* <li>
                  <Link to="/">Programa de Afiliados</Link>
                </li> */}
              </ul>
            </section>
          </div>
        </div>
        <hr className="hr-light mt-2 margin-bottom-2x" />
        <div className="row">
          <div className="col-md-7 padding-bottom-1x">
            <div className="margin-bottom-1x" style={{ maxWidth: "615px" }}>
              <img src="assets/img/payment_methods1.png" alt="Payment Methods" />
            </div>
          </div>
          <div className="col-md-5 padding-bottom-1x">
            <div className="margin-top-1x hidden-md-up"></div>
            <form
              className="subscribe-form"
              onSubmit={handleSuscription}
            >
              <div className="clearfix">
                <div className="input-group input-light">
                  <input
                    className="form-control"
                    type="email"
                    name="EMAIL"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    placeholder="Tu Correo Electrónico"
                  />
                  {/* <FormInput
                    required
                    placeholder="Correo Electrónico"
                    // label="* Correo Electrónico"
                    minLength={6}
                    name="email"
                    handleChange={evt => handleEmail(evt)}
                    value={email}
                    type="email"
                  /> */}
                  <span className="input-group-addon">
                    <i className="icon-mail"></i>
                  </span>
                </div>
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_c7103e2c981361a6639545bd5_1194bb7544"
                    tabIndex="-1"
                  />
                </div>
                <button className="btn btn-primary" type="submit">
                  <i className="icon-check"></i>
                </button>
              </div>
              <span className="form-text text-sm text-white opacity-50">
                Subscribete a nuestros descuentos, ofertas y noticias.
              </span>
            </form>
          </div>
        </div>
        <p className="footer-copyright">
          © Derechos Reservados. Hecho con  &nbsp;
          <i className="icon-heart text-danger"></i>
          <Link to="/" target="_blank">
            {" "}
            &nbsp;por miShop.gt
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
