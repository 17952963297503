import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { selectCartTotal, selectCartItemsCount, selectCartItems } from './../../redux/Cart/cart.selectors';
import { useSelector, useDispatch } from 'react-redux';
import FormInput from '../Forms/FormInput';
import FormInputCard from '../Forms/FormInputCard';
import { useHistory, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Item from './Item';
import { saveOrderHistory } from './../../redux/Orders/orders.actions';
import { formatCreditCardNumber, formatExpirationDate, formatCVC } from '../../Utils';
import Cards from 'react-credit-cards';
import { guestSignInStart } from "./../../redux/User/user.actions";
import { Alert } from '@material-ui/lab';
import "react-credit-cards/es/styles-compiled.css";
import axios from 'axios';
import { data } from '../../assets/data/departments';
import { data1 } from '../../assets/data/postal_code.json';
import CreatableSelect from 'react-select/creatable';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const initialAddressState = {
    line1: '',
    line2: '',
    nit: '',
    city: '',
    state: '',
    postal_code: '',
    email: '',
    phone: ''
};

const mapState = createStructuredSelector({
    total: selectCartTotal,
    itemCount: selectCartItemsCount,
    cartItems: selectCartItems,
});

const mapState1 = ({ user }) => ({
    currentUser: user.currentUser,
});

const PaymentDetails = () => {
    const { total, itemCount, cartItems } = useSelector(mapState);
    const [envio, setEnvio] = useState(0.00);
    const [shipping] = useState(0);
    const [discount] = useState(0);
    const dispatch = useDispatch();
    const [billingAddress, setBillingAddress] = useState({ ...initialAddressState });
    const [shippingAddress, setShippingAddress] = useState({ ...initialAddressState });
    const [newVoucher, setNewVoucher] = useState('');
    const [recipientName, setRecipientName] = useState('');
    const [nameOnCard, setNameOnCard] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [nameOnCard1, setNameOnCard1] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [cvc, setCVC] = useState('');
    const [focused, setFocused] = useState('');
    const history = useHistory();
    const [stepOne, setStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);
    const [stepThree, setStepThree] = useState(false);
    const [stepFour, setStepFour] = useState(false);
    const [stepFive, setStepFive] = useState(false);
    const [disableMunicipio, setDisableMunicipio] = useState(true);
    const [disablePayment, setDisablePayment] = useState(false);
    const [displayErrorPayment, setDisplayErrorPayment] = useState(false);
    const [municipios, setMunicipios] = useState(['']);
    const [displayError, setDisplayError] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedPostalCodeOption, setSelectedPostalCodeOption] = useState(null);
    const [selectedOptionMunicipio, setSelectedOptionMunicipio] = useState(null);
    const { currentUser } = useSelector(mapState1);
    const [show, setShow] = useState(false);
    const [cityCode, setcityCode] = useState(0);

    useEffect(() => {
        if (itemCount < 1) {
            history.push('/');
        }

        if (window.pageYOffset > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        validateShipping();

        if (!currentUser) {
            dispatch(guestSignInStart())
        }
    }, [itemCount]);

    function Example() {
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        return (
            <>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-100w"
                    aria-labelledby="example-custom-modal-styling-title"
                    scrollable={true}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Códigos Postales de Guatemala
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Si no sabes el código postal donde vives, puedes seleccionar el Departamento
                            donde vives y veras la lista de códigos por zona o municipios al que pertenezcas.
                        </p>
                        <div className='row'>
                            <div className="col-sm-8 form-group">
                                <label htmlFor="sorting">
                                    Departamento
                                </label>
                                <CreatableSelect
                                    value={selectedPostalCodeOption}
                                    name="city"
                                    styles={colourStyles}
                                    options={data}
                                    onChange={evt => handlePostalCodeDepartment(evt)}
                                />
                            </div>
                        </div>
                        {<>
                            <div className="row">
                                <div className="col-lg-9 col-md-8 order-md-2">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Codigo Postal</th>
                                                    <th>Zona o Municipio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data1.map((postalcode, index) => {

                                                    const {
                                                        value,
                                                        name,
                                                        department
                                                    } = postalcode;

                                                    if (department == cityCode) {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{value}</td>
                                                                <td>{name}</td>
                                                            </tr>
                                                        )
                                                    }

                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>}
                        <br />
                        <br />
                        <br />
                        <br />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    const colourStyles = {
        control: styles => ({
            ...styles,
            padding: '0 18px 3px',
            border: '1px solid #dbe2e8',
            borderRadius: '22px',
            backgroundColor: '#ffffff',
            color: '#606975',
            fontFamily: '"Maven Pro", Helvetica, Arial, sans-serif',
            fontSize: '14px',
            webkitAppearance: 'none',
            mozAppearance: 'none',
            appearance: 'none'
        })
    }

    const validateShipping = () => {
        if (total >= 1500) {
            setEnvio(0.00);
        }
    }

    const validateShippingState = (shippingState, shippingCity) => {
        if (total < 1500) {
            if (shippingState == "Guatemala") {
                setEnvio(23.00);
            } else {
                if (shippingCity == "Guatemala" && shippingState != "Guatemala") {
                    setEnvio(28.00)
                }
                else {
                    if (shippingCity != "Guatemala") {
                        setEnvio(33.00)
                    }
                }

            }
        }

    }

    const getMunicipios = async (idDepto) => {
        return axios.get('assets/data/municipios.json')
            .then(res => res.data.data)
            .then(data => {
                let infoMuni = data.filter(x => x.city == idDepto);
                setMunicipios(infoMuni);
            })

    }

    const handleShippingPostalCode = evt => {
        // evt.preventDefault();
        if (evt) {
            setPostalCode(evt);
        }
    }

    const setPostalCode = evt => {
        setShippingAddress({
            ...shippingAddress,
            ['postal_code']: evt
        });
    }

    const handleShipping = evt => {
        const { name, value } = evt.target;
        setShippingAddress({
            ...shippingAddress,
            [name]: value
        });
    };

    const handlePostalCodeDepartment = evt => {
        const { label, value } = evt;
        setcityCode(value);
        setSelectedPostalCodeOption(evt);
    }

    const handleShippingDepartment = evt => {
        const { label, value } = evt;
        setSelectedOption(evt);
        setMunicipios(['']);
        setSelectedOptionMunicipio(null);

        setDisableMunicipio(false);

        setShippingAddress({
            ...shippingAddress,
            ['city']: label,
            ['state']: ''
        });

        getMunicipios(value);
    };

    const handleShippingMunicipio = evt => {

        const { label } = evt;

        setSelectedOptionMunicipio(evt);

        setShippingAddress({
            ...shippingAddress,
            ['state']: label
        });
        validateShippingState(label, shippingAddress.city);
    };

    const handleBilling = evt => {
        const { name, value } = evt.target;
        let newValue = evt.target.value;

        if (name == 'nit') {
            newValue = newValue.trim();
        } else {
            newValue = evt.target.value;
        }

        setBillingAddress({
            ...billingAddress,
            [name]: newValue
        });
    }

    const handleGuestSignIn = async () => {

        if (!currentUser) {

            await Promise.all([
                dispatch(guestSignInStart()),
            ]);
        }

        handleFormSubmitComplete()

    };

    const handleFormSubmitComplete = async evt => {

        const configOrder = {
            orderTotal: total + envio,
            subTotal: total,
            name: recipientName,
            orderItems: cartItems.map(item => {
                const { documentID, productThumbnail, productName,
                    productPrice, quantity, sku } = item;

                return {
                    documentID,
                    productThumbnail,
                    productName,
                    productPrice,
                    quantity,
                    sku
                };
            }),
            shipping: {
                name: recipientName,
                address: {
                    ...shippingAddress
                }
            },
            billing: {
                name: nameOnCard,
                address: {
                    ...billingAddress
                }
            },
            shippingPrice: envio
        }
        dispatch(
            saveOrderHistory(configOrder)
        );

        history.push('/paymentType');


    }

    const handleFormSubmit = async evt => {
        evt.preventDefault();
        if (
            !shippingAddress.line1 || !shippingAddress.city ||
            !shippingAddress.state || !shippingAddress.postal_code ||
            !shippingAddress.email || !shippingAddress.phone ||
            !billingAddress.line1 || !recipientName
        ) {
            setDisplayError(true);
            setTimeout(() => {
                setDisplayError(false)
            }, 6000);
            return;
        }

        setStepOne(false);
        setStepTwo(true);
        setStepThree(false);
        setStepFour(false);
        setStepFive(false);

        if (window.pageYOffset > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const setOne = () => {

        setStepOne(true);
        setStepTwo(false);
        setStepThree(false);
        setStepFour(false);
        setStepFive(false);
    }

    const setTwo = () => {
        setStepOne(false);
        setStepTwo(true);
        setStepThree(false);
        setStepFour(false);
        setStepFive(false);
    }

    const setThree = () => {
        setStepOne(false);
        setStepTwo(false);
        setStepThree(true);
        setStepFour(false);
        setStepFive(false);
    }

    const handleFocusChange = (e) => {
        setFocused(e.target.name)
    }

    return (
        <React.Fragment>

            {stepOne && <>
                <div className="col-xl-9 col-lg-8">
                    <div className="checkout-steps">
                        <Link to="#"><span className="angle"></span>3. Pago</Link>
                        <Link to="#"><span className="angle"></span>2. Detalle</Link>
                        {/* <Link to="#"><span className="angle"></span>2. Método de Envío</Link> */}
                        <Link to="#" className="active"><span className="angle"></span>1. Dirección</Link>
                    </div>
                    <h4>Dirección de Entrega</h4>
                    <hr className="padding-bottom-1x"></hr>
                    <form onSubmit={handleFormSubmit} className="needs-validation">
                        <div className="row">
                            <FormInput
                                required
                                placeholder="Nombre persona a quien se entrega"
                                name="recipientName"
                                label="* Nombre quien Recibe"
                                handleChange={evt => setRecipientName(evt.target.value)}
                                value={recipientName}
                                type="text"
                            />
                            <FormInput
                                required
                                placeholder="Dirección"
                                name="line1"
                                label="* Dirección"
                                handleChange={evt => handleShipping(evt)}
                                value={shippingAddress.line1}
                                type="text"
                            />
                        </div>
                        <div className="row">

                            <FormInput
                                placeholder="Datos extras Dirección"
                                name="line2"
                                label="Datos Adicionales Dirección"
                                handleChange={evt => handleShipping(evt)}
                                value={shippingAddress.line2}
                                type="text"
                            />
                            <Example />
                            {/* <FormInput
                                required
                                placeholder="Código Postal"
                                name="postal_code"
                                label="* Código Postal "
                                onClick={evt => handleShipping(evt)}
                                value={shippingAddress.postal_code}
                                type="text"
                            /> */}
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>* Código Postal  </label><Link to="#" onClick={() => setShow(true)}> Ver Codigos</Link>
                                    <input
                                        placeholder="Código Postal"
                                        className="form-control"
                                        onChange={e => handleShippingPostalCode(e.target.value)}
                                        value={shippingAddress.postal_code}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-6 form-group">
                                <label htmlFor="sorting">
                                    * Departamento
                                </label>
                                <CreatableSelect
                                    value={selectedOption}
                                    rules={{ required: true }}
                                    name="city"
                                    styles={colourStyles}
                                    options={data}
                                    onChange={evt => handleShippingDepartment(evt)}
                                />
                            </div>
                            <div className="col-sm-6 form-group">
                                <label htmlFor="sorting">
                                    * Municipio
                                </label>
                                <CreatableSelect
                                    value={selectedOptionMunicipio}
                                    rules={{ required: true }}
                                    name="state"
                                    styles={colourStyles}
                                    options={municipios}
                                    isDisabled={disableMunicipio}
                                    onChange={evt => handleShippingMunicipio(evt)}

                                />
                            </div>
                            {/* <AddressFormSelect
                            required
                            name="state"
                            label="Municipio"
                            options={municipios}
                            disabled={disableMunicipio}
                            handleChange={evt => handleShipping(evt)}
                        /> */}
                        </div>
                        <div className="row">
                            <FormInput
                                required
                                placeholder="Correo Electrónico"
                                label="* Correo Electrónico"
                                minLength={6}
                                name="email"
                                handleChange={evt => handleShipping(evt)}
                                value={shippingAddress.email}
                                type="email"
                            />
                            <FormInput
                                required
                                placeholder="Numero de Telefono"
                                label="* Numero de Telefono"
                                minLength={6}
                                name="phone"
                                pattern="[\d| ]{6,10}"
                                handleChange={evt => handleShipping(evt)}
                                value={shippingAddress.phone}
                                type="text"
                            />
                        </div>
                        <h4>Nota:</h4><p> Asegurate que los datos de dirección y contacto ingresados sean correctos para la entrega.</p>
                        <br />

                        <h4>Datos de Facturación</h4>
                        <hr className="padding-bottom-1x" />
                        <div className="row">
                            <FormInput
                                required
                                placeholder="Nombre para Factura"
                                label="* Nombre en Factura"
                                handleChange={evt => setNameOnCard(evt.target.value)}
                                value={nameOnCard}
                                type="text"
                            />
                            <FormInput
                                required
                                placeholder="Dirección"
                                name="line1"
                                label="* Dirección"
                                handleChange={evt => handleBilling(evt)}
                                value={billingAddress.line1}
                                type="text"
                            />
                        </div>
                        <div className="row">
                            <FormInput
                                type="text"
                                name="nit"
                                value={billingAddress.nit}
                                placeholder="NIT"
                                label="NIT"
                                handleChange={evt => handleBilling(evt)}
                            />
                        </div>
                        <h4>Nota:</h4><p> Tu Factura la recibirás al correo electrónico registrado en los datos anteriores.</p>

                        {displayError &&
                            <Alert variant="filled" severity="error" onClose={() => { setDisplayError(false) }}>
                                Verifica que los datos obligatorios hayan sido ingresados.
                            </Alert>
                        }

                        <div className="checkout-footer">
                            <div className="column">
                                <NavLink className="btn btn-outline-secondary" to="/cart"><i className="icon-arrow-left"></i><span className="hidden-xs-down">&nbsp;Regresar a Carrito</span></NavLink>
                            </div>
                            <div className="column">
                                <button type="submit" className="btn btn-primary">
                                    <span className="hidden-xs-down">Continuar&nbsp;</span><i className="icon-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-xl-3 col-lg-4">
                    <aside className="sidebar">
                        <div className="padding-top-2x hidden-lg-up"></div>
                        <section className="widget widget-order-summary">
                            <h3 className="widget-title">Resumen de Orden</h3>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Subtotal Carrito:</td>
                                        <td className="text-medium">Q. {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td>Envío:</td>
                                        <td className="text-medium">Q. {envio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td>Descuento:</td>
                                        <td className="text-medium">Q. {discount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-lg text-medium">Q {(total + envio + discount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>

                    </aside>
                </div>
            </>
            }
            {/* {stepTwo && <>
                <div className="checkout-steps">
                    <Link to="#"><span className="angle"></span>4. Pago</Link>
                    <Link to="#"><span className="angle"></span>3. Detalle</Link>
                    <Link to="#" className="active"><span className="angle"></span>2. Método de Envío</Link>
                    <Link to="#" className="completed"><span className="angle"></span><span className="step-indicator icon-circle-check"></span>1. Dirección</Link>
                </div>
                <h4>Elige Método de Envío</h4>
                <hr className="padding-bottom-1x" />
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead className="thead-default">
                            <tr>
                                <th></th>
                                <th>Método de Envío</th>
                                <th>Por Tiempo</th>
                                <th>Costo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="align-middle">
                                    <div className="custom-control custom-radio mb-0">
                                        <input className="custom-control-input" type="radio" id="courier" name="shipping-method" defaultChecked />
                                        <label className="custom-control-label" htmlFor="courier"></label>
                                    </div>
                                </td>
                                <td className="align-middle"><span className="text-medium">Ciudad</span><br /><span className="text-muted text-sm">Cualquier Dirección en Guatemala País</span></td>
                                <td className="align-middle">1 día</td>
                                <td className="align-middle">Q25.00</td>
                            </tr>
                            <tr>
                                <td className="align-middle">
                                    <div className="custom-control custom-radio mb-0">
                                        <input className="custom-control-input" type="radio" id="local" name="shipping-method" />
                                        <label className="custom-control-label" htmlFor="local"></label>
                                    </div>
                                </td>
                                <td className="align-middle"><span className="text-medium">Departamental</span><br /><span className="text-muted text-sm">Cualquier Dirección en Guatemala País</span></td>
                                <td className="align-middle">1 a 2 días</td>
                                <td className="align-middle">Q40.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="checkout-footer margin-top-1x">
                    <div className="column hidden-xs-down">
                        <button type="button" className="btn btn-outline-secondary" onClick={() => setOne()}>
                            <i className="icon-arrow-left"></i><span className="hidden-xs-down"> Regresar&nbsp;</span>
                        </button>
                    </div>
                    <div className="column">
                        <button type="button" className="btn btn-primary" onClick={() => setThree()}>
                            <span className="hidden-xs-down">Continuar&nbsp;</span><i className="icon-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </>
            } */}
            {stepTwo && <>
                <div className="col-xl-9 col-lg-8">
                    <div className="checkout-steps">
                        <Link to="#"><span className="angle"></span>3. Pago</Link>
                        <Link to="#" className="active"><span className="angle"></span>2. Detalle</Link>
                        {/* <Link to="#" className="completed"><span className="angle"></span><span className="step-indicator icon-circle-check"></span>2. Método de Envío</Link> */}
                        <Link to="#" className="completed"><span className="angle"></span><span className="step-indicator icon-circle-check"></span>1. Dirección</Link>
                    </div>
                    <h4>Detalle de la Compra</h4>
                    <hr className="padding-bottom-1x" />
                    <div className="table-responsive shopping-cart">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th className="text-center">Precio Unitario</th>
                                    <th className="text-center">Subtotal</th>
                                </tr>
                            </thead>
                            {cartItems.map((item, pos) => {
                                return (
                                    <tbody key={pos}>
                                        <Item {...item} />
                                    </tbody>
                                );
                            })}
                        </table>
                    </div>
                    <div className="shopping-cart-footer">
                        <div className="column"></div>
                        <div className="column text-lg">Subtotal: <span className="text-medium">Q. {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span></div>
                        {/* <div className="column"></div>
                        <div className="column text-lg">Envío: <span className="text-medium">Q. {envio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span></div> */}
                    </div>
                    <div className="row padding-top-1x mt-3">
                        <div className="col-sm-6">
                            <h5>Enviar a:</h5>
                            <ul className="list-unstyled">
                                <li><span className="text-muted">Cliente: </span>{recipientName}</li>
                                <li><span className="text-muted">Dirección: </span>{shippingAddress.line1}</li>
                                <li><span className="text-muted">Datos Adicionales: </span>{shippingAddress.line2}</li>
                                <li><span className="text-muted">Codigo Postal: </span>{shippingAddress.postal_code}</li>
                                <li><span className="text-muted">Correo: </span>{shippingAddress.email}</li>
                                <li><span className="text-muted">Teléfono: </span>{shippingAddress.phone}</li>
                            </ul>
                        </div>
                        {/* <div className="col-sm-6">
                        <h5>Método de Pago:</h5>
                        <ul className="list-unstyled">
                            <li><span className="text-muted">Tarjeta de Crédito:</span>**** **** **** 5300</li>
                        </ul>
                    </div> */}
                    </div>
                    <div className="checkout-footer margin-top-1x">
                        <div className="column hidden-xs-down">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => setOne()}>
                                <i className="icon-arrow-left"></i><span className="hidden-xs-down"> Regresar&nbsp;</span>
                            </button>
                        </div>
                        <div className="column">
                            <button type="button" className="btn btn-primary" onClick={() => handleFormSubmitComplete()}>
                                <span className="hidden-xs-down">Continuar&nbsp;</span><i className="icon-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4">
                    <aside className="sidebar">
                        <div className="padding-top-2x hidden-lg-up"></div>
                        <section className="widget widget-order-summary">
                            <h3 className="widget-title">Resumen de Orden</h3>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Subtotal Carrito:</td>
                                        <td className="text-medium">Q. {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td>Envío:</td>
                                        <td className="text-medium">Q. {envio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td>Descuento:</td>
                                        <td className="text-medium">Q. {discount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-lg text-medium">Q {(total + envio + discount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>

                    </aside>
                </div>
            </>
            }
            {stepFour && <>
                <div className="checkout-steps">
                    <Link to="#" className="active"><span className="angle"></span>3. Pago</Link>
                    <Link to="#" className="completed"><span className="angle"></span>2. Detalle</Link>
                    {/* <Link to="#" className="completed"><span className="angle"></span><span className="step-indicator icon-circle-check"></span>2. Método de Envío</Link> */}
                    <Link to="#" className="completed"><span className="angle"></span><span className="step-indicator icon-circle-check"></span>1. Dirección</Link>
                </div>
                <h4>Elige Método de Pago</h4>
                <hr className="padding-bottom-1x" />
                <div className="accordion" id="accordion" role="tablist">
                    <div className="card">
                        <div className="card-header" role="tab">
                            <h6><a href="#card" data-toggle="collapse"><i className="icon-columns"></i>Pagar con Tarjeta de Crédito o Débito</a></h6>
                        </div>
                        <div className="collapse show" id="card" data-parent="#accordion" role="tabpanel">
                            <div className="card-body">
                                <p>Aceptamos todas las tarjetas de Crédito:&nbsp;<img className="d-inline-block align-middle" src="assets/img/credit-cards.png" alt="Cerdit Cards" /></p>
                                &nbsp;
                                <Cards
                                    number={cardNumber}
                                    name={nameOnCard1}
                                    expiry={expirationDate}
                                    cvc={cvc}
                                    focused={focused}
                                />
                                <br />
                                <form onSubmit={handleFormSubmitComplete} className="interactive-credit-card row">

                                    <FormInput
                                        required
                                        placeholder="Numero de Tarjeta"
                                        minLength={15}
                                        pattern="[\d| ]{16,22}"
                                        handleChange={evt => setCardNumber(formatCreditCardNumber(evt.target.value))}
                                        onFocus={handleFocusChange}
                                        value={cardNumber}
                                        type="text"
                                    />
                                    <FormInput
                                        required
                                        placeholder="Nombre en Tarjeta"
                                        minLength={5} maxLength={30}
                                        handleChange={evt => setNameOnCard1(evt.target.value)}
                                        onFocus={handleFocusChange}
                                        value={nameOnCard1}
                                        type="text"
                                    />
                                    <FormInputCard
                                        required
                                        placeholder="MM/YY"
                                        pattern="\d\d/\d\d"
                                        minLength={5} maxLength={5}
                                        handleChange={evt => setExpirationDate(formatExpirationDate(evt.target.value))}
                                        onFocus={handleFocusChange}
                                        value={expirationDate}
                                        type="text"
                                    />
                                    <FormInputCard
                                        required
                                        placeholder="CVC"
                                        pattern="\d{3,4}"
                                        handleChange={evt => setCVC(formatCVC(evt.target.value))}
                                        value={cvc}
                                        type="text"
                                        name="cvc"
                                        id="cvc"
                                        onFocus={handleFocusChange}
                                    />
                                    <div className="col-sm-6">
                                        <button className="btn btn-outline-primary btn-block margin-top-none" type="submit" disabled={disablePayment}>Pagar</button>
                                    </div>
                                </form>
                                {displayErrorPayment &&
                                    <Alert variant="filled" severity="error" onClose={() => { setDisplayErrorPayment(false) }}>
                                        Error al procesar el pago — Verifica los datos ingresados e intenta nuevamente!
                                    </Alert>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkout-footer margin-top-1x">
                    <div className="column hidden-xs-down">
                        <button type="button" className="btn btn-outline-secondary" onClick={() => setThree()}>
                            <i className="icon-arrow-left"></i><span className="hidden-xs-down"> Regresar&nbsp;</span>
                        </button>
                    </div>
                </div>
            </>
            }
            {stepFive && <>
                <div className="offcanvas-wrapper">

                    <div className="page-title">
                        <div className="container">
                            <div className="column">
                                <h1>Checkout</h1>
                            </div>
                            <div className="column">
                                <ul className="breadcrumbs">
                                    <li><a href="index.html">Home</a>
                                    </li>
                                    <li className="separator">&nbsp;</li>
                                    <li>Checkout</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container padding-bottom-3x mb-2">
                        <div className="card text-center">
                            <div className="card-body padding-top-2x">
                                <h3 className="card-title">Gracias por tu orden!</h3>
                                <p className="card-text">Tu pedido ha sido realizado y será procesado lo antes posible</p>
                                <p className="card-text">Se generó el número de orden <span className="text-medium">{newVoucher.orderId}</span></p>
                                <p className="card-text">Voucher<span className="text-medium"></span></p>
                                <p className="card-text">No. de Autorización: <span className="text-medium">{newVoucher.authorizationNumber}</span></p>
                                <p className="card-text">- PAGADO ELECTRONICAMENTE - </p>
                                <p className="card-text">En breve recibirás un correo electrónico con la confirmación de tu pedido.
                                    <u>Ahora puedes:</u>
                                </p>
                                <div className="padding-top-1x padding-bottom-1x">
                                    <NavLink className="btn btn-outline-secondary" to="/search">Volver a Tienda</NavLink>
                                    <NavLink className="btn btn-outline-primary" to="/OrderLists"><i className="icon-location"></i>&nbsp;Track order</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </React.Fragment>
    )

}

export default PaymentDetails;