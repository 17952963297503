import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addProductStart, fetchProductsStart } from './../../redux/Products/products.actions';
import FormInput from './../../components/Forms/FormInput';
import AddressFormSelect from './../../components/Forms/AddressFormSelect';
import CKEditor from 'ckeditor4-react';


const CreateProduct = props => {
    const dispatch = useDispatch();
    const [productCategory, setProductCategory] = useState('mens');
    const [productName, setProductName] = useState('');
    const [productAvailability, setProductAvailavility] = useState(0);
    const [productThumbnail, setProductThumbnail] = useState('');
    const [productThumbnail2, setProductThumbnail2] = useState('');
    const [productThumbnail3, setProductThumbnail3] = useState('');
    const [productThumbnail4, setProductThumbnail4] = useState('');
    const [productThumbnail5, setProductThumbnail5] = useState('');
    const [productPrice, setProductPrice] = useState(0);
    const [productSpecialPrice, setProductSpecialPrice] = useState(0);
    const [productIncrementPrice, setproductIncrementPrice] = useState(0);
    const [productDesc, setProductDesc] = useState('');
    const [localImageURL, setLocalImageURL] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [sku, setSku] = useState('');

    useEffect(() => {
        dispatch(
            fetchProductsStart()
        );
    }, []);

    const resetForm = () => {
        setProductCategory('mens');
        setProductName('');
        setProductAvailavility(0);
        setProductThumbnail('');
        setProductThumbnail2('');
        setProductThumbnail3('');
        setProductThumbnail4('');
        setProductThumbnail5('');
        setProductPrice(0);
        setProductSpecialPrice(0);
        setproductIncrementPrice(0);
        setProductDesc('');
        setLocalImageURL('');
        setShortDesc('');
        setSku('');
    };

    const handleSubmit = e => {
        e.preventDefault();

        dispatch(
            addProductStart({
                productCategory,
                productName,
                productAvailability,
                productThumbnail,
                productThumbnail2,
                productThumbnail3,
                productThumbnail4,
                productThumbnail5,
                productPrice,
                productSpecialPrice,
                productIncrementPrice,
                productDesc,
                localImageURL,
                shortDesc,
                sku
            })
        );
        resetForm();

    };

    return (
        <div>

            <form onSubmit={handleSubmit}>

                <h2>
                    Agregar Nuevo Producto
                </h2>

                <AddressFormSelect
                    label="Category"
                    options={[{
                        value: "mens",
                        name: "Hombres"
                    }, {
                        value: "womens",
                        name: "Mujeres"
                    }, {
                        value: "licors",
                        name: "Licores"
                    }, {
                        value: "computers",
                        name: "Computadoras de Escritorio"
                    }, {
                        value: "notebooks",
                        name: "Computadoras Portátiles"
                    }, {
                        value: "personalcare",
                        name: "Cuidado Personal"
                    }, {
                        value: "accesories",
                        name: "Accesorios de Computadora"
                    }, {
                        value: "networkaccesories",
                        name: "Accesorios de red"
                    }, {
                        value: "tvaccesories",
                        name: "Accesorios para TV y Monitores"
                    }, {
                        value: "monitors",
                        name: "Monitores"
                    }, {
                        value: "storage",
                        name: "Almacenamiento"
                    }, {
                        value: "components",
                        name: "Componentes Informáticos"
                    }, {
                        value: "memories",
                        name: "Memorias"
                    }, {
                        value: "powerprotection",
                        name: "Protectores de Poder"
                    }, {
                        value: "projectors",
                        name: "Proyectores"
                    }, {
                        value: "digitaldownloads",
                        name: "Descargas Digitales"
                    }, {
                        value: "communications",
                        name: "Comunicaciones"
                    }, {
                        value: "networks",
                        name: "Redes"
                    }, {
                        value: "homeappliances",
                        name: "Electrodomésticos"
                    }, {
                        value: "techtoys",
                        name: "Drones y Accesorios"
                    }, {
                        value: "television",
                        name: "Televisores"
                    }, {
                        value: "audio",
                        name: "Audio y Video"
                    }, {
                        value: "backpacks",
                        name: "Mochilas y Estuches"
                    }, {
                        value: "printers",
                        name: "Impresoras y Escáneres"
                    }, {
                        value: "consumables",
                        name: "Consumibles y Media"
                    }, {
                        value: "forniture",
                        name: "Muebles"
                    }, {
                        value: "detectiondevices",
                        name: "Detección de Incendios"
                    }, {
                        value: "intrusion",
                        name: "Intrusión"
                    }, {
                        value: "videosurveillance",
                        name: "Vigilancia de Video"
                    }, {
                        value: "pointsofsale",
                        name: "Puntos de Venta"
                    }, {
                        value: "promotions",
                        name: "Promociones"
                    }, {
                        value: "accesscontrol",
                        name: "Control de Acceso"
                    }, {
                        value: "cameras",
                        name: "Cámaras y Videocámaras"
                    },
                    // {
                    //     value: "headphones",
                    //     name: "Auriculares"
                    // }, 
                    {
                        value: "tablets",
                        name: "Tablets"
                    }, {
                        value: "phones",
                        name: "Teléfonos"
                    },
                    // {
                    //     value: "servers",
                    //     name: "Servidores"
                    // }, 
                    {
                        value: "chairs",
                        name: "Sillas"
                    }, {
                        value: "sports",
                        name: "Artículos Deportivos"
                    }, {
                        value: "home",
                        name: "Hogar"
                    }, {
                        value: "Periféricos",
                        name: "Periféricos"
                    }, {
                        value: "Software",
                        name: "Software"
                    }, {
                        value: "videogames",
                        name: "Videojuegos"
                    }]}
                    handleChange={e => setProductCategory(e.target.value)}
                />

                <FormInput
                    label="Name"
                    type="text"
                    value={productName}
                    handleChange={e => setProductName(e.target.value)}
                />

                <FormInput
                    label="Disponibilidad"
                    type="number"
                    min="0"
                    max="10000"
                    step="1"
                    value={productAvailability}
                    handleChange={e => setProductAvailavility(e.target.value)}
                />

                <FormInput
                    label="Nombre imagen Local"
                    type="text"
                    value={localImageURL}
                    handleChange={e => setLocalImageURL(e.target.value)}
                />

                <FormInput
                    label="Main image URL"
                    type="url"
                    value={productThumbnail}
                    handleChange={e => setProductThumbnail(e.target.value)}
                />

                <FormInput
                    label="Main image URL 2"
                    type="url"
                    value={productThumbnail2}
                    handleChange={e => setProductThumbnail2(e.target.value)}
                />

                <FormInput
                    label="Main image URL 3"
                    type="url"
                    value={productThumbnail3}
                    handleChange={e => setProductThumbnail3(e.target.value)}
                />

                <FormInput
                    label="Main image URL 4"
                    type="url"
                    value={productThumbnail4}
                    handleChange={e => setProductThumbnail4(e.target.value)}
                />

                <FormInput
                    label="Main image URL 5"
                    type="url"
                    value={productThumbnail5}
                    handleChange={e => setProductThumbnail5(e.target.value)}
                />

                <FormInput
                    label="Precio"
                    type="number"
                    min="0.00"
                    max="10000.00"
                    step="0.01"
                    value={productPrice}
                    handleChange={e => setProductPrice(e.target.value)}
                />
                <FormInput
                    label="Precio Especial"
                    type="number"
                    min="0.00"
                    max="10000.00"
                    step="0.01"
                    value={productSpecialPrice}
                    handleChange={e => setProductSpecialPrice(e.target.value)}
                />

                <CKEditor
                    onChange={evt => setShortDesc(evt.editor.getData())}
                />

                <CKEditor
                    onChange={evt => setProductDesc(evt.editor.getData())}
                />

                <button className="btn btn-primary btn-sm" type="submit">Agregar producto</button>



            </form>




        </div>
    );
}

export default CreateProduct;