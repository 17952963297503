import React, { useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectWishItems, selectWishTotal } from '../../redux/Wish/wish.selectors';
import { createStructuredSelector } from 'reselect';
import './styles.scss';
import Item from './Item';

const mapState = createStructuredSelector({
    wishItems: selectWishItems,
    total: selectWishTotal
});

const CheckoutWish = () => {
    const history = useHistory();
    const { wishItems, total } = useSelector(mapState);

    useEffect(() => {

        if (window.pageYOffset > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }, [])


    return (
        <React.Fragment>
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Lista de Deseos</h1>
                    </div>
                    <div className="column">
                        <ul className="breadcrumbs">
                            <li><a href="index.html">Principal</a>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Mis Artículos que Deseo</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container padding-bottom-3x mb-1">
                <div className="table-responsive shopping-cart">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th className="text-center">Cantidad</th>
                                <th className="text-center">Precio</th>
                                <th className="text-center">Descuento</th>
                                <th className="text-center"></th>
                            </tr>
                        </thead>

                        {wishItems.map((item, pos) => {
                            return (
                                <tbody key={pos}>

                                    <Item {...item} />

                                </tbody>
                            );
                        })}

                    </table>
                </div>

                <div className="shopping-cart-footer">
                    <div className="column">
                        <form className="coupon-form" method="post">
                            <input className="form-control form-control-sm" type="text" placeholder="Código Cupon" required />
                            <button className="btn btn-outline-primary btn-sm" type="submit">Aplicar Cupón</button>
                        </form>
                    </div>
                    <div className="column text-lg">Total: <span className="text-medium">Q. {total}</span></div>
                </div>

                <div className="shopping-cart-footer">
                    <div className="column"><NavLink to="/search" className="btn btn-outline-secondary"><i className="icon-arrow-left" onClick={() => history.goBack()}></i>&nbsp;Volver a Tienda</NavLink></div>
                    <div className="column"><NavLink className="btn btn-success" to="/payment">Comprar ahora</NavLink></div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default CheckoutWish;