import React, { useEffect, useState } from 'react';
import { useParams, useHistory, NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, setProduct } from './../../redux/Products/products.actions';
import { addProduct } from './../../redux/Cart/cart.actions';
import { addWishProduct } from './../../redux/Wish/wish.actions';
import {
    TwitterShareButton, FacebookShareButton,
    FacebookIcon, TwitterIcon, WhatsappShareButton, WhatsappIcon
} from 'react-share';
import Button from './../Forms/Button';
import FormSelect from './../../components/Forms/FormSelect';
import { Alert } from '@material-ui/lab';
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player/youtube'
import "react-responsive-carousel/lib/styles/carousel.min.css";
const categorys = require('../../assets/data/categorys.json');

const mapState = state => ({
    product: state.productsData.product
});

const ProductCard = ({ }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [productQuantity, setProductQuantity] = useState(1);
    const [categoryDescription, setCategoryDescription] = useState("");
    const { productID } = useParams();
    const { product } = useSelector(mapState);
    const [displayError, setDisplayError] = useState(false);

    const {
        localProductURL,
        localProductURL2,
        localProductURL3,
        localProductURL4,
        localProductURL5,
        localProductVideo,
        productThumbnail,
        productThumbnail2,
        productThumbnail3,
        productThumbnail4,
        productThumbnail5,
        productName,
        productPrice,
        productNormalPrice,
        productDesc,
        productCategory,
        shortDesc,
        productAvailability
    } = product;

    useEffect(() => {
        dispatch(
            fetchProductStart(productID)
        )

        if (window.pageYOffset > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        return () => {
            dispatch(
                setProduct({})
            )
        }

    }, []);

    useEffect(() => {
        if (productCategory) {
            const categorySelected = categorys.filter(
                (category) => category.value === productCategory
            )[0];

            if (categorySelected) {
                setCategoryDescription(categorySelected.name);
            }
        }
    }, [productCategory])

    const handleAddToCart = (product) => {
        if (productQuantity > productAvailability) {
            setDisplayError(true);
            setTimeout(() => {
                setDisplayError(false)
            }, 6000);
            return;
        }

        if (!product) return;
        for (let i = 0; i < productQuantity; i++) {
            dispatch(
                addProduct(product)
            );
        }
        history.push('/cart');
    }

    const handleAddToWish = (product) => {
        if (!product) return;
        dispatch(
            addWishProduct(product)
        );
        history.push('/wish');
    };

    const configAddToCartBtn = {
        type: 'button'
    }

    const configAddToWishBtn = {
        type: 'button'

    };

    const YoutubeSlide = (url) => {
        let urlItem = url.url;
        let isVideoItem = url.isVideo;

        if (isVideoItem) {
            return <ReactPlayer width="100%" url={urlItem} playing={false} />
        } else {
            return <img width="100%" src={urlItem} />
        }

    };

    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

    const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

    const getVideoId = (url) => {
        return url.substr('https://www.youtube.com/embed/'.length, url.length)
    };

    const customRenderThumb = (children) =>

        children.map((item) => {
            let videoItem = item.props.url;
            let isVideoItem = item.props.isVideo;
            if (isVideoItem) {
                const videoId = getVideoId(videoItem);
                return <img width="100%" src={getVideoThumb(videoId)} />;
            } else {
                let srcItem = '';
                if (videoItem) {
                    srcItem = videoItem;
                    return <img width="100%" src={srcItem} />
                }
            }
        });


    return (


        <React.Fragment>
            <div className="offcanvas-wrapper">
                <div className="page-title">
                    <div className="container">
                        <div className="column">
                            <h1>Producto</h1>
                        </div>
                        <div className="column">
                            <ul className="breadcrumbs">
                                <li><Link to="/">Principal</Link>
                                </li>
                                <li className="separator">&nbsp;</li>
                                <li><Link to="/cart">Comprar</Link>
                                </li>
                                <li className="separator">&nbsp;</li>
                                <li>Producto</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container padding-bottom-3x mb-1">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="carousel-wrapper">
                                {localProductVideo && (<>
                                    {!productThumbnail && !localProductURL &&
                                        <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb} showIndicators={false} showStatus={false}>
                                            <YoutubeSlide key="youtube-2" url={`https://www.youtube.com/embed/${localProductVideo}`} isVideo={true} />
                                            <YoutubeSlide key="youtube-1" url="assets/img/white.png" isVideo={false} />
                                        </Carousel>
                                    }
                                    {localProductURL && !localProductURL2 && !localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                        <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb} showIndicators={false} showStatus={false}>
                                            <YoutubeSlide key="youtube-1" url={localProductURL} isVideo={false} />
                                            <YoutubeSlide key="youtube-2" url={`https://www.youtube.com/embed/${localProductVideo}`} isVideo={true} />
                                        </Carousel>
                                    }
                                    {localProductURL && localProductURL2 && !localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                        <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb} showIndicators={false} showStatus={false}>
                                            <YoutubeSlide key="youtube-1" url={localProductURL} isVideo={false} />
                                            <YoutubeSlide key="youtube-2" url={localProductURL2} isVideo={false} />
                                            <YoutubeSlide key="youtube-3" url={`https://www.youtube.com/embed/${localProductVideo}`} isVideo={true} />
                                        </Carousel>
                                    }
                                    {localProductURL && localProductURL2 && localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                        <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb} showIndicators={false} showStatus={false}>
                                            <YoutubeSlide key="youtube-1" url={localProductURL} isVideo={false} />
                                            <YoutubeSlide key="youtube-2" url={localProductURL2} isVideo={false} />
                                            <YoutubeSlide key="youtube-3" url={localProductURL3} isVideo={false} />
                                            <YoutubeSlide key="youtube-4" url={`https://www.youtube.com/embed/${localProductVideo}`} isVideo={true} />
                                        </Carousel>
                                    }
                                    {localProductURL && localProductURL2 && localProductURL3 && localProductURL4 && !localProductURL5 &&
                                        <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb} showIndicators={false} showStatus={false}>
                                            <YoutubeSlide key="youtube-1" url={localProductURL} isVideo={false} />
                                            <YoutubeSlide key="youtube-2" url={localProductURL2} isVideo={false} />
                                            <YoutubeSlide key="youtube-3" url={localProductURL3} isVideo={false} />
                                            <YoutubeSlide key="youtube-4" url={localProductURL4} isVideo={false} />
                                            <YoutubeSlide key="youtube-5" url={`https://www.youtube.com/embed/${localProductVideo}`} isVideo={true} />
                                        </Carousel>
                                    }
                                    {localProductURL && localProductURL2 && localProductURL3 && localProductURL4 && localProductURL5 &&
                                        <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb} showIndicators={false} showStatus={false}>
                                            <YoutubeSlide key="youtube-1" url={localProductURL} isVideo={false} />
                                            <YoutubeSlide key="youtube-2" url={localProductURL2} isVideo={false} />
                                            <YoutubeSlide key="youtube-3" url={localProductURL3} isVideo={false} />
                                            <YoutubeSlide key="youtube-4" url={localProductURL4} isVideo={false} />
                                            <YoutubeSlide key="youtube-5" url={localProductURL5} isVideo={false} />
                                            <YoutubeSlide key="youtube-6" url={`https://www.youtube.com/embed/${localProductVideo}`} isVideo={true} />
                                        </Carousel>
                                    }
                                </>
                                )
                                }


                                {!localProductVideo && (<>
                                    {!productThumbnail && !localProductURL &&
                                        < Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                            <div>
                                                <img src="assets/img/white.png" />
                                            </div>
                                        </Carousel>
                                    }
                                    {localProductURL && !localProductURL2 && !localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                            <div>
                                                <img src={localProductURL} />
                                            </div>
                                        </Carousel>
                                    }
                                    {localProductURL && localProductURL2 && !localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                            <div>
                                                <img src={localProductURL} />
                                            </div>
                                            <div>
                                                <img src={localProductURL2} />
                                            </div>
                                        </Carousel>
                                    }
                                    {localProductURL && localProductURL2 && localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                            <div>
                                                <img src={localProductURL} />
                                            </div>
                                            <div>
                                                <img src={localProductURL2} />
                                            </div>
                                            <div>
                                                <img src={localProductURL3} />
                                            </div>
                                        </Carousel>
                                    }
                                    {localProductURL && localProductURL2 && localProductURL3 && localProductURL4 && !localProductURL5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                            <div>
                                                <img src={localProductURL} />
                                            </div>
                                            <div>
                                                <img src={localProductURL2} />
                                            </div>
                                            <div>
                                                <img src={localProductURL3} />
                                            </div>
                                            <div>
                                                <img src={localProductURL4} />
                                            </div>
                                        </Carousel>
                                    }
                                    {localProductURL && localProductURL2 && localProductURL3 && localProductURL4 && localProductURL5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                            <div>
                                                <img src={localProductURL} />
                                            </div>
                                            <div>
                                                <img src={localProductURL2} />
                                            </div>
                                            <div>
                                                <img src={localProductURL3} />
                                            </div>
                                            <div>
                                                <img src={localProductURL4} />
                                            </div>
                                            <div>
                                                <img src={localProductURL5} />
                                            </div>
                                        </Carousel>
                                    }
                                    {!localProductURL && productThumbnail && !productThumbnail2 && !productThumbnail3 && !productThumbnail4 && !productThumbnail5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                            <div>
                                                <img src={productThumbnail} />
                                            </div>
                                        </Carousel>
                                    }
                                    {!localProductURL && productThumbnail && productThumbnail2 && !productThumbnail3 && !productThumbnail4 && !productThumbnail5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false} >
                                            <div>
                                                <img src={productThumbnail} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail2} />
                                            </div>
                                        </Carousel>
                                    }
                                    {!localProductURL && productThumbnail && productThumbnail2 && productThumbnail3 && !productThumbnail4 && !productThumbnail5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false} >
                                            <div>
                                                <img src={productThumbnail} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail2} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail3} />
                                            </div>
                                        </Carousel>
                                    }
                                    {!localProductURL && productThumbnail && productThumbnail2 && productThumbnail3 && productThumbnail4 && !productThumbnail5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false} >
                                            <div>
                                                <img src={productThumbnail} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail2} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail3} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail4} />
                                            </div>
                                        </Carousel>
                                    }
                                    {!localProductURL && productThumbnail && productThumbnail2 && productThumbnail3 && productThumbnail4 && productThumbnail5 &&
                                        <Carousel showStatus={false} showIndicators={false} showArrows={false} >
                                            <div>
                                                <img src={productThumbnail} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail2} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail3} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail4} />
                                            </div>
                                            <div>
                                                <img src={productThumbnail5} />
                                            </div>
                                        </Carousel>
                                    }
                                </>
                                )
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="padding-top-2x mt-2 hidden-md-up"></div>
                            <div className="rating-stars"><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i><i className="icon-star"></i>
                            </div><span className="text-muted align-middle">&nbsp;&nbsp;0 | 0 reseñas de clientes</span>
                            <h2 className="padding-top-1x text-normal">{productName}</h2><span className="h2 d-block">
                                {productNormalPrice && <del className="text-muted text-normal">{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productNormalPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</del>} &nbsp;  {(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>

                            <span
                                className="desc"
                                dangerouslySetInnerHTML={{ __html: shortDesc }} />

                            {displayError &&
                                <Alert variant="filled" severity="error" onClose={() => { setDisplayError(false) }}>
                                    La cantidad que seleccionaste es mayor a la disponible.
                                </Alert>
                            }

                            <div className="row margin-top-1x">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <FormSelect
                                            label="Cantidad"
                                            className="form-control"
                                            options={[
                                                {
                                                    value: "1",
                                                    name: "1"
                                                }, {
                                                    value: "2",
                                                    name: "2"
                                                }, {
                                                    value: "3",
                                                    name: "3"
                                                }, {
                                                    value: "4",
                                                    name: "4"
                                                }, {
                                                    value: "5",
                                                    name: "5"
                                                }, {
                                                    value: "6",
                                                    name: "6"
                                                }, {
                                                    value: "7",
                                                    name: "7"
                                                }, {
                                                    value: "8",
                                                    name: "8"
                                                }, {
                                                    value: "9",
                                                    name: "9"
                                                }, {
                                                    value: "10",
                                                    name: "10"
                                                }]}
                                            handleChange={e => setProductQuantity(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {productAvailability > 3 &&
                                <div className="pt-1 mb-2"><span className="text-medium">Disponibilidad:</span><div className="d-inline text-success"> En Stock</div></div>
                            }
                            {productAvailability == 0 &&
                                <div className="pt-1 mb-2"><span className="text-medium">Disponibilidad:</span><div className="d-inline text-danger"> No Disponible Por el Momento</div></div>
                            }
                            {productAvailability <= 3 && productAvailability > 0 &&
                                <div className="pt-1 mb-2"><span className="text-medium">Disponibilidad:</span>{productAvailability}</div>
                            }
                            <div className="pt-1 mb-2"><span className="text-medium">Referencia:</span> {productID}</div>
                            <div className="padding-bottom-1x mb-2"><span className="text-medium">Categoria:&nbsp;</span><Link className="navi-link" to={"/" + productCategory}>{categoryDescription}</Link></div>
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="entry-share mt-2 mb-2"><span className="text-muted">Compartir en: &nbsp;&nbsp;</span>
                                    <div className="share-links">
                                        <FacebookShareButton url={'https://mishop.gt/product/' + productID}
                                            hashtag="#ecommerce #mishop #onlineshopping #onlinestore #digital #ecommercestore #marketplace #shop #technology"
                                            quote="Conoce nuestros productos en www.miShop.gt">
                                            <FacebookIcon size={32} round={true} />
                                        </FacebookShareButton>
                                        <WhatsappShareButton title="miShop.gt"
                                            url={'https://mishop.gt/product/' + productID}>
                                            <WhatsappIcon size={32} round={true} />
                                        </WhatsappShareButton>
                                        <TwitterShareButton title="miShop.gt"
                                            url={'https://mishop.gt/product/' + productID}
                                            hashtag="#ecommerce #mishop #onlineshopping #onlinestore #digital #ecommercestore #marketplace #shop #technology">
                                            <TwitterIcon
                                                size={32} round={true} />
                                        </TwitterShareButton>
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-3" />
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="column"><NavLink to="/search" className="btn btn-outline-secondary"><i className="icon-arrow-left" onClick={() => history.goBack()}></i>&nbsp;Volver a Tienda</NavLink></div>
                                <div className="sp-buttons mt-2 mb-2">
                                    <Button {...configAddToWishBtn} onClick={() => handleAddToWish(product)} className="btn btn-outline-secondary btn-sm btn-wishlist" data-toggle="tooltip" title="Whishlist"><i className="icon-heart"></i></Button>
                                    <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)}>
                                        Agregar a Carrito
                                    </Button>
                                </div>

                            </div>


                        </div>


                    </div>
                </div>

                <div className="container">
                    <div className="col-lg-10 offset-lg-1">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item"><a className="nav-link active" data-toggle="tab" role="tab">Características</a></li>
                            {/* <li className="nav-item"><a className="nav-link" href="#reviews" data-toggle="tab" role="tab">Reviews (0)</a></li> */}
                        </ul>
                        <div className="tab-content">

                            <div className="tab-pane fade show active" id="description" role="tabpanel" dangerouslySetInnerHTML={{ __html: productDesc }}>

                            </div>

                            {/* <div className="tab-pane fade" id="reviews" role="tabpanel">


                                <h5 className="mb-30 padding-top-1x">Dejar Review</h5>
                                <form className="row" method="post">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="review_name">Tu Nombre</label>
                                            <input className="form-control form-control-rounded" type="text" id="review_name" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="review_email">Tu correo</label>
                                            <input className="form-control form-control-rounded" type="email" id="review_email" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="review_subject">Titulo</label>
                                            <input className="form-control form-control-rounded" type="text" id="review_subject" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="review_rating">Rating</label>
                                            <select className="form-control form-control-rounded" id="review_rating">
                                                <option>5 Stars</option>
                                                <option>4 Stars</option>
                                                <option>3 Stars</option>
                                                <option>2 Stars</option>
                                                <option>1 Star</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="review_text">Reseña </label>
                                            <textarea className="form-control form-control-rounded" id="review_text" rows="8" required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12 text-right">
                                        <button className="btn btn-outline-primary" type="submit">Enviar Reseña</button>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>
                <br />
            </div>

        </React.Fragment >
    );
}

export default ProductCard;
