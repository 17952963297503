import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { signOutUserStart } from './../../redux/User/user.actions';
import { selectCartItemsCount, selectCartItems, selectCartTotal } from './../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';
import ItemCart from '../Checkout/ItemCart';

const mapState = (state) => ({
  currentUser: state.user.currentUser,
  totalNumCartItems: selectCartItemsCount(state)
});

const mapState1 = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  itemCount: selectCartItemsCount
});

const Toolbar1 = (props) => {

  const dispatch = useDispatch();
  const { currentUser, totalNumCartItems } = useSelector(mapState);
  const { cartItems, total, itemCount } = useSelector(mapState1);
  const [buttonShop, setButtonShop] = useState(true);

  let displayName = "";

  if (currentUser) {
    displayName = currentUser.displayName;
  }

  if (displayName === null) {
    if (currentUser) {
      displayName = currentUser.email;
      if (displayName) {
        let initPosition = displayName.search("@");
        displayName = displayName.substr(0, initPosition - 1);
      } else {
        displayName = "Invitado"
      }

    }
  }

  const signOut = () => {
    dispatch(signOutUserStart());
  }

  useEffect(() => {
    if (itemCount < 1) {
      setButtonShop(false);
    } else {
      setButtonShop(true);
    }
  })

  return (
    <React.Fragment>
      <div className="toolbar">
        <div className="inner">
          <div className="tools">
            {/* <div className="search">
              <i className="icon-search"></i>
            </div> */}
            <div className="account">
              <NavLink to="/"></NavLink>
              <i className="icon-head"></i>
              <ul className="toolbar-dropdown">
                <li className="sub-menu-user">
                  <div className="user-ava">
                    <img
                      src="assets/img/account/login-user.png"
                      alt={displayName}
                    />
                  </div>
                  <div className="user-info">
                    <h6 className="user-name">{displayName}</h6>
                    <span className="text-xs text-muted">0 puntos</span>
                  </div>
                </li>
                {currentUser && (
                  <li>
                    <Link to="/dashboard">
                      <i className="icon-head"></i>Mi Cuenta
                    </Link>
                  </li>
                )}
                {currentUser && (
                  <li>
                    <Link to="/orders">
                      <i className="icon-bag"></i>Tus Pedidos
                    </Link>
                  </li>
                )}

                {currentUser && (
                  <li>
                    <Link to="/wishlist">
                      <i className="icon-heart"></i>Lista de Deseos
                    </Link>
                  </li>
                )}
                <li className="sub-menu-separator"></li>
                {!currentUser && (
                  <li>
                    <Link to="/registration">
                      <i className="icon-unlock"></i>Registrate
                    </Link>
                  </li>
                )}
                {!currentUser && (
                  <li>
                    <Link to="/login">
                      <i className="icon-unlock"></i>Iniciar Sesión
                    </Link>
                  </li>
                )}
                {currentUser && (
                  <li>
                    <Link to="" onClick={() => signOut()}>
                      {" "}
                      <i className="icon-lock"></i>Cerrar Sesión
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <div className="cart">
              <Link to="/cart"></Link>
              <i className="icon-bag"></i>
              <span className="count">{totalNumCartItems}</span>
              <span className="subtotal">Q. {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
              <div className="toolbar-dropdown">
                {cartItems.map((item, pos) => {
                  return (
                    <ItemCart key={pos} {...item} />
                  );
                })}

                <div className="toolbar-dropdown-group">
                  <div className="column">
                    <span className="text-lg">Total:</span>
                  </div>
                  <div className="column text-right">
                    <span className="text-lg text-medium">Q. {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}&nbsp;</span>
                  </div>
                </div>
                <div className="toolbar-dropdown-group">
                  <div className="column">
                    <NavLink
                      className="btn btn-sm btn-block btn-secondary"
                      to="/cart"
                    >
                      Ver Carrito
                    </NavLink>
                  </div>
                  {buttonShop && <>
                    <div className="column">
                      <NavLink
                        className="btn btn-sm btn-block btn-success"
                        to="/payment"
                      >
                        Comprar
                      </NavLink>
                    </div>
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Toolbar1;
