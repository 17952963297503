import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkUserIsAdmin } from './../../Utils';
import './styles.scss';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
})

const AdminToolbar = props => {
    const { currentUser } = useSelector(mapState);

    const isAdmin = checkUserIsAdmin(currentUser);
    if (!isAdmin) return null;

    return (

        <div className="topbar">
            <div className="topbar-column">
                <Link className="hidden-md-down" to="mailto:ayuda@mishop.gt"><i className="icon-mail"></i>&nbsp; info@mishop.gt</Link>
                <Link className="hidden-md-down" to="tel:57000094"><i className="icon-bell"></i>&nbsp; 57000094</Link>
                <Link className="social-button sb-facebook shape-none sb-dark" to="/" target="_blank"><i className="socicon-facebook"></i></Link>
                <Link className="social-button sb-twitter shape-none sb-dark" to="/" target="_blank"><i className="socicon-twitter"></i></Link>
                <Link className="social-button sb-instagram shape-none sb-dark" to="/" target="_blank"><i className="socicon-instagram"></i></Link>
                <Link className="social-button sb-pinterest shape-none sb-dark" to="/" target="_blank"><i className="socicon-pinterest"></i></Link>
            </div>
            <div className="topbar-column"><Link to="/admin">Mi Cuenta</Link>
            </div>
        </div>

    );
}

export default AdminToolbar;