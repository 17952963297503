import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderDetailsStart } from './../../redux/Orders/orders.actions';
import { useDispatch, useSelector } from 'react-redux';
import OrderDetails from './../../components/OrderDetails';
import { useHistory, NavLink } from 'react-router-dom';

const mapState = ({ ordersData }) => ({
    orderDetails: ordersData.orderDetails
});

const Order = () => {
    const { orderID } = useParams();
    const dispatch = useDispatch();
    const { orderDetails } = useSelector(mapState);
    const { orderTotal, status } = orderDetails;
    const history = useHistory();

    useEffect(() => {

        dispatch(
            getOrderDetailsStart(orderID)
        );

    }, []);

    return (
        <div>
            <div className="container padding-bottom-2x mb-1 col-lg-8">
                <div className="card mb-2 col-lg-8">
                    <div className="p-2 text-center text-white text-lg bg-dark rounded-top"><span className="text-uppercase">Tracking Order No - </span><span className="text-medium">{orderID}</span></div>
                    <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-2 px-2 bg-secondary">
                        <div className="w-100 text-center py-1 px-2"><span className='text-medium'>Envío Vía:</span></div>
                        <div className="w-100 text-center py-1 px-2"><span className='text-medium'>Estado:</span> {status}</div>
                        <div className="w-100 text-center py-1 px-2"><span className='text-medium'>Fecha Espera Entrega:</span> </div>
                    </div>
                    <div className="card-body">
                        {status == 'Creada' &&
                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-1x padding-bottom-1x">

                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-cart"></i></div>
                                    </div>
                                    <h4 className="step-title">Orden Creada</h4>
                                </div>
                                <div className="step">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-config"></i></div>
                                    </div>
                                    <h4 className="step-title">Procesando Orden</h4>
                                </div>
                                <div className="step">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-car"></i></div>
                                    </div>
                                    <h4 className="step-title">Producto en Camino</h4>
                                </div>
                                <div className="step">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-home"></i></div>
                                    </div>
                                    <h4 className="step-title">Producto Entregado</h4>
                                </div>

                            </div>
                        }
                        {status == 'Procesando' &&
                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-1x padding-bottom-1x">

                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-cart"></i></div>
                                    </div>
                                    <h4 className="step-title">Orden Creada</h4>
                                </div>
                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-config"></i></div>
                                    </div>
                                    <h4 className="step-title">Procesando Orden</h4>
                                </div>
                                <div className="step">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-car"></i></div>
                                    </div>
                                    <h4 className="step-title">Producto en Camino</h4>
                                </div>
                                <div className="step">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-home"></i></div>
                                    </div>
                                    <h4 className="step-title">Producto Entregado</h4>
                                </div>

                            </div>
                        }
                        {status == 'Camino' &&
                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-1x padding-bottom-1x">

                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-cart"></i></div>
                                    </div>
                                    <h4 className="step-title">Orden Creada</h4>
                                </div>
                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-config"></i></div>
                                    </div>
                                    <h4 className="step-title">Procesando Orden</h4>
                                </div>
                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-car"></i></div>
                                    </div>
                                    <h4 className="step-title">Producto en Camino</h4>
                                </div>
                                <div className="step">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-home"></i></div>
                                    </div>
                                    <h4 className="step-title">Producto Entregado</h4>
                                </div>

                            </div>
                        }
                        {status == 'Entregado' &&
                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-1x padding-bottom-1x">

                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-cart"></i></div>
                                    </div>
                                    <h4 className="step-title">Orden Creada</h4>
                                </div>
                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-config"></i></div>
                                    </div>
                                    <h4 className="step-title">Procesando Orden</h4>
                                </div>
                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-car"></i></div>
                                    </div>
                                    <h4 className="step-title">Producto en Camino</h4>
                                </div>
                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-home"></i></div>
                                    </div>
                                    <h4 className="step-title">Producto Entregado</h4>
                                </div>

                            </div>
                        }
                    </div>
                </div>
                {/* <div className="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
                    <div className="custom-control custom-checkbox mr-3">
                        <input className="custom-control-input" type="checkbox" id="notify_me" checked />
                        <label className="custom-control-label" for="notify_me">Notify me when order is delivered</label>
                    </div>
                    <div className="text-left text-sm-right"><a className="btn btn-outline-primary btn-rounded btn-sm" href="orderDetails" data-toggle="modal" data-target="#orderDetails">View Order Details</a></div>
                </div> */}
            </div>

            {/* <h1>
                Orden: {orderID}
            </h1> */}

            <OrderDetails order={orderDetails} />


            <div className="shopping-cart-footer">
                <div className="column"><NavLink to="/Orders" className="btn btn-outline-secondary"><i className="icon-arrow-left" onClick={() => history.goBack()}></i>&nbsp;Volver</NavLink></div>
                <div className="column">
                    <h3>
                        Total: Q.{orderTotal}
                    </h3>
                </div>
            </div>

        </div>
    )

}

export default Order;