import React from "react";
import Profile from './../../components/Profile';
import "./styles.scss";

const Dashboard = props => {
  return (
    <Profile />
  );
};

export default Dashboard;
