import React from "react";
import "./styles.scss";

const FormInputCard = ({ handleChange, label, ...otherProps }) => {
    return (
        <div className="col-sm-3">
            <div className="form-group">
                {label && <label>{label}</label>}
                <input
                    className="form-control"
                    onChange={handleChange}
                    {...otherProps}
                />
            </div>
        </div>
    );
};

export default FormInputCard;
