import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Toolbar1 from "../../components/Toolbar";
import Toolbar from '@material-ui/core/Toolbar';
import "./styles.scss";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import { NavLink } from "react-router-dom";
import List from '@material-ui/core/List';
import { useHistory } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DesktopMac from '@material-ui/icons/DesktopMac';
import PersonOutline from '@material-ui/icons/PersonOutline';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import LocalMall from '@material-ui/icons/LocalMall';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Home from '@material-ui/icons/Home';
import clsx from 'clsx';
import { Divider } from "@material-ui/core";
import { useDispatch } from 'react-redux'
import { signOutUserStart } from './../../redux/User/user.actions';
import algoliasearch from 'algoliasearch/lite';

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const useStyles1 = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const searchClient = algoliasearch('KDYSV5MBNR', '5cd08e207c3ed90ce0f281da4e17a979');

const searchIndex = searchClient.initIndex('mishop.gt');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black"
  },
  title: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: "white",
    paddingRight: "0px",
    paddingLeft: "10px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  drawerContainer: {
    background: "#374250",
    width: 290
  },
  toolbar1: {
    textAlign: "right"
  },
  site_menu: {
    textAlign: "center"
  }
}));

const Header = (props) => {
  const { currentUser } = useSelector(mapState);
  const classes = useStyles();
  const classes1 = useStyles1();
  const history = useHistory();
  const [searchFilter, setSearchFilter] = useState("");
  const [searchText, setSearchText] = useState('');
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  useEffect(() => {

    // if (!searchText) history.push('/search');

  }, [searchText])

  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(signOutUserStart());
  };

  const handleSearchBar = (event) => {
    setSearchFilter(event);
  }

  const handleFormSubmit = async e => {
    e.preventDefault();
    document.getElementById("btnSearch").focus();
    history.push('/search/' + 'p=' + searchText);
  }

  const handleSearch = async (queryText) => {
    // history.push('/search?p=' + searchText);
    document.getElementById("btnSearch").focus();
    history.push('/searchAl/' + 'p=' + searchText);
    // try {
    //   const result = await searchIndex.search(queryText);
    //   setSearchResult(result.hits);
    // } catch (error) {
    //   setSearchResult(null);

    // }
  }

  const handleFocus = () => {
    document.getElementById("btnSearch").focus();
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let displayName = "";

  if (currentUser) {
    displayName = currentUser.displayName;
  }

  const list = (anchor) => (
    <div
      className={clsx(classes1.list, {
        [classes1.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <div className="offcanvas-container" id="mobile-menu"><a className="account-link" href="account-orders.html">
            <div className="user-ava"><img src="assets/img/account/user-ava-md.jpg" alt="" />
            </div>
            <div className="user-info">
              <h6 className="user-name">{displayName}</h6><span className="text-sm text-white opacity-60"></span>
            </div></a>
          </div>
        </ListItem>
      </List>
      <Divider />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <List>
        <ListItem button onClick={() => history.push(`/aboutus`)}>
          <ListItemIcon><Home /></ListItemIcon>
          <ListItemText primary={'Nosotros'} />
        </ListItem>
        <ListItem button onClick={() => history.push(`/`)}>
          <ListItemIcon><Home /></ListItemIcon>
          <ListItemText primary={'Inicio'} />
        </ListItem>
        <ListItem button onClick={() => history.push(`/search`)}>
          <ListItemIcon><DesktopMac /></ListItemIcon>
          <ListItemText primary={'Productos'} />
        </ListItem>
        <ListItem button onClick={() => history.push(`/search/promotions`)}>
          <ListItemIcon><ShoppingCart /></ListItemIcon>
          <ListItemText primary={'Promociones'} />
        </ListItem>
        {currentUser && (
          <ListItem button onClick={() => history.push(`/orders`)}>
            <ListItemIcon><LocalMall /></ListItemIcon>
            <ListItemText primary={'Tus Pedidos'} />
          </ListItem>
        )}
        {currentUser && (
          <ListItem button onClick={() => history.push(`/cart`)}>
            <ListItemIcon><ShoppingCart /></ListItemIcon>
            <ListItemText primary={'Carrito'} />
          </ListItem>
        )}
        {currentUser && (
          <ListItem button onClick={() => history.push(`/wishlist`)}>
            <ListItemIcon><FavoriteBorder /></ListItemIcon>
            <ListItemText primary={'Lista de Deseos'} />
          </ListItem>
        )}
        {currentUser && (
          <ListItem button onClick={() => history.push(`/dashboard`)}>
            <ListItemIcon><PersonOutline /></ListItemIcon>
            <ListItemText primary={'Mi Cuenta'} />
          </ListItem>
        )}
        <Divider />
        {currentUser && (
          <ListItem button onClick={() => signOut()}>
            <ListItemIcon><Lock /></ListItemIcon>
            <ListItemText primary={'Cerrar Sesión'} />
          </ListItem>
        )}
        {!currentUser && (
          <ListItem button onClick={() => history.push(`/registration`)}>
            <ListItemIcon><Lock /></ListItemIcon>
            <ListItemText primary={'Registrarme'} />
          </ListItem>
        )}
        {!currentUser && (
          <ListItem button onClick={() => history.push(`/login`)}>
            <ListItemIcon><LockOpen /></ListItemIcon>
            <ListItemText primary={'Iniciar Sesión'} />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (

    <React.Fragment>
      <AppBar position="static" className={classes.header}>
        <header className="navbar navbar-sticky">

          {/* <form className="site-search" method="get">
            <input type="text" name="site_search" placeholder="Escribe el producto a buscar..." value={searchFilter} onChange={(e) => handleSearchBar(e.target.value)} />
            <div className="search-tools"><span className="clear-search">Limpiar</span><span className="close-search"><i className="icon-cross"></i></span></div>
          </form> */}



          <Toolbar>
            {['left'].map((anchor) => (
              <div key={anchor} className="section-mobil">
                <IconButton edge="start" className={classes.menuButton} onClick={toggleDrawer(anchor, true)} >
                  <MenuIcon />
                </IconButton>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                  {list(anchor)}
                </Drawer>
              </div>
            ))}

            <div className="site-branding" style={{ left: "40px" }}>
              <div className="inner">
                {/* <a class="offcanvas-toggle cats-toggle" href="#shop-categories" data-toggle="offcanvas"></a> */}
                {/* <a class="offcanvas-toggle menu-toggle" href="#mobile-menu" data-toggle="offcanvas"></a> */}
                <a className="site-logo" href="/"><img src="../../assets/img/logo/Logo-HD.jpg" alt="miShop.gt" /></a>
              </div>
            </div>
            {/* <div className='site-menu'>
              <a href="/" className="site-logo">
                <img src="../../assets/img/logo/Logo-HD.jpg" alt="miShop.gt" />
              </a>
            </div> */}
            <nav className='site-menu'>
              <ul>
                <li>
                  <NavLink to="/aboutus"
                    className="">
                    <span >Nosotros</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/"
                    className="">
                    <span >Inicio</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/search"
                    className="">
                    <span >Productos</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/search/promotions"
                    className="">
                    <span >Promociones</span>
                  </NavLink>
                </li>
              </ul>
            </nav>

            <Toolbar1 className={classes.toolbar1} currentUser={currentUser} />
          </Toolbar>
          <div className="container" style={{
            justifyContent: "center", display: "flex", width: "100%"
          }}>
            <div className="cols-6 margin-bottom-1x" style={{ justifyContent: "center", display: "flex", width: "100%", marginTop: "10px" }}>
              <div className="input-group" style={{ width: "80%", justifyContent: "center" }}>
                {/* <div onSubmit={handleFormSubmit} action="/s">
                  <span className="input-group-btn"><button id="btnSearch" type="search" onClick={handleFormSubmit}> <i className="icon-search"></i></button></span>
                  <input type="search" className="form-control" placeholder="Busca tu producto y presiona Enter" onChange={e => setSearchText(e.target.value)} onBlur={handleFocus} value={searchText} onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSearch(searchText)
                  }} />
                </div> */}

                <form onSubmit={handleFormSubmit}>
                  <span className="input-group-btn"><button id="btnSearch" type="search" onClick={handleFormSubmit}> <i className="icon-search"></i></button></span>
                  <input type="search" className="form-control" placeholder="Busca tu producto + Enter" onChange={e => setSearchText(e.target.value)} onBlur={handleFocus} value={searchText} onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSearch(searchText)
                  }} />
                </form>
                {/* <form>
                  <span className="input-group-btn"><button type="search" onClick={handleFormSubmit}> <i className="icon-search"></i></button></span>
                  <input className="form-control" name="search" type="search" placeholder="Buscar" value={search} onChange={handleChange} />
                </form> */}
                {/* <span className="input-group-btn"><button type="search"><i className="icon-search"></i></button></span>
                <input type="search" className="form-control" placeholder="Busca tu producto y presiona Enter" onChange={e => setSearchText(e.target.value)} value={searchText} onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSearch(searchText)
                }} /> */}
              </div>
            </div>
          </div>

        </header>
      </AppBar >
    </React.Fragment >
  );
};

Header.defaultProps = {
  currentUser: null,
};

export default Header;
