import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { resetPasswordStart, resetUserState } from './../../redux/User/user.actions';

import "./styles.scss";

import FormInput from "../Forms/FormInput";


const mapState = ({ user }) => ({
  resetPasswordSuccess: user.resetPasswordSuccess,
  userErr: user.userErr
});

const EmailPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { resetPasswordSuccess, userErr } = useSelector(mapState);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(resetUserState());
      history.push('/login');
    }
  }, [resetPasswordSuccess]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(resetPasswordStart({ email }));

  };

  return (
    <React.Fragment>
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>Recuperación de Contraseña</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li>
                <a href="index.html">Principal</a>
              </li>
              <li className="separator">&nbsp;</li>
              <li>
                <a href="account-orders.html">Mi Cuenta</a>
              </li>
              <li className="separator">&nbsp;</li>
              <li>Recuperación de Contraseña</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container padding-bottom-3x mb-2">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <h2>¿Olvidaste tu Contraseña?</h2>
            <p>
              Cambia tu contraseña en 3 pasos simples. Esto te ayudará a tener
              una nueva contraseña segura.
            </p>
            <ol className="list-unstyled">
              <li>
                <span className="text-primary text-medium">1. </span>Ingresa tu
                correo electrónico.
              </li>
              <li>
                <span className="text-primary text-medium">2. </span>Te estaremos enviando
                un código temporal.
              </li>
              <li>
                <span className="text-primary text-medium">3. </span>Usa dicho código
                para cambiar tu contraseña en nuestro sitio seguro.
              </li>
            </ol>

            <form className="card mt-4" onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="form-group">
                  {/* <label for="email-for-pass">Enter your email address</label>
                  <input
                    className="form-control"
                    type="email"
                    id="email-for-pass"
                    name="email"
                    value={email}
                    handleChange={(e) => setEmail(e.target.value)}
                    required
                  /> */}
                  <FormInput
                    type="text"
                    name="email"
                    value={email}
                    placeholder="Email"
                    label="Email"
                    handleChange={(e) => setEmail(e.target.value)}
                  />
                  <small className="form-text text-muted">
                    Indica tu correo electrónico utilizado en el registro con nosotros
                    Luego te enviaremos un codigo a dicho correo electrónico.
                  </small>
                </div>
              </div>
              {errors.length > 0 && (
                <div className="alert alert-danger alert-dismissible fade show text-center margin-bottom-1x">
                  <span className="alert-close" data-dismiss="alert"></span>
                  {errors.map((err, index) => {
                    return <li key={index}>{err}</li>;
                  })}
                </div>
              )}
              <div className="card-footer">
                <button className="btn btn-primary" type="submit">
                  Obtener Nueva Contraseña
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailPassword;
