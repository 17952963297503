import wishTypes from './wish.types';

export const addWishProduct = (nextWishItem) => ({
    type: wishTypes.ADD_TO_WISH,
    payload: nextWishItem
});

export const removeWishItem = (wishItem) => ({
    type: wishTypes.REMOVE_WISH_ITEM,
    payload: wishItem
});

export const reduceWishItem = (wishItem) => ({
    type: wishTypes.REDUCE_WISH_ITEM,
    payload: wishItem
});