const productsTypes = {
    ADD_NEW_PRODUCT_START: 'ADD_NEW_PRODUCT_START',
    UPDATE_PRODUCT_START: 'UPDATE_PRODUCT_START',
    FETCH_PRODUCTS_START: 'FETCH_PRODUCTS_START',
    FETCH_PRODUCT_START_SKU: 'FETCH_PRODUCT_START_SKU',
    FETCH_SPECIAL_PRODUCTS_START: 'FETCH_SPECIAL_PRODUCTS_START',
    SET_PRODUCTS: 'SET_PRODUCTS',
    SET_SPECIAL_PRODUCTS: 'SET_SPECIAL_PRODUCTS',
    DELETE_PRODUCT_START: 'DELETE_PRODUCT_START',
    FETCH_PRODUCT_START: 'FETCH_PRODUCT_START',
    SET_PRODUCT: 'SET_PRODUCT',
};

export default productsTypes;