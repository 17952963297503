import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import UserProfile from './../UserProfile';
import { signOutUserStart } from './../../redux/User/user.actions';
import './styles.scss';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
})

const VerticalNav = ({ children }) => {
    const { currentUser } = useSelector(mapState);

    const configUserProfile = {
        currentUser
    }

    const dispatch = useDispatch();

    const signOut = () => {
        dispatch(signOutUserStart());
    };

    return (

        <div className="col-lg-4">
            <aside className="user-info-wrapper">
                <div className="user-cover" style={{ backgroundImage: "assets/img/account/user-cover-img.jpg" }}>
                </div>
                <UserProfile {...configUserProfile} />
            </aside>
            <nav className="list-group">
                <Link to="/admin" className="list-group-item with-badge" ><i className="icon-ribbon"></i>Productos</Link>
                <Link to="/addProduct" className="list-group-item with-badge" ><i className="icon-bag"></i>Agrega Producto</Link>
                <Link to="/quotation" className="list-group-item with-badge" ><i className="icon-bag"></i>Cotizador</Link>
                <Link to="/updateProduct" className="list-group-item with-badge" ><i className="icon-bag"></i>Actualizar Producto</Link>
                <Link to="/updateProductSKU" className="list-group-item with-badge" ><i className="icon-bag"></i>Actualizar Producto por SKU</Link>
                <Link to="/addCategory" className="list-group-item with-badge" ><i className="icon-air-play"></i>Agregar Categorías</Link>
                <Link to="/cancelPurchase" className="list-group-item with-badge" ><i className="icon-paper"></i>Anular Compra</Link>
                <Link to="/updateStatusOrder" className="list-group-item with-badge" ><i className="icon-paper"></i>Cambio de Estado Ordenes</Link>
                <Link to="/productInfo" className="list-group-item with-badge" ><i className="icon-paper"></i>Consulta producto por ID</Link>
                <Link to="/productsku" className="list-group-item with-badge" ><i className="icon-paper"></i>Consulta producto por SKU</Link>
                <a href="#" className="list-group-item with-badge" onClick={() => signOut()}><i className="icon-unlock"></i>Cerrar Sesión</a>
            </nav>
        </div>


    );
}

export default VerticalNav;