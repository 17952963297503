import React from 'react'
import { Link } from 'react-router-dom';

export const Categories = () => {
    return (
        <>
            <section className="container padding-top-3x">
                <h3 className="text-center mb-30">Categorías</h3>
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/personalcare">
                                <div className="main-img"><img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Dia%20de%20la%20madre%202022%2FAirfryer%20lateral.png?alt=media&token=fa50f85c-9fe0-4ab7-81cb-effff26b8034" alt="Category" /></div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Dia%20de%20la%20madre%202022%2FSecadora%20atras%20lateral.png?alt=media&token=485a0288-3745-440c-a87e-53311aa2eae2" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Dia%20de%20la%20madre%202022%2Falisadora%20onduladora.png?alt=media&token=354fb211-9221-4535-ba2d-bd8d7c3ef5b0" alt="Category" />
                                </div></Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Cuidado Personal</h4>
                                <p className="text-muted">Desde Q 89.99</p><Link className="btn btn-outline-primary btn-sm" to="search/personalcare">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30"><Link className="card-img-tiles" to="search/notebooks">
                            <div className="inner">
                                <div className="main-img"><img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/notebooks%2F03C4t1DxmW1F8qlU7GdH-03.png?alt=media&token=6ccdaf47-d6e7-4fbb-a812-0bb9a1aae54c" alt="Category" /></div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/notebooks%2F00CtFRIdK5qB7zayheRY-01.png?alt=media&token=47844d26-b7db-450d-8353-cf34a5283d7d" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/notebooks%2F7xEg5BgahlXihZySJu8n-01.png?alt=media&token=7327abee-f17d-4cbc-9c89-a571b9df72ab" alt="Category" />
                                </div>
                            </div></Link>
                            <div className="card-body text-center">
                                <h4 className="card-title">Computadoras Portátiles</h4>
                                <p className="text-muted">Desde Q2056.00</p><Link className="btn btn-outline-primary btn-sm" to="search/notebooks">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/audio">
                                <div className="main-img"><img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/audio_y_video%2FAw7uWI39G5oEPA6l2TN5_02.png?alt=media&token=3ca6b8a3-d30b-4819-bb2c-64c172833fce" alt="Category" /></div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/audio_y_video%2FRgKchCWZSEAwb7hs3CpC_01.png?alt=media&token=79ae00b0-1aaa-4898-92db-6fef9587cfb4" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/audio_y_video%2F2aSyJyrHkUaOlLrQ8Fkm-01.png?alt=media&token=7b3b4138-6b3b-4666-b41b-48faf38abe93" alt="Category" /></div>
                            </Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Audio y Video</h4>
                                <p className="text-muted">Desde Q299.00</p><Link className="btn btn-outline-primary btn-sm" to="search/audio">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/phones">
                                <div className="main-img"><img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Celular%2Fz5ko9eJp7zgYOAXhvNIq_01.png?alt=media&token=6d1aeaaa-3217-4e8f-b8ed-2cee609627df" alt="Category" /></div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Celular%2F3UFe1UBujnviaCQtFxd1_01.png?alt=media&token=74053559-0b83-40e9-9fb5-a54f86631e1a" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Celular%2FAeRSsgNmzBb1bYOzkrgY_02.png?alt=media&token=b7175ff7-8313-4cfa-af37-9a232e8a1b12" alt="Category" /></div></Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Telefonos y Accesorios</h4>
                                <p className="text-muted">Desde Q127.00</p><Link className="btn btn-outline-primary btn-sm" to="search/phones">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/television">
                                <div className="main-img"><img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Televisores%2FTV043XIA05.jpeg?alt=media&token=742bf66b-b8d7-4480-82c7-6ac46c6abd76" alt="Category" /></div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Televisores%2F50UQ7500PSF_1.webp?alt=media&token=23ca120f-1a96-4f8b-994b-96a24e15e7f0" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Televisores%2F55UN732C0UF.webp?alt=media&token=c6c55a03-5c2d-4170-ae1c-c121339f89f2" alt="Category" />
                                </div>
                            </Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Televisores</h4>
                                <p className="text-muted">Desde Q1549.99</p><Link className="btn btn-outline-primary btn-sm" to="search/television">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/monitors">
                                <div className="main-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Monitores%2F2hMPjgU0yV9PFvPSa1C2-01.png?alt=media&token=7d803564-b6cc-450e-a31f-ca18060f8dcf" alt="Category" /></div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Monitores%2FBTVgmJegzgzASzC1Rha7-03.png?alt=media&token=fe4bbc6a-98d7-46b9-a162-90084022b1c1" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Monitores%2F9QUHb0DBVLe0zfdkk0Dd-03.png?alt=media&token=b75491ba-722c-486f-9c56-269f4174d6ba" alt="Category" />
                                </div>
                            </Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Monitores</h4>
                                <p className="text-muted">Desde Q399.00</p><Link className="btn btn-outline-primary btn-sm" to="search/monitors">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/Periféricos">
                                <div className="main-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Perif%C3%A9ricos%2FORGlxJNenCDw9ur5Uuko_01.png?alt=media&token=663a38a1-8e66-4481-8dc9-dfe36b0327ec" alt="Category" />
                                </div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Perif%C3%A9ricos%2F2X85fTSsGgbXWfsCe5j7_02.png?alt=media&token=a70939e3-8ac4-4082-bc4e-258c720302a7" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Perif%C3%A9ricos%2FBTnFD9NELXxqZWVHspcE_01.png?alt=media&token=526ddcad-7664-4499-8724-2ad9e3ede0d3" alt="Category" />
                                </div></Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Perifericos</h4>
                                <p className="text-muted">Desde Q56.00</p><Link className="btn btn-outline-primary btn-sm" to="search/Periféricos">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/chairs">
                                <div className="main-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Sillas%2F1G6Tt2EhhLl5kceEFct0_01.png?alt=media&token=b772562d-c071-49f6-9110-e64af9110fbb" alt="Category" />
                                </div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Sillas%2F1ZLLOnTqZTsTZxcuy2L9_01.png?alt=media&token=a88e9a51-a7f3-4951-b7e7-5c42bafb14fb" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Sillas%2FKmCYmKZuLol3g6RvmC5q_03.png?alt=media&token=4840fd3e-10fa-485a-8955-a70891886055" alt="Category" />
                                </div>
                            </Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Sillas</h4>
                                <p className="text-muted">Desde Q1549.99</p><Link className="btn btn-outline-primary btn-sm" to="search/chairs">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/homeappliances">
                                <div className="main-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Electrodom%C3%A9sticos%2FG6rOdLmbqBdaDbcJlinb_01.png?alt=media&token=df1a391f-cb8e-495b-991a-410551ff53e9" alt="Category" />
                                </div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Electrodom%C3%A9sticos%2F0HnRcDYmTnGlwOkrI5y7_02.png?alt=media&token=41c01d61-88f6-4add-9794-ab56d867260c" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Electrodom%C3%A9sticos%2FC0RoBHCk2iEDwrWv2MP4_01.png?alt=media&token=f055fc04-5d2c-4e58-9794-0b59286a661d" alt="Category" />
                                </div>
                            </Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Electrodomésticos</h4>
                                <p className="text-muted">Desde Q399.00</p><Link className="btn btn-outline-primary btn-sm" to="search/homeappliances">Ver Productos</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/networks">
                                <div className="main-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Redes%2FzokFC0s3scGMpG7YXj27-01.png?alt=media&token=c8fd4f3d-7fcf-4466-be9a-1e61866f1eed" alt="Category" />
                                </div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Redes%2FAxk7pNRHZAkEkkCqNaRA-01.png?alt=media&token=5e8fc8ee-2f08-4f76-ae7d-f31558fdf3a0" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Redes%2FQ3Xvfox38ZuSxV0ZJ89Y-01.png?alt=media&token=b1bba571-56be-42a7-a817-7f5d76f9473a" alt="Category" />
                                </div>
                            </Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Redes</h4>
                                <p className="text-muted">Desde Q249.99</p><Link className="btn btn-outline-primary btn-sm" to="search/networks">Ver Productos</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/techtoys">
                                <div className="main-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Juguetes%2F08talo1y2x5d8O4aYowb-02.png?alt=media&token=43e677de-9f23-4781-b13b-2d8296002068" alt="Category" />
                                </div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Juguetes%2FIQxKe43mRWK3DlCPjQOe-01.png?alt=media&token=62a5df94-36fd-4e9d-9002-667a38314fc4" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Juguetes%2F08talo1y2x5d8O4aYowb-03.png?alt=media&token=b32f67cf-14e4-4311-9145-40f076795290" alt="Category" />
                                </div>
                            </Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Drones y Juguetes</h4>
                                <p className="text-muted">Desde Q399.00</p><Link className="btn btn-outline-primary btn-sm" to="search/techtoys">Ver Productos</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <div className="card mb-30">
                            <div className="inner"><Link className="card-img-tiles" to="search/forniture">
                                <div className="main-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Oficina%2F0hngpEkoIa2i9ClhnmbH_01.png?alt=media&token=7d9a9007-38ff-4b76-8b54-77ab89be1586" alt="Category" />
                                </div>
                                <div className="thumblist">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Oficina%2FIMHNjA4btWr7eQujObao_01.png?alt=media&token=b53c1968-57ca-4c45-88cd-02f268820068" alt="Category" />
                                    <img src="https://firebasestorage.googleapis.com/v0/b/unishop-jm.appspot.com/o/Oficina%2FMlEVvtXqRVzM6qv0Zi7Y_01.png?alt=media&token=34c2e7c2-9878-4d7e-8814-a035e327eb81" alt="Category" />
                                </div>
                            </Link>
                            </div>
                            <div className="card-body text-center">
                                <h4 className="card-title">Oficina</h4>
                                <p className="text-muted">Desde Q556.00</p><Link className="btn btn-outline-primary btn-sm" to="search/forniture">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center"><Link className="btn btn-outline-secondary margin-top-none" to="/search">Todas las Categorias</Link></div>
                <br />

            </section>
        </>
    )
}
