import { firestore } from './../../firebase/utils';

export const handleAddProduct = product => {
    return new Promise((resolve, reject) => {
        firestore
            .collection('products')
            .doc()
            .set(product)
            .then(() => {
                resolve();
            })
            .catch(err => {
                reject(err);
            })
    });
}

export const handleUpdateProduct = product => {
    return new Promise((resolve, reject) => {
        let documentID = product.documentID;
        firestore
            .collection('products')
            .doc(documentID)
            .update(product)
            .then(() => {
                resolve();
            })
            .catch(err => {
                reject(err);
            })
    });
}

export const handleFetchProducts = ({ filterType, startAfterDoc, persistProducts = [] }) => {
    return new Promise((resolve, reject) => {
        const pageSize = 9;
        let ref = '';

        if (filterType) {
            ref = firestore.collection('products').orderBy('productAvailability', 'desc').limit(pageSize);
        } else {
            ref = firestore.collection('products').orderBy('validImage', 'desc').orderBy('productAvailability', 'desc').limit(pageSize);
        }

        if (filterType) ref = ref.where('productCategory', '==', filterType);
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

        ref
            .get()
            .then(snapshot => {
                const totalCount = snapshot.size;

                const data = [
                    ...persistProducts,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            documentID: doc.id
                        }
                    })
                ];


                resolve({
                    data,
                    queryDoc: snapshot.docs[totalCount - 1],
                    isLastPage: totalCount < 1
                });
            })
            .catch(err => {
                reject(err);
            })
    })
}

export const handleFetchSpecialProducts = ({ filterType, startAfterDoc, persistProducts = [] }) => {
    return new Promise((resolve, reject) => {
        const pageSize = 8;
        const offerPrice = 0;
        let ref = firestore.collection('products').orderBy('createdDate').limit(pageSize);

        if (offerPrice) ref = ref.where('productSpecialPrice', '!=', offerPrice);
        if (filterType) ref = ref.where('productCategory', '==', filterType);
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

        ref
            .get()
            .then(snapshot => {
                const totalCount = snapshot.size;

                const data = [
                    ...persistProducts,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            documentID: doc.id
                        }
                    })
                ];
                resolve({
                    data,
                    queryDoc: snapshot.docs[totalCount - 1],
                    isLastPage: totalCount < 1
                });
            })
            .catch(err => {
                reject(err);
            })
    })
}

export const handleDeleteProduct = documentID => {
    return new Promise((resolve, reject) => {
        firestore
            .collection('products')
            .doc(documentID)
            .delete()
            .then(() => {
                resolve();
            })
            .catch(err => {
                reject(err);
            })
    });
}

export const handleFetchProduct = (productID) => {
    return new Promise((resolve, reject) => {
        firestore
            .collection('products')
            .doc(productID)
            .get()
            .then(snapshot => {

                if (snapshot.exists) {
                    resolve({
                        ...snapshot.data(),
                        documentID: productID
                    });
                }
            })
            .catch(err => {
                reject(err);
            })
    })
}

export const handleFetchProductSku = (productSku) => {

    return new Promise((resolve, reject) => {
        let ref = firestore.collection('products');

        ref = ref.where('sku', '==', productSku);

        ref
            .get()
            .then(snapshot => {

                const data = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            documentID: doc.id
                        }
                    })
                ];

                resolve({
                    ...data[0]
                });

            })
            .catch(err => {
                reject(err);
            })

    })
}