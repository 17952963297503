import React from 'react';
import ProductResults from './../../components/ProductResults';

const Search = () => {
    return (

        <ProductResults />

    );
}
export default Search;
