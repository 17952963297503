import React, { useCallback, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Switch, Route } from "react-router-dom";

// components
import AdminToolbar from './components/AdminToolbar';

// hoc
import WithAuth from "./hoc/withAuth";
import WithAdminAuth from './hoc/withAdminAuth';

// layouts
import MainLayout from "./layouts/MainLayout";
import HomepageLayout from "./layouts/HomepageLayout";
import AdminLayout from './layouts/AdminLayout';
import DashboardLayout from './layouts/DashboardLayout';
import CheckoutLayout from './layouts/CheckoutLayout';

// pages
import Homepage from "./pages/Homepage";
import Search from './pages/Search';
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Recovery from "./pages/Recovery";
import Dashboard from "./pages/Dashboard";
import Admin from './pages/Admin';
import CreateProduct from './pages/AdminProduct';
import CreateCategory from './pages/AdminCategory';
import ProductDetails from './pages/ProductDetails';
import CancelPurchase from './pages/AdminCancelPurchase';
import Orders from './pages/Orders';
import Order from './pages/Order';
import WishList from './pages/WishList';
import Address from "./pages/Address";
import Tickets from "./pages/Tickets";
import Cart from './pages/Cart';
import Wish from './pages/Wish';
import { checkUserSession } from "./redux/User/user.actions";
import Header from './components/Header';
import Footer from "./components/Footer";
import CompleteWizard from './components/CompleteOrder/CompleteWizard/index';

import PaymentDetails from './components/PaymentDetails/index';
import PaymentType from './components/PaymentType/index';
import StatusOrderAdmin from "./pages/StatusOrderAdmin";
import About from "./pages/About";
import ProductInfo from "./pages/ProductInfo";
import ProductSku from "./pages/ProductSku";
import UpdateProduct from "./pages/UpdateProduct";
import UpdateProductSKU from "./pages/UpdateProductSKU";
import { Quotation } from "./pages/Quotation";
import SearchAl from "./pages/SearchAl";

const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession())
  }, []);

  return (
    <div >
      <AdminToolbar />
      <Header {...props} />
      <Switch>
        <Route exact path="/" render={() => (
          <HomepageLayout>
            <Homepage />
          </HomepageLayout>
        )}
        />
        <Route
          exact path="/aboutus" render={() => (
            <MainLayout>
              <About />
            </MainLayout>
          )}
        />
        <Route
          exact path="/search" render={() => (
            <MainLayout>
              <Search />
            </MainLayout>
          )}
        />
        <Route
          exact path="/searchAl" render={() => (
            <MainLayout>
              <SearchAl />
            </MainLayout>
          )}
        />
        <Route path="/search/:filterType" render={() => (
          <MainLayout>
            <Search />
          </MainLayout>
        )} />
        <Route path="/product/:productID" render={() => (
          <MainLayout>
            <ProductDetails />
          </MainLayout>
        )} />
        <Route path="/cart" render={() => (
          <MainLayout>
            <Cart />
          </MainLayout>
        )} />
        <Route path="/payment" render={() => (
          <CheckoutLayout>
            <PaymentDetails />
          </CheckoutLayout>
        )} />
        <Route path="/paymentType" render={() =>
          <CheckoutLayout>
            <PaymentType />
          </CheckoutLayout>
        } />
        <Route path="/wish" render={() => (
          <MainLayout>
            <Wish />
          </MainLayout>
        )} />
        <Route
          path="/registration" render={() => (
            <MainLayout>
              <Registration />
            </MainLayout>
          )}
        />
        <Route
          path="/login" render={() => (
            <MainLayout>
              <Login />
            </MainLayout>
          )}
        />
        <Route
          path="/recovery"
          render={() => (
            <MainLayout>
              <Recovery />
            </MainLayout>
          )}
        />
        <Route path="/dashboard" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/orders" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Orders />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/wishlist" render={() => (
          <WithAuth>
            <DashboardLayout>
              <WishList />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/address" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Address />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/tickets" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Tickets />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/order/:orderID" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Order />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route
          path="/admin" render={() => (
            <WithAdminAuth>
              <AdminLayout>
                <Admin />
              </AdminLayout>
            </WithAdminAuth>
          )}
        />
        <Route
          path="/addProduct" render={() => (
            <WithAdminAuth>
              <AdminLayout>
                <CreateProduct />
              </AdminLayout>
            </WithAdminAuth>
          )}
        />
        <Route
          path="/updateProduct" render={() => (
            <WithAdminAuth>
              <AdminLayout>
                <UpdateProduct />
              </AdminLayout>
            </WithAdminAuth>
          )}
        />
        <Route
          path="/updateProductSKU" render={() => (
            <WithAdminAuth>
              <AdminLayout>
                <UpdateProductSKU />
              </AdminLayout>
            </WithAdminAuth>
          )}
        />
        <Route
          path="/quotation" render={() => (
            <WithAdminAuth>
              <AdminLayout>
                <Quotation />
              </AdminLayout>
            </WithAdminAuth>
          )}
        />
        <Route
          path="/addCategory" render={() => (
            <WithAdminAuth>
              <AdminLayout>
                <CreateCategory />
              </AdminLayout>
            </WithAdminAuth>
          )}
        />
        <Route path="/cancelPurchase" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <CancelPurchase />
            </AdminLayout>
          </WithAdminAuth>
        )} />
        <Route path="/updateStatusOrder" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <StatusOrderAdmin />
            </AdminLayout>
          </WithAdminAuth>
        )} />
        <Route path="/productInfo" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <ProductInfo />
            </AdminLayout>
          </WithAdminAuth>
        )} />
        <Route path="/productsku" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <ProductSku />
            </AdminLayout>
          </WithAdminAuth>
        )} />
        <Route
          path="/completeWizard" render={() => (
            <CompleteWizard />
          )}
        />
      </Switch>
      <Footer />
      <a className="scroll-to-top-btn" href="#"><i className="icon-arrow-up"></i></a>
      <div className="site-backdrop"></div>
    </div>
  );
};

export default App;
