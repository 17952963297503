import React from 'react';
import { useDispatch } from 'react-redux';
import { removeWishItem } from '../../../redux/Wish/wish.actions';
import { NavLink, Link } from 'react-router-dom';

const Item = (product) => {
    const dispatch = useDispatch();
    const {
        productName,
        productThumbnail,
        productPrice,
        documentID,
        productAvailability
    } = product;

    const handleRemoveWishItem = (documentID) => {
        dispatch(
            removeWishItem({
                documentID
            })
        );
    }

    return (
        <tr>
            <td>
                <div className="product-item">
                    <NavLink to={`/product/${documentID}`} className="product-thumb">
                        <img src={productThumbnail} alt={productName} />
                    </NavLink>
                    <div className="product-info">
                        <h4 className="product-title">
                            <NavLink to={`/product/${documentID}`}>
                                {productName}
                            </NavLink>
                        </h4>
                        <div className="text-lg text-medium text-muted">{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        <div>Disponibilidad:
                            <div className="d-inline text-success">{productAvailability > 0 ? " En Stock" : " Sin Stock"}</div>
                        </div>
                    </div>
                </div>
            </td>
            <td className="text-center">
                <Link className="remove-from-cart" to="" data-toggle="tooltip" title="Remove item">
                    <i className="icon-cross" onClick={() => handleRemoveWishItem(documentID)}></i>
                </Link>
            </td>
        </tr>
    );
}

export default Item;