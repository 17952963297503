import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { selectCartTotal, selectCartItemsCount, selectCartItems } from './../../redux/Cart/cart.selectors';
import { useSelector, useDispatch } from 'react-redux';
import FormInput from '../Forms/FormInput';
import FormInputCard from '../Forms/FormInputCard';
import { Link, useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { apiInstance, formatCreditCardNumber, formatExpirationDate, formatCVC } from '../../Utils';
import Cards from 'react-credit-cards';
import { Alert } from '@material-ui/lab';
import "react-credit-cards/es/styles-compiled.css";
import { clearCart } from '../../redux/Cart/cart.actions';
import { getOrderDetailsStart } from './../../redux/Orders/orders.actions';
import moment from 'moment';
import AddressFormSelect from './../../components/Forms/AddressFormSelect';
import emailjs from 'emailjs-com';

const mapState = createStructuredSelector({
    total: selectCartTotal,
    itemCount: selectCartItemsCount,
    cartItems: selectCartItems,
});

const mapState1 = ({ ordersData }) => ({
    orderDetails: ordersData.orderDetails
});

const mapState2 = ({ user }) => ({
    currentUser: user.currentUser,
});

const PaymentType = () => {
    const { total, itemCount, cartItems } = useSelector(mapState);
    const [envio, setEnvio] = useState(0.00);
    const [discount] = useState(0);
    const dispatch = useDispatch();
    const [newVoucher, setNewVoucher] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [nameOnCard1, setNameOnCard1] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [cvc, setCVC] = useState('');
    const [focused, setFocused] = useState('');
    const { orderDetails } = useSelector(mapState1);
    const { shipping, documentID } = orderDetails;
    const [disablePayment, setDisablePayment] = useState(false);
    const [displayErrorPayment, setDisplayErrorPayment] = useState(false);
    const [disableCashPayment, setDisableCashPayment] = useState(false);
    const [displayMessageCashPayment, setDisplayMessageCashPayment] = useState(false);
    const [cashPaymentMethod, setCashPaymentMethod] = useState(false);
    const [cardPaymentMethod, setCardPaymentMethod] = useState(false);
    const [stepFour, setStepFour] = useState(true);
    const [stepFive, setStepFive] = useState(false);
    const [quotes, setQuotes] = useState('03');
    const [quotesValue, setQuotesValue] = useState(false);
    const [creditCard, setCreditCard] = useState(true);
    const [quotesCard, setQuotesCard] = useState(false);
    const [itemsData, setItemsData] = useState([]);
    const { currentUser } = useSelector(mapState2);
    const history = useHistory();

    useEffect(() => {
        if (window.pageYOffset > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        handleOrderData();
        if (orderDetails) {
            dispatch(
                getOrderDetailsStart(orderDetails)
            );
        }
    }, [itemCount, orderDetails]);

    const handleVoucher = evt => {
        setNewVoucher(evt);
        sendEmailCreation(evt);
    }

    function sendEmailCreation() {
        let idOrder = '';
        let name = '';
        let address = '';
        if (shipping) {
            idOrder = documentID;
            name = shipping.name;
            address = shipping.address;
        } else {
            idOrder = orderDetails
        }

        if (shipping) {
            let templateParams = {
                orderId: idOrder,
                name: name,
                email: address.email,
                reply_to: 'info@mishop.gt'
            }

            emailjs.send('service_a59gipb', 'template_iikbjgn', templateParams, 'user_ZX1BFz0awK4x68kNH5vl7')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
        }


    }

    const handleIWSOrder = (idOrderV, cartItemsV) => {
        let orderIWS = [];
        cartItemsV.map(x => {
            if (x.sku) {
                orderIWS.push({ Sku: x.sku, Quantity: x.quantity })
            }
        })

        if (orderIWS.length >= 1) {
            apiInstance.post('payment/iwsorder', {
                CustomerOrderNumber: idOrderV,
                Items: orderIWS
            })
                .then((response) => {
                    console.log('response');
                })
                .catch((error) => {
                    console.log('error', error);
                })
        }
    }

    const handleInvoice = (idOrderV) => {

        if (idOrderV) {
            apiInstance.post('payment/invoice', {
                CustomerOrderNumber: idOrderV
            })
                .then((response) => {
                    console.log('response');
                })
                .catch((error) => {
                    console.log('error', error);
                })
        }
    }

    const handleCashComplete = () => {
        let idOrder = '';
        if (shipping) {
            idOrder = documentID;
        } else {
            idOrder = orderDetails
        }

        if (idOrder) {
            apiInstance.post('payment/cashpayment', {

                orderId: idOrder

            }).then((response) => {
                let infoVoucher = response.data;
                if (response.data) {
                    setCashPaymentMethod(true);
                    setCardPaymentMethod(false);
                    setItemsData(cartItems);
                    handleIWSOrder(idOrder, cartItems);
                    dispatch(
                        clearCart()
                    );
                    handleVoucher(infoVoucher);
                    setFive();
                }
            }).catch((error) => {
                setDisplayMessageCashPayment(true);
                console.log('error', error);
                setTimeout(() => {
                    setDisableCashPayment(false)
                }, 9000);
                setTimeout(() => {
                    setDisableCashPayment(false)
                }, 9000);
            })
        } else {
            setDisplayMessageCashPayment(true);
            setTimeout(() => {
                setDisableCashPayment(false)
            }, 9000);
            setTimeout(() => {
                setDisplayMessageCashPayment(false)
            }, 9000);
        }
    }

    const handleOrderData = async evt => {
        let idOrder = '';
        if (shipping) {
            idOrder = documentID;
        } else {
            idOrder = orderDetails;
        }

        if (idOrder) {
            apiInstance.post('payment/shippingprice', {
                orderId: idOrder,
            }).then((response) => {

                if (response.data) {
                    let infoVoucher = response.data;
                    let envio = 0.00;
                    if (infoVoucher) {
                        envio = infoVoucher.shippingPrice;
                    }
                    if (envio >= 0) {
                        setEnvio(envio);
                    }


                }
            }).catch((error) => {

            })
        }

    }

    const handleFormSubmitComplete = async evt => {
        evt.preventDefault();
        setDisablePayment(true);

        let card = cardNumber.replace(/\s+/g, '');
        let month = expirationDate.substr(0, 2);
        let year = expirationDate.substr(3, 2);

        let expDate = year + month;
        let idOrder = '';
        let totalQuotes = "";

        if (shipping) {
            idOrder = documentID;
        } else {
            idOrder = orderDetails;
        }

        if (quotesCard) {
            setQuotesValue(true);
        }

        if (quotes && quotesCard) {
            totalQuotes = "VC" + quotes;
        } else {
            totalQuotes = "";
        }

        if (idOrder) {

            apiInstance.post('payment/checkout', {

                cardNumber: card,
                cvv2: cvc,
                expirationDate: expDate,
                orderId: idOrder,
                name: nameOnCard1,
                additionalData: totalQuotes

            }).then((response) => {

                if (response.data) {
                    let infoVoucher = response.data;
                    setCardPaymentMethod(true);
                    setCashPaymentMethod(false);
                    setItemsData(cartItems);
                    handleIWSOrder(idOrder, cartItems);
                    handleInvoice(idOrder);
                    dispatch(
                        clearCart()
                    );
                    handleVoucher(infoVoucher);
                    setFive();
                }
            }).catch((error) => {
                setDisplayErrorPayment(true);
                console.log('error', error);
                setTimeout(() => {
                    setDisablePayment(false)
                }, 9000);
                setTimeout(() => {
                    setDisplayErrorPayment(false)
                }, 9000);
            })
        } else {
            setDisplayErrorPayment(true);
            setTimeout(() => {
                setDisablePayment(false)
            }, 9000);
            setTimeout(() => {
                setDisplayErrorPayment(false)
            }, 9000);
        }

    }

    const handleFocusChange = (e) => {
        setFocused(e.target.name)
    }

    const handleCard = (e) => {
        setCreditCard(true);
        setQuotesCard(false);
    }

    const handleQuotesCard = (e) => {
        setCreditCard(false);
        setQuotesCard(true);
    }

    const setFive = () => {
        setStepFour(false);
        setStepFive(true);
    }

    const handleRegister = () => {
        history.push('/registration');
    }

    return (
        <React.Fragment>
            {stepFour && <>
                <div className="col-xl-9 col-lg-8">
                    <div className="checkout-steps">
                        <Link to="#" className="active"><span className="angle"></span>3. Pago</Link>
                        <Link to="#" className="completed"><span className="angle"></span><span className="step-indicator icon-circle-check"></span>2. Detalle</Link>
                        {/* <Link to="#" className="completed"><span className="angle"></span><span className="step-indicator icon-circle-check"></span>2. Método de Envío</Link> */}
                        <Link to="#" className="completed"><span className="angle"></span><span className="step-indicator icon-circle-check"></span>1. Dirección</Link>
                    </div>
                    <h4>Elige Método de Pago</h4>
                    <hr className="padding-bottom-1x" />
                    <div className="accordion" id="accordion" role="tablist">
                        <div className="card">
                            <div className="card-header" role="tab">
                                <h6><a href="#card" data-toggle="collapse"><i className="icon-columns"></i>Pagar con Tarjeta de Crédito o Débito</a></h6>
                            </div>
                            <div className="collapse show" id="card" data-parent="#accordion" role="tabpanel">

                                {/* <br /> */}
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        {/* <thead className="thead-default">
                                        <tr>
                                            <th></th>
                                            <th>Método de Envío</th>
                                            <th>Por Tiempo</th>
                                            <th>Costo</th> 
                                        </tr>
                                    </thead> */}
                                        <tbody>
                                            <tr>
                                                <td className="align-middle">
                                                    <div className="custom-control custom-radio mb-0">
                                                        <input className="custom-control-input" value={creditCard} onClick={evt => handleCard(evt.target.value)} type="radio" id="courier" name="shipping-method" defaultChecked />
                                                        <label className="custom-control-label" htmlFor="courier"></label>
                                                    </div>
                                                </td>
                                                <td className="align-middle"><span className="text-medium">Tarjeta de Crédito o Débito</span><br /><span className="text-muted text-sm">Visa, MasterCard o American Express</span></td>
                                                {/* <td className="align-middle">1 día</td>
                                            <td className="align-middle">Q25.00</td> */}
                                            </tr>
                                            <tr>
                                                <td className="align-middle">
                                                    <div className="custom-control custom-radio mb-0">
                                                        <input className="custom-control-input" value={quotesCard} onClick={evt => handleQuotesCard(evt.target.value)} type="radio" id="local" name="shipping-method" />
                                                        <label className="custom-control-label" htmlFor="local"></label>
                                                    </div>
                                                </td>
                                                <td className="align-middle"><span className="text-medium">Cuotas</span><br /><span className="text-muted text-sm">Visa o MasterCard</span></td>
                                                {/* <td className="align-middle">1 a 2 días</td>
                                            <td className="align-middle">Q40.00</td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="card-body">
                                    <p>Aceptamos las siguientes tarjetas de Crédito:&nbsp;<img className="d-inline-block align-middle" src="assets/img/credit-cards.png" alt="Cerdit Cards" /></p>
                                    &nbsp;
                                    <Cards
                                        number={cardNumber}
                                        name={nameOnCard1}
                                        expiry={expirationDate}
                                        cvc={cvc}
                                        focused={focused}
                                    />
                                    <br />
                                    <form onSubmit={handleFormSubmitComplete} className="interactive-credit-card row">

                                        <FormInput
                                            required
                                            placeholder="Número de Tarjeta"
                                            label="Número de Tarjeta"
                                            minLength={15}
                                            pattern="[\d| ]{16,22}"
                                            handleChange={evt => setCardNumber(formatCreditCardNumber(evt.target.value))}
                                            onFocus={handleFocusChange}
                                            value={cardNumber}
                                            type="text"
                                        />
                                        <FormInput
                                            required
                                            placeholder="Nombre en Tarjeta"
                                            label="Nombre en Tarjeta"
                                            minLength={5} maxLength={30}
                                            handleChange={evt => setNameOnCard1(evt.target.value)}
                                            onFocus={handleFocusChange}
                                            value={nameOnCard1}
                                            type="text"
                                        />
                                        <FormInputCard
                                            required
                                            placeholder="MM/YY"
                                            label="Fecha Expiración"
                                            pattern="\d\d/\d\d"
                                            minLength={5} maxLength={5}
                                            handleChange={evt => setExpirationDate(formatExpirationDate(evt.target.value))}
                                            onFocus={handleFocusChange}
                                            value={expirationDate}
                                            type="text"
                                        />
                                        <FormInputCard
                                            required
                                            label="CVC"
                                            placeholder="CVC"
                                            pattern="\d{3,4}"
                                            handleChange={evt => setCVC(formatCVC(evt.target.value))}
                                            value={cvc}
                                            type="text"
                                            name="cvc"
                                            id="cvc"
                                            onFocus={handleFocusChange}
                                        />
                                        {quotesCard && <>
                                            <AddressFormSelect
                                                placeholder="Cuotas"
                                                label="Cuotas"
                                                options={[{
                                                    name: '3',
                                                    value: '03'
                                                }, {
                                                    name: '6',
                                                    value: '06'
                                                }, {
                                                    value: "10",
                                                    name: "10"
                                                }, {
                                                    value: "12",
                                                    name: "12"
                                                }, {
                                                    value: "18",
                                                    name: "18"
                                                }]}
                                                handleChange={e => setQuotes(e.target.value)}
                                            />
                                        </>
                                        }
                                        <div className="col-sm-6">
                                            <label>  </label>
                                            <button className="btn btn-outline-primary btn-block margin-top-none" type="submit" disabled={disablePayment}>Pagar</button>
                                        </div>
                                    </form>
                                    {displayErrorPayment &&
                                        <Alert variant="filled" severity="error" onClose={() => { setDisplayErrorPayment(false) }}>
                                            Error al procesar el pago — Verifica los datos ingresados e intenta nuevamente!
                                        </Alert>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" role="tab">
                                <h6><a className="collapsed" href="#paypal" data-toggle="collapse"><i className="socicon-paypal"></i>Pago en Efectivo</a></h6>
                            </div>
                            <div className="collapse" id="paypal" data-parent="#accordion" role="tabpanel">
                                <div className="card-body">
                                    <p>Mi pago será en efecitvo al momento de la entrega.</p>

                                    {/* <div className="card-body">
                                <p>El monto supera los Q. 500.00 deberás optar a otro metodo de pago ya sea pago con Tarjeta desde la Web o con VisaLink</p>
                            </div> */}
                                    <div className="col-12">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center"><a></a>
                                            <button className="btn btn-outline-primary margin-top-none" type="button" onClick={() => handleCashComplete()} disabled={disableCashPayment}>Completar</button>
                                        </div>
                                    </div>
                                    {displayMessageCashPayment &&
                                        <Alert variant="filled" severity="error" onClose={() => { setDisplayErrorPayment(false) }}>
                                            Ocurrió un error al procesar la orden  — Verifica los datos ingresados e intenta nuevamente!
                                        </Alert>
                                    }
                                    {/* <div className="col-sm-3">
                                <label>  </label>
                                <button className="btn btn-outline-primary btn-block margin-top-none" type="submit" disabled={disablePayment}>Completar</button>
                            </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header" role="tab">
                                <h6><a className="collapsed" href="#llegamarket" data-toggle="collapse"><i className="socicon-paypal"></i>Pago con Llega Market en Quetzales</a></h6>
                            </div>
                            <div className="collapse" id="llegamarket" data-parent="#accordion" role="tabpanel">
                                <div className="card-body">
                                    <p>Quiero pagar por medio de Llega Market.</p>
                                    <form action='https://host2.ypayme.com/spa/xpay/securew/ipay.asp' method='post' name='form1' target='_blank'>

                                        <input name='MerchantID' type='hidden' id='MerchantID' value='281' />
                                        <input name='Password' id='Password' type='hidden' value='11a22b33' />
                                        <input name='PID' id='PID' type='hidden' value='IC1' />
                                        <input name='ProductID' id='ProductID' type='hidden' value='Instructivo 1P1P' />
                                        <input name='BackAction' id='BackAction' type='hidden' value='B' />
                                        <input name='ReferenceNo' id='ReferenceNo' type='hidden' />
                                        <input name='Total' id='Total' type='hidden' value='1' />
                                        <input name='Description' id='Description' type='hidden' value='Instructivo 1P1P' />
                                        <input type='submit' name='checkouticard1' value='' style={{ backgroundImage: 'url(https://host2.ypayme.com/spa/xmma/images/LOGO-LLEGA.png)', height: '42px', width: '145px', border: '0px' }} />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header" role="tab">
                                <h6><a className="collapsed" href="#llegamarket" data-toggle="collapse"><i className="socicon-paypal"></i>Pago con Llega Market en Dolares</a></h6>
                            </div>
                            <div className="collapse" id="llegamarket" data-parent="#accordion" role="tabpanel">
                                <div className="card-body">
                                    <p>Quiero pagar por medio de Llega Market.</p>
                                    <form action='https://gpspay.io/spa/xpay/securew/ipay.asp' method='post' name='form1' target='_blank'>

                                        <input name='MerchantID' type='hidden' id='MerchantID' value='828' />
                                        <input name='Password' id='Password' type='hidden' value='11a22b33' />
                                        <input name='PID' id='PID' type='hidden' value='IC1' />
                                        <input name='ProductID' id='ProductID' type='hidden' value='Instructivo 1P1P' />
                                        <input name='BackAction' id='BackAction' type='hidden' value='B' />
                                        <input name='ReferenceNo' id='ReferenceNo' type='hidden' />
                                        <input name='Total' id='Total' type='hidden' value='1' />
                                        <input name='Description' id='Description' type='hidden' value='Instructivo 1P1P' />
                                        <input type='submit' name='checkouticard1' value='' style={{ backgroundImage: 'url(https://gpspay.io/spa/xmma/images/checkouticard.png)', height: '42px', width: '145px', border: '0px' }} />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header" role="tab">
                                <h6><a className="collapsed" href="#llegamarket" data-toggle="collapse"><i className="socicon-paypal"></i>Pago con Llega USA Precio Abierto</a></h6>
                            </div>
                            <div className="collapse" id="llegamarket" data-parent="#accordion" role="tabpanel">
                                <div className="card-body">
                                    <p>Quiero pagar por medio de Llega Market.</p>
                                    {/* <iframe title="Boton de Pago LlegaMarket" srcDoc={`

                                            <form action='https://web.llega.com/spa/xpay/securew/ipay.asp' method='post' name='form1' target='_blank'> 

                                                    <input name='MerchantID' type='hidden' id='MerchantID' value='827' />  
                                                    <input name='Password' id='Password' type='hidden' value='11a22b' />  
                                                    <input name='PID' id='PID' type='hidden' value='IC1' />  
                                                    <input name='ProductID' id='ProductID' type='hidden' value='Botón Llega Market' />  
                                                    <input name='BackAction' id='BackAction' type='hidden' value='B' />  
                                                    <input name='ReferenceNo' id='ReferenceNo' type='hidden' />   
                                                    Amount :<input name='Total' id='Total' type='text' value='' /> <br />  

                                            <script type='text/javascript' src='https://web.llega.com/Spa/XMMA/securew/jquery-2.0.2.min.js' ></script>  
                                                <script type='text/javascript' src='https://web.llega.com/Spa/XMMA/securew/Input-validation.js' ></script>  
                                                <script type='text/javascript'>$(document).ready(function () {$('#Total').ForceNumericOnly();});</script>  
                                                    <input name='Description' id='Description' type='hidden' value='Botón Llega Market' />  
                                                    <input type='submit' name='checkouticard1' value='' style='background-image: url(https://web.llega.com/spa/xmma/images/checkouticard.png); border: 0px; height: 42px; width: 145px;' />
                                                </form>

                                    `}
                                    /> */}

                                    <iframe title="Botón de Pago LlegaMarket" srcDoc={`

                                        <form action='https://web.llega.com/spa/xpay/securew/ipay.asp' method='post' name='form1' target='_blank'> 

                                                <input name='MerchantID' type='hidden' id='MerchantID' value='827' />  
                                                <input name='Password' id='Password' type='hidden' value='11a22b' />  
                                                <input name='PID' id='PID' type='hidden' value='IC1' />  
                                                <input name='ProductID' id='ProductID' type='hidden' value='Botón Llega Market' />  
                                                <input name='BackAction' id='BackAction' type='hidden' value='B' />  
                                                <input name='ReferenceNo' id='ReferenceNo' type='hidden' />   
                                                Amount :<input name='Total' id='Total' type='text' value='' /> <br />  

                                        <script type='text/javascript' src='https://web.llega.com/Spa/XMMA/securew/jquery-2.0.2.min.js' ></script>  
                                            <script type='text/javascript' src='https://web.llega.com/Spa/XMMA/securew/Input-validation.js' ></script>  
                                            <script type='text/javascript'>$(document).ready(function () {$('#Total').ForceNumericOnly();});</script>  
                                                <input name='Description' id='Description' type='hidden' value='Botón Llega Market' />  
                                                <input type='submit' name='checkouticard1' value='' style='background-image: url(https://web.llega.com/spa/xmma/images/checkouticard.png); border: 0px; height: 42px; width: 145px;' />
                                            </form>

                                    `}
                                    />



                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="checkout-footer margin-top-1x">
                        <div className="column hidden-xs-down">
                            <NavLink className="btn btn-outline-secondary" to="/payment"><i className="icon-arrow-left"></i><span className="hidden-xs-down">&nbsp;Regresar</span></NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4">
                    <aside className="sidebar">
                        <div className="padding-top-2x hidden-lg-up"></div>
                        <section className="widget widget-order-summary">
                            <h3 className="widget-title">Resumen de Orden</h3>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Subtotal Carrito:</td>
                                        <td className="text-medium">Q. {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td>Envío:</td>
                                        <td className="text-medium">Q. {envio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td>Descuento:</td>
                                        <td className="text-medium">Q. {discount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-lg text-medium">Q {(total + envio + discount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>

                    </aside>
                </div>
            </>
            }
            {
                stepFive && <>
                    <div className="offcanvas-wrapper">
                        <div className="container padding-bottom-3x mb-2">
                            <div className="card text-center">
                                <div className="card-body padding-top-2x">
                                    <h3 className="card-title">Gracias por tu orden No. <span className="text-medium">{newVoucher.orderId}</span></h3>
                                    {(currentUser.displayName == null) && <>
                                        <p>Para consultar tu historial de ordenes, has tu registro express <Link to='/registration'>aquí</Link></p>
                                    </>
                                    }
                                    {cashPaymentMethod &&
                                        <div className="row justify-content-md-center">
                                            <div className="col-lg-5">
                                                <h4 className="card-title">Información de Pago</h4>
                                                <p className="card-text">Método de Pago: <span className="text-medium">{newVoucher.paymentMethod}</span></p>
                                                <p className="card-text">Fecha y Hora: <span className="text-medium">{moment(newVoucher.dateTime).format('DD/MM/YYYY hh:mm:ss')}</span></p>
                                                <p className="card-text">Monto de la Venta: <span className="text-medium">{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(newVoucher.amount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                            </div>
                                        </div>
                                    }
                                    {cardPaymentMethod &&
                                        <div className="row justify-content-md-center">
                                            <div className="card col-lg-5">
                                                <h4 className="card-title">Voucher</h4>
                                                {/* <p className="card-title">Voucher<span className="text-medium"></span></p> */}
                                                <p className="card-text">Método de Pago: <span className="text-medium">{newVoucher.paymentMethod}</span></p>
                                                <p className="card-text">Fecha y Hora de la transacción: <span className="text-medium">{moment(newVoucher.dateTime).format('DD/MM/YYYY hh:mm:ss')}</span></p>
                                                <p className="card-text">Monto de la Venta: <span className="text-medium">{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(newVoucher.amount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                {quotesValue && <>
                                                    <p className="card-text"><span className="text-medium"> {parseInt(quotes)} Cuotas</span></p>
                                                </>
                                                }
                                                <p className="card-text">Nombre del Tarjeta Habiente: <span className="text-medium">{newVoucher.name}</span></p>
                                                <p className="card-text">No. Tarjeta Enmascarada: <span className="text-medium">{newVoucher.creditCardNumber}</span></p>
                                                <p className="card-text">No. de Referencia: <span className="text-medium">{newVoucher.referenceNumber}</span></p>
                                                <p className="card-text">No. de Autorización: <span className="text-medium">{newVoucher.authorizationNumber}</span></p>
                                                <p className="card-text">Afiliación: <span className="text-medium">{newVoucher.merchant}</span></p>
                                                <p className="card-text">No. de Auditoría: <span className="text-medium">{String(newVoucher.auditNumber).padStart(6, '0')}</span></p>
                                                <h5 className="card-title">- PAGADO ELECTRONICAMENTE - </h5>
                                            </div>
                                        </div>
                                    }
                                    <div className="padding-top-1x padding-bottom-1x">
                                        <NavLink className="btn btn-outline-secondary" to="/search">Volver a Tienda</NavLink>
                                        <NavLink className="btn btn-outline-primary" to="/orders"><i className="icon-location"></i>&nbsp;Ver ordenes</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </React.Fragment >
    )

}

export default PaymentType;