import React, { useEffect } from 'react';
import OrderHistory from './../../components/OrderHistory';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOrderHistory } from './../../redux/Orders/orders.actions';

const mapState = ({ user, ordersData }) => ({
    currentUser: user.currentUser,
    orderHistory: ordersData.orderHistory.data
});


const Orders = props => {
    const dispatch = useDispatch();
    const { currentUser, orderHistory } = useSelector(mapState);

    useEffect(() => {
        dispatch(
            getUserOrderHistory(currentUser.id)
        );

    }, []);

    return (
        <div className="table-responsive">
            <OrderHistory orders={orderHistory} />
        </div>
    )
}

export default Orders;