import React from 'react';

import Header from './../components/Header';
import VerticalNav from './../components/VerticalNav';
import Footer from './../components/Footer';

const AdminLayout = props => {

    return (
        <React.Fragment>
            {/* <Header {...props} /> */}

            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Administración de Productos</h1>
                    </div>
                    <div className="column">
                        <ul className="breadcrumbs">
                            <li><a href="index.html">Principal</a>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li><a href="account-orders.html">Mi Cuenta</a>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Administra tu Perfil</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container padding-bottom-3x mb-2">
                <div className="row">
                    <VerticalNav></VerticalNav>

                    <div className="col-lg-8">
                        <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                        {props.children}
                    </div>
                    {/* <div className="content">
                            {props.children}
                        </div> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default AdminLayout;