import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { emailSignInStart, googleSignInStart, facebookSignInStart } from "./../../redux/User/user.actions";

import FormInputLogin from "./../Forms/FormInputLogin";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const SignIn = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector(mapState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (currentUser) {
      resetForm();
      history.push("/");
    }
  }, [currentUser]);

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(emailSignInStart({ email, password }));
  };

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
    history.push("/");
    // history.goBack();
  };

  const handleFacebookSignIn = () => {
    dispatch(facebookSignInStart());
    history.push("/");
    // history.goBack();
  };

  // const handleTwitterSignIn = () => {
  //   dispatch(twitterSignInStart());
  //   history.push("/");
  // }

  return (
    <React.Fragment>
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>Iniciar Sesión / Mi Cuenta</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Principal</Link>
              </li>
              <li className="separator">&nbsp;</li>
              <li>
                <Link to="/dashboard">Mi Cuenta</Link>
              </li>
              <li className="separator">&nbsp;</li>
              <li>Iniciar Sesión</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container padding-bottom-3x mb-2">
        <div className="row">
          <div className="col-md-6">
            <form className="login-box" onSubmit={handleSubmit}>
              <div className="row margin-bottom-1x">
                <div className="col-xl-4 col-md-6 col-sm-4">
                  <a
                    className="btn btn-sm btn-block facebook-btn"
                    onClick={handleFacebookSignIn}
                  >
                    <i className="socicon-facebook"></i>&nbsp;Facebook Login
                  </a>
                </div>
                {/* <div className="col-xl-4 col-md-6 col-sm-4">
                  <a
                    className="btn btn-sm btn-block twitter-btn"
                    onClick={handleTwitterSignIn}
                  >
                    <i className="socicon-google"></i>&nbsp;Twitter Login
                  </a>
                </div> */}
                <div className="col-xl-4 col-md-6 col-sm-4">
                  <a
                    className="btn btn-sm btn-block google-btn"
                    onClick={handleGoogleSignIn}
                  >
                    <i className="socicon-google"></i>&nbsp;Google Login
                  </a>
                </div>
              </div>
              <h4 className="margin-bottom-1x">O utilizando el siguiente formulario</h4>
              <FormInputLogin
                type="email"
                name="email"
                value={email}
                icon="icon-mail"
                placeholder="Correo Electrónico"
                handleChange={(e) => setEmail(e.target.value)}
                required
              />
              <FormInputLogin
                type="password"
                name="password"
                value={password}
                icon="icon-lock"
                placeholder="Contraseña"
                handleChange={(e) => setPassword(e.target.value)}
                required
              />

              <div className="d-flex flex-wrap justify-content-between padding-bottom-1x">
                <Link to="/recovery" className="navi-link">
                  <p>¿Olvidaste tu Contraseña?</p>
                </Link>
              </div>
              <div className="text-center text-sm-right">
                <button
                  className="btn btn-primary margin-bottom-none"
                  type="submit"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
