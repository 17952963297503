import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { removeCartItem } from './../../../redux/Cart/cart.actions';

const ItemCart = (product) => {
    const dispatch = useDispatch();
    const {
        productName,
        productThumbnail,
        productPrice,
        quantity,
        documentID
    } = product;

    const handleRemoveCartItem = (documentID) => {
        dispatch(
            removeCartItem({
                documentID
            })
        );
    }

    return (

        <div className="dropdown-product-item">
            {/* <span className="dropdown-product-remove">
                <i className="icon-cross"></i>
            </span> */}
            <span className="dropdown-product-remove"
                onClick={() => handleRemoveCartItem(documentID)}>
                <i className="icon-cross"></i>
            </span>
            <NavLink className="dropdown-product-thumb" to={`/product/${documentID}`}>
                <img src={productThumbnail} alt={productName} />
            </NavLink>
            <div className="dropdown-product-info">
                <NavLink className="dropdown-product-title" to={`/product/${documentID}`}>
                    {productName}
                </NavLink>
                <span className="dropdown-product-details">{quantity} x {productPrice}</span>
            </div>
        </div>


    );
}

export default ItemCart;