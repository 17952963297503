import React from 'react';
import WishListDetail from './../../components/WishListDetail';

const WishList = props => {


    return (
        <div className="container padding-bottom-3x mb-2">
            <div className="row">
                <div className="col-lg-8">
                    <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                    <WishListDetail />
                </div>
            </div>
        </div>
    )
}

export default WishList;