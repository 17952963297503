import React, { useState, useEffect } from 'react';
import FormInput from '../../components/Forms/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import CancelPurchaseDetails from './../../components/CancelPurchaseDetails';
import { getOrderDetailsStart } from './../../redux/Orders/orders.actions';
import { NavLink } from 'react-router-dom';
import { apiInstance } from '../../Utils';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

const mapState = ({ ordersData }) => ({
    orderDetails: ordersData.orderDetails
});

const CancelPurchase = () => {
    const [orderID, setOrderId] = useState('');
    const dispatch = useDispatch();
    const { orderDetails } = useSelector(mapState);
    const { orderTotal } = orderDetails;
    const [stepOne, setStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);
    const [newVoucher, setNewVoucher] = useState('');
    const [displayErrorPayment, setDisplayErrorPayment] = useState(false);
    const [disablePayment, setDisablePayment] = useState(false);

    const handleVoucher = evt => {
        evt.creditCardNumber = orderDetails.cardNumber;
        setNewVoucher(evt);
    }

    const handleFormSubmit = e => {
        e.preventDefault();

        dispatch(
            getOrderDetailsStart(orderID)
        );
    }

    const handleCancelOrder = async => {
        setDisablePayment(true);

        let auditNumber = orderDetails.auditNumber;
        let name = orderDetails.name;
        let card = orderDetails.cardNumber;
        card = card.replace('XXXXXXXXXXXX', '000000000000');

        apiInstance.post('payment/annulment', {

            CardNumber: "0000000000000000",
            Cvv2: "000",
            ExpirationDate: "2401",
            OrderId: orderID,
            Name: name,
            type: "Annulment",
            auditNumber: auditNumber

        }).then((response) => {
            if (response.data) {
                let infoVoucher = response.data;
                handleVoucher(infoVoucher);
                setTwo();
            }

        }).catch((error) => {
            setDisplayErrorPayment(true);
            console.log('error', error);
            setTimeout(() => {
                setDisablePayment(false)
            }, 9000);
            setTimeout(() => {
                setDisplayErrorPayment(false)
            }, 9000);
        })

    }

    const setTwo = () => {
        setStepOne(false);
        setStepTwo(true);
    }

    return (
        <React.Fragment>
            {stepOne && <>
                <div>
                    <h2>Ingresar No. de Orden</h2>
                    <form onSubmit={handleFormSubmit} className="needs-validation">
                        <div className="row">
                            <FormInput
                                label="No. de Orden"
                                placeholder="Ingresar No. de Orden a Anular"
                                type="text"
                                name="orderId"
                                value={orderID}
                                handleChange={e => setOrderId(e.target.value)}
                                required
                            />
                        </div>
                        <div className="row">
                            <button type="submit" className="btn btn-primary btn-sm">
                                <span className="hidden-xs-down">Buscar Orden&nbsp;</span><i className="icon-search"></i>
                            </button>
                        </div>
                    </form>
                    <br /><br />
                    <h1>
                        Orden: {orderID}
                    </h1>
                    <CancelPurchaseDetails order={orderDetails} />


                    <div className="shopping-cart-footer">
                        <div className="column">
                            <h3>
                                Total: Q.{orderTotal}
                            </h3>
                        </div>
                    </div>
                    {displayErrorPayment &&
                        <Alert variant="filled" severity="error" onClose={() => { setDisplayErrorPayment(false) }}>
                            Error al anular el pago — Verifica que la orden haya sido pagada y la anulación sea el mismo día de la compra!
                        </Alert>
                    }
                    {orderDetails.paid &&
                        <div className="checkout-footer">
                            <div className="column"></div>
                            <div className="column">
                                <button className="btn btn-primary" onClick={() => handleCancelOrder()} disabled={disablePayment}>
                                    <span className="hidden-xs-down">Anular&nbsp;</span><i className="icon-skip"></i>
                                </button>
                            </div>
                        </div>
                    }

                </div >
            </>
            }
            {stepTwo && <>
                <div>
                    <div className="card text-center">
                        <div className="card-body">
                            <h3 className="card-title">Cancelación de orden!</h3>
                            <p className="card-text">Se canceló el número de orden <span className="text-medium">{newVoucher.orderId}</span></p>
                            <div className="row justify-content-md-center">
                                <div className="card col-lg-5">
                                    <h4 className="card-title">Voucher</h4>
                                    <p className="card-text">Método de Pago: <span className="text-medium">{newVoucher.paymentMethod}</span></p>
                                    <p className="card-text">Fecha y Hora de la transacción: <span className="text-medium">{moment(newVoucher.dateTime).format('DD/MM/YYYY hh:mm:ss')}</span></p>
                                    <p className="card-text">Monto de la Venta: <span className="text-medium">{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(newVoucher.amount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                    <p className="card-text">Nombre del Tarjeta Habiente: <span className="text-medium">{newVoucher.name}</span></p>
                                    <p className="card-text">No. Tarjeta Enmascarada: <span className="text-medium">{newVoucher.creditCardNumber}</span></p>
                                    <p className="card-text">No. de Referencia: <span className="text-medium">{newVoucher.referenceNumber}</span></p>
                                    <p className="card-text">No. de Autorización: <span className="text-medium">{newVoucher.authorizationNumber}</span></p>
                                    <p className="card-text">Afiliación: <span className="text-medium">{newVoucher.merchant}</span></p>
                                    <p className="card-text">No. de Auditoría: <span className="text-medium">{String(newVoucher.auditNumber).padStart(6, '0')}</span></p>
                                    <h5 className="card-title">- PAGADO ELECTRONICAMENTE - </h5>
                                </div>
                            </div>
                            <div className="padding-top-1x padding-bottom-1x">
                                <NavLink className="btn btn-outline-secondary" to="/admin">Volver</NavLink>
                                <NavLink className="btn btn-outline-primary" to="/orders"><i className="icon-location"></i>&nbsp;Ver ordenes</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </React.Fragment>
    );
}

export default CancelPurchase;