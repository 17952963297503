import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import UserProfile from './../UserProfile';
import { signOutUserStart } from './../../redux/User/user.actions';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
})

const ProfileVerticalNav = ({ children }) => {
    const { currentUser } = useSelector(mapState);

    const configUserProfile = {
        currentUser
    }

    const dispatch = useDispatch();

    const signOut = () => {
        dispatch(signOutUserStart());
    };

    return (

        <div className="col-lg-4">
            <aside className="user-info-wrapper">
                <div className="user-cover" style={{ backgroundImage: "assets/img/account/user-cover-img.jpg" }}>
                </div>
                <UserProfile {...configUserProfile} />
            </aside>
            <nav className="list-group">
                <Link to="/Orders" className="list-group-item with-badge" ><i className="icon-bag"></i>Mis Ordenes</Link>
                <Link to="/dashboard" className="list-group-item with-badge" ><i className="icon-head"></i>Mi Perfil</Link>
                <Link to="/Address" className="list-group-item with-badge" ><i className="icon-map"></i>Dirección de Envío</Link>
                <Link to="/wishlist" className="list-group-item with-badge" ><i className="icon-heart"></i>Lista de Deseos</Link>
                <Link to="/tickets" className="list-group-item with-badge" ><i className="icon-tag"></i>Mis Tickets</Link>
                <Link to="#" className="list-group-item with-badge" onClick={() => signOut()}><i className="icon-unlock"></i>Cerrar Sesión</Link>
            </nav>
        </div>


    );
}

export default ProfileVerticalNav;