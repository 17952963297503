import categorysTypes from './categorys.types';

export const addCategoryStart = categoryData => ({
    type: categorysTypes.ADD_NEW_CATEGORY_START,
    payload: categoryData
});

export const fetchCategorysStart = (filters = {}) => ({
    type: categorysTypes.FETCH_CATEGORYS_START,
    payload: filters
});
