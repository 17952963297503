import React from 'react';
import { useDispatch } from 'react-redux';
import { removeWishItem, addWishProduct, reduceWishItem } from '../../../redux/Wish/wish.actions';

const Item = (product) => {
    const dispatch = useDispatch();
    const {
        productName,
        productThumbnail,
        productPrice,
        quantity,
        documentID
    } = product;

    const handleRemoveWishItem = (documentID) => {
        dispatch(
            removeWishItem({
                documentID
            })
        );
    }

    const handleAddProduct = (product) => {
        dispatch(
            addWishProduct(product)
        )
    }

    const handleReduceItem = (product) => {
        dispatch(
            reduceWishItem(product)
        );
    }

    return (
        <tr>
            <td>
                <div className="product-item"><a className="product-thumb" href="shop-single.html"><img src={productThumbnail} alt={productName} /></a>
                    <div className="product-info">
                        <h4 className="product-title"><a href="shop-single.html">{productName}</a></h4>
                    </div>
                </div>
            </td>

            <td className="text-center">
                <button className="btn btn-link-info" type="button" onClick={() => handleReduceItem(product)} style={{ fontSize: 30 }}>-</button>
                <span className="text-center text-lg text-medium">
                    {quantity}
                </span>
                <button className="btn btn-link-info" type="button" onClick={() => handleAddProduct(product)} style={{ fontSize: 30 }}>+</button>
            </td>
            <td className="text-center text-lg text-medium">Q. {Intl.NumberFormat().format(productPrice)}</td>
            <td className="text-center text-lg text-medium">Q 0.00</td>
            <td className="text-center"><p className="remove-from-cart" data-toggle="tooltip" title="Remove item"><i className="icon-cross" onClick={() => handleRemoveWishItem(documentID)}></i></p></td>
        </tr>



    );
}

export default Item;