import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect'
import Header from './../components/Header';
import Footer from './../components/Footer';
import { selectCartItemsCount, selectCartTotal } from '../redux/Cart/cart.selectors';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const mapState = createStructuredSelector({
    total: selectCartTotal,
    itemCount: selectCartItemsCount
})

const CheckoutLayout = props => {
    const { total } = useSelector(mapState);
    const [shipping] = useState(0);
    const [discount] = useState(0);

    useEffect(() => {

        if (window.pageYOffset > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, []);

    return (
        <React.Fragment>
            <div className="offcanvas-wrapper">
                <div className="page-title">
                    <div className="container">
                        <div className="column">
                            <h1>Proceder a la Compra</h1>
                        </div>
                        <div className="column">
                            <ul className="breadcrumbs">
                                <li><Link to="/">Principal</Link>
                                </li>
                                <li className="separator">&nbsp;</li>
                                <li>Comprar</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container padding-bottom-3x mb-2">
                    <div className="row">
                        {/* <div className="col-xl-9 col-lg-8"> */}
                        {props.children}
                        {/* </div> */}
                        {/* <div className="col-xl-3 col-lg-4">
                            <aside className="sidebar">
                                <div className="padding-top-2x hidden-lg-up"></div>
                                <section className="widget widget-order-summary">
                                    <h3 className="widget-title">Resumen de Orden</h3>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Subtotal Carrito:</td>
                                                <td className="text-medium">Q. {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                            </tr>
                                            <tr>
                                                <td>Envío:</td>
                                                <td className="text-medium">Q. {shipping.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                            </tr>
                                            <tr>
                                                <td>Descuento:</td>
                                                <td className="text-medium">Q. {discount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="text-lg text-medium">Q {(total + shipping + discount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>

                            </aside>
                        </div> */}

                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}

export default CheckoutLayout;