import React from "react";
import "./styles.scss";

const FormInput = ({ handleChange, icon, ...otherProps }) => {
  return (
    <div className="form-group input-group">
      <input className="form-control" onChange={handleChange} {...otherProps} />
      <span className="input-group-addon">
        <i className={icon}></i>
      </span>
    </div>
  );
};

export default FormInput;
