import React from 'react';


const Profile = (props) => {

    return (
        <React.Fragment>
            <div className="padding-top-2x mt-2 hidden-lg-up"></div>
            <form className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-fn">Nombre</label>
                        <input className="form-control" type="text" id="account-fn" value="" required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-ln">Apellidos</label>
                        <input className="form-control" type="text" id="account-ln" value="" required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-email">Correo Electrónico</label>
                        <input className="form-control" type="email" id="account-email" value="" disabled />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-phone">Teléfono</label>
                        <input className="form-control" type="text" id="account-phone" value="" required />
                    </div>
                </div>
                {/* <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-pass">New Password</label>
                        <input className="form-control" type="password" id="account-pass" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-confirm-pass">Confirm Password</label>
                        <input className="form-control" type="password" id="account-confirm-pass" />
                    </div>
                </div> */}
                <div className="col-12">
                    <hr className="mt-2 mb-3" />
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className="custom-control custom-checkbox d-block">
                            <input className="custom-control-input" type="checkbox" id="subscribe_me" />
                            <label className="custom-control-label" htmlFor="subscribe_me">Subscribe me to Newsletter</label>
                        </div>
                        <button className="btn btn-primary margin-right-none" type="button" data-toast data-toast-position="topRight" data-toast-type="success" data-toast-icon="icon-circle-check" data-toast-title="Success!" data-toast-message="Your profile updated successfuly.">Update Profile</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}

export default Profile;