import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../components/Forms/FormInput';
import { fetchProductStart } from './../../redux/Products/products.actions';
import { Carousel } from 'react-responsive-carousel';

const mapState = state => ({
    product: state.productsData.product
});

const ProductInfo = () => {
    const dispatch = useDispatch();
    const [productId, setProductId] = useState('');
    const { product } = useSelector(mapState);

    const {
        localProductURL,
        localProductURL2,
        localProductURL3,
        localProductURL4,
        localProductURL5,
        productThumbnail,
        productThumbnail2,
        productThumbnail3,
        productThumbnail4,
        productThumbnail5,
        brand,
        productName,
        productNormalPrice,
        shortDesc,
        productAvailability,
        productCategory,
        productDesc,
        productPrice,
        profitMargin,
        purchasePrice,
        sku
    } = product

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log(productId);
        if (productId) {
            dispatch(
                fetchProductStart(productId)
            );
        }
    }

    useEffect(() => {
    }, [product])

    return (
        <>
            <div>
                <h3>Ingresar Id Producto:</h3>
                <form onSubmit={handleFormSubmit} className='needs-validations'>
                    <div className='row'>
                        <FormInput
                            label="Codigo Interno de Producto"
                            placeholder="Ingresar Codigo de Producto"
                            type="text"
                            name="productId"
                            value={productId}
                            handleChange={e => setProductId(e.target.value)}
                            required
                        />
                    </div>
                    <div className='row'>
                        <button type="submit" className="btn btn-primary btn-sm">
                            <span className="hidden-xs-down">Buscar&nbsp;</span><i className="icon-search"></i>
                        </button>
                    </div>
                </form>
            </div>
            {product &&
                <div className="container padding-bottom-3x mb-1">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="carousel-wrapper">
                                {!productThumbnail && !localProductURL &&
                                    < Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                        <div>
                                            <img src="assets/img/white.png" alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {localProductURL && !localProductURL2 && !localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                        <div>
                                            <img src={localProductURL} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {localProductURL && localProductURL2 && !localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                        <div>
                                            <img src={localProductURL} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL2} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {localProductURL && localProductURL2 && localProductURL3 && !localProductURL4 && !localProductURL5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                        <div>
                                            <img src={localProductURL} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL2} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL3} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {localProductURL && localProductURL2 && localProductURL3 && localProductURL4 && !localProductURL5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                        <div>
                                            <img src={localProductURL} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL2} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL3} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL4} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {localProductURL && localProductURL2 && localProductURL3 && localProductURL4 && localProductURL5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                        <div>
                                            <img src={localProductURL} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL2} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL3} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL4} alt="" />
                                        </div>
                                        <div>
                                            <img src={localProductURL5} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {!localProductURL && productThumbnail && !productThumbnail2 && !productThumbnail3 && !productThumbnail4 && !productThumbnail5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false}>
                                        <div>
                                            <img src={productThumbnail} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {!localProductURL && productThumbnail && productThumbnail2 && !productThumbnail3 && !productThumbnail4 && !productThumbnail5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false} >
                                        <div>
                                            <img src={productThumbnail} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail2} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {!localProductURL && productThumbnail && productThumbnail2 && productThumbnail3 && !productThumbnail4 && !productThumbnail5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false} >
                                        <div>
                                            <img src={productThumbnail} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail2} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail3} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {!localProductURL && productThumbnail && productThumbnail2 && productThumbnail3 && productThumbnail4 && !productThumbnail5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false} >
                                        <div>
                                            <img src={productThumbnail} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail2} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail3} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail4} alt="" />
                                        </div>
                                    </Carousel>
                                }
                                {!localProductURL && productThumbnail && productThumbnail2 && productThumbnail3 && productThumbnail4 && productThumbnail5 &&
                                    <Carousel showStatus={false} showIndicators={false} showArrows={false} >
                                        <div>
                                            <img src={productThumbnail} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail2} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail3} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail4} alt="" />
                                        </div>
                                        <div>
                                            <img src={productThumbnail5} alt="" />
                                        </div>
                                    </Carousel>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="padding-top-1x text-normal">{productName}</h3>
                        <hr className="mb-3" />
                        <h3 className="text-normal">Descripción</h3>
                        <span
                            className="desc"
                            dangerouslySetInnerHTML={{ __html: shortDesc }} />
                        <br /><br />
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="pt-1 mb-2"><span className="text-medium">Precio Venta: </span>
                                <span className="h2 d-block">
                                    {productPrice && (Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </div>
                            <div className="pt-1 mb-2"><span className="text-medium">Precio de Compra: </span>
                                <span className="h2 d-block">
                                    {purchasePrice && (Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(purchasePrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </div>
                        </div>
                        <hr className="mb-3" />
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="pt-1 mb-4"><span className="text-medium">Margen de Ganancia: </span>{profitMargin}</div>
                            <div className="pt-1 mb-4"><span className="text-medium">Marca: </span>{brand}</div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="pt-1 mb-4"><span className="text-medium">Disponibilidad: </span>{productAvailability}</div>
                            <div className="pt-1 mb-4"><span className="text-medium">Categoria: </span>{productCategory}</div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="pt-1 mb-4"><span className="text-uppercase">SKU: </span>{sku}</div>
                            <div className="pt-1 mb-4"><span className="text-medium">Precio Normal Si está ofertado: </span>{productNormalPrice}</div>
                        </div>
                        <hr className="mb-3" />
                        <h2 className="padding-top-1x text-normal">Características</h2>
                        <div className="tab-pane fade show active" id="description" role="tabpanel" dangerouslySetInnerHTML={{ __html: productDesc }}>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ProductInfo;