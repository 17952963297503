export const existingWishItem = ({
    prevWishItems,
    nextWishItem
}) => {
    return prevWishItems.find(
        wishItem => wishItem.documentID === nextWishItem.documentID
    );
};

export const handleAddToWish = ({
    prevWishItems,
    nextWishItem
}) => {
    const quantityIncrement = 1;
    const wishItemExists = existingWishItem({ prevWishItems, nextWishItem });

    if (wishItemExists) {
        return prevWishItems.map(wishItem =>
            wishItem.documentID === nextWishItem.documentID
                ? {
                    ...wishItem,
                    quantity: wishItem.quantity + quantityIncrement
                } : wishItem
        );
    }

    return [
        ...prevWishItems,
        {
            ...nextWishItem,
            quantity: quantityIncrement
        }
    ];
};

export const handleRemoveWishItem = ({
    prevWishItems,
    wishItemToRemove
}) => {
    return prevWishItems.filter(item => item.documentID !== wishItemToRemove.documentID);
}

export const handleReduceWishItem = ({
    prevWishItems,
    wishItemToReduce
}) => {
    const existingWishItem = prevWishItems.find(wishItem =>
        wishItem.documentID === wishItemToReduce.documentID);

    if (existingWishItem.quantity === 1) {
        return prevWishItems.filter(
            wishItem => wishItem.documentID !== existingWishItem.documentID
        );
    }

    return prevWishItems.map(wishItem =>
        wishItem.documentID === existingWishItem.documentID ?
            {
                ...wishItem,
                quantity: wishItem.quantity - 1
            } : wishItem)
};