import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { removeCartItem, addProduct, reduceCartItem } from './../../../redux/Cart/cart.actions';
import { Snackbar } from '@material-ui/core';

const Item = (product) => {
    const dispatch = useDispatch();
    const [displayError, setDisplayError] = useState(false);
    const {
        productName,
        productThumbnail,
        productPrice,
        quantity,
        documentID,
        productAvailability
    } = product;

    const handleRemoveCartItem = (documentID) => {
        dispatch(
            removeCartItem({
                documentID
            })
        );
    }

    const handleAddProduct = (product) => {
        if (productAvailability < quantity + 1) {
            setDisplayError(true);
            setTimeout(() => {
                setDisplayError(false)
            }, 6000);
        } else {
            dispatch(
                addProduct(product)
            )
        }
    }

    const handleReduceItem = (product) => {
        dispatch(
            reduceCartItem(product)
        );
    }

    const handleClose = () => {
        setDisplayError(false);
    }

    return (
        <tr>
            <td>
                <div className="product-item">
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={displayError}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message="No hay mas unidades disponibles"
                        key={'top' + 'right'}
                    />
                    <NavLink to={`/product/${documentID}`} className="product-thumb">
                        <img src={productThumbnail} alt={productName} />
                    </NavLink>
                    <div className="product-info">
                        <h4 className="product-title">
                            <NavLink to={`/product/${documentID}`}>
                                {productName}
                            </NavLink>
                        </h4>
                    </div>
                </div>
            </td>
            <td className="text-center">
                <button className="btn btn-link-info" type="button" onClick={() => handleReduceItem(product)} style={{ fontSize: 30 }}>-</button>
                <span className="text-center text-lg text-medium">
                    {quantity}
                </span>
                <button className="btn btn-link-info" type="button" onClick={() => handleAddProduct(product)} style={{ fontSize: 30 }}>+</button>
            </td>
            <td className="text-center text-lg text-medium"><div className='column'>{'Q.' + Intl.NumberFormat().format(productPrice)}</div></td>
            {/* <td className="text-center text-lg text-medium">Q 0.00</td> */}
            <td className="text-center text-lg text-medium">{'Q.' + Intl.NumberFormat().format(productPrice * quantity)}</td>
            <td className="text-center"><p className="remove-from-cart" data-toggle="tooltip" title="Remove item"><i className="icon-cross" onClick={() => handleRemoveCartItem(documentID)}></i></p></td>
        </tr>



    );
}

export default Item;