import React from 'react';
import TicketsDetails from '../../components/TicketsDetails';

const Tickets = () => {

    return (
        <div>
            <TicketsDetails />
        </div>
    )
}

export default Tickets;