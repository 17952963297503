import React, { useEffect, useState } from 'react'
import FormInput from '../../components/Forms/FormInput';
import { getOrderDetailsStart } from '../../redux/Orders/orders.actions';
import { useDispatch, useSelector } from 'react-redux';
import StatusOrderAdminDetail from '../../components/StatusOrderAdminDetail';

// const mapState = ({ ordersData }) => ({
//   orderDetails: ordersData.orderDetails
// });

const mapState = (state) => ({
  orderDetails: state.orderDetails
});

const StatusOrderAdmin = () => {
  const [orderId, setOrderId] = useState('');
  const dispatch = useDispatch();
  const { orderDetails } = useSelector(mapState);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsStart(orderId))
    }
  }, [])

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(orderId);
    getOrderDetail(orderId);
  }

  const getOrderDetail = (orderId) => {
    dispatch(
      getOrderDetailsStart(orderId)
    );
  }

  return (
    <>
      <div>
        <h2>Ingresar No. de Orden</h2>
        <form onSubmit={handleFormSubmit} className='needs-validations'>
          <div className='row'>
            <FormInput
              label="No. de Orden"
              placeholder="Ingresar No de Orden"
              type="text"
              name="orderId"
              value={orderId}
              handleChange={e => setOrderId(e.target.value)}
              required
            />
          </div>
          <div className='row'>
            <button type="submit" className="btn btn-primary btn-sm">
              <span className="hidden-xs-down">Consultar&nbsp;</span><i className="icon-search"></i>
            </button>
          </div>
        </form>
        &nbsp;&nbsp;
        <h3>Orden: {orderId}</h3>

        <StatusOrderAdminDetail order={orderDetails} />
      </div>
    </>
  )
}

export default StatusOrderAdmin;