import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUpUserStart } from "./../../redux/User/user.actions";
import "./styles.scss";

import FormInput from "./../Forms/FormInput";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
  userErr: user.userErr,
});

const Signup = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser, userErr } = useSelector(mapState);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (currentUser) {
      resetForm();
      history.push("/");
    }
  }, [currentUser]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
    setErrors([]);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    dispatch(
      signUpUserStart({
        firstName,
        lastName,
        displayName: firstName + " " + lastName,
        email,
        phone,
        password,
        confirmPassword,
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-title">
        <div className="container">
          <div className="column">
            <h1>Register Account</h1>
          </div>
          <div className="column">
            <ul className="breadcrumbs">
              <li>
                <a href="index.html">Principal</a>
              </li>
              <li className="separator">&nbsp;</li>
              <li>
                <a href="account-orders.html">Mi Cuenta</a>
              </li>
              <li className="separator">&nbsp;</li>
              <li>Registrate</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container padding-bottom-3x mb-2">
        <div className="row">
          <div className="col-md-6">
            <div className="padding-top-3x hidden-md-up"></div>
            <h3 className="margin-bottom-1x">¿No tienes Cuenta? Registrate</h3>
            <p>
              Toma menos de 1 minuto.
            </p>
            {errors.length > 0 && (
              <div className="alert alert-danger alert-dismissible fade show text-center margin-bottom-1x">
                <span className="alert-close" data-dismiss="alert"></span>
                {errors.map((err, index) => {
                  return <li key={index}>{err}</li>;
                })}
              </div>
            )}
            <form className="row" onSubmit={handleFormSubmit}>
              <FormInput
                type="text"
                name="firstName"
                value={firstName}
                placeholder="Nombres"
                label="Nombre"
                handleChange={(e) => setFirstName(e.target.value)}
                required
              />

              <FormInput
                type="text"
                name="lastName"
                value={lastName}
                placeholder="Apellidos"
                label="Apellidos"
                handleChange={(e) => setLastName(e.target.value)}
                required
              />

              <FormInput
                type="email"
                name="email"
                value={email}
                placeholder="Correo Electrónico"
                label="Correo Electrónico"
                handleChange={(e) => setEmail(e.target.value)}
                required
              />

              <FormInput
                type="text"
                name="phone"
                value={phone}
                placeholder="Teléfono"
                label="Teléfono"
                handleChange={(e) => setPhone(e.target.value)}
                required
              />

              <FormInput
                type="password"
                name="password"
                value={password}
                placeholder="Contraseña"
                label="Contraseña"
                handleChange={(e) => setPassword(e.target.value)}
                required
              />

              <FormInput
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                placeholder="Confirma Contraseña"
                label="Confirmar Contraseña"
                handleChange={(e) => setConfirmPassword(e.target.value)}
                required
              />

              <div className="col-12 text-center text-sm-right">
                <button
                  className="btn btn-primary margin-bottom-none"
                  type="submit"
                >
                  Registrarse
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signup;
