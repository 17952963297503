import { auth } from './../../firebase/utils';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setProducts, setSpecialProducts, setProduct, fetchProductsStart } from './products.actions';
import {
    handleAddProduct, handleFetchProducts,
    handleFetchProduct, handleDeleteProduct,
    handleFetchSpecialProducts,
    handleFetchProductSku,
    handleUpdateProduct
} from './products.helpers';
import productsTypes from './products.types';

export function* addProduct({ payload }) {

    try {
        const timestamp = new Date();
        yield handleAddProduct({
            ...payload,
            productAdminUserUID: auth.currentUser.uid,
            createdDate: timestamp
        });
        yield put(
            fetchProductsStart()
        );


    } catch (err) {
        // console.log(err);
    }

}

export function* onAddProductStart() {
    yield takeLatest(productsTypes.ADD_NEW_PRODUCT_START, addProduct);
}

export function* updateProduct({ payload }) {
    try {
        const timestamp = new Date();
        yield handleUpdateProduct({
            ...payload,
            productAdminUserUID: auth.currentUser.uid,
            updateDate: timestamp
        })
        yield put(
            fetchProductsStart()
        );
    } catch (err) {
        console.log(err);
    }
}

export function* onUpdateProductStart() {
    yield takeLatest(productsTypes.UPDATE_PRODUCT_START, updateProduct);
}

export function* fetchProducts({ payload }) {
    try {
        const products = yield handleFetchProducts(payload);
        yield put(
            setProducts(products)
        );

    } catch (err) {
        // console.log(err);
    }
}

export function* onFetchProductsStart() {
    yield takeLatest(productsTypes.FETCH_PRODUCTS_START, fetchProducts);
}

export function* fetchSpecialProducts({ payload }) {
    try {
        const products = yield handleFetchSpecialProducts(payload);
        yield put(
            setSpecialProducts(products)
        );

    } catch (err) {
        // console.log(err);
    }
}

export function* onFetchSpecialProductsStart() {
    yield takeLatest(productsTypes.FETCH_SPECIAL_PRODUCTS_START, fetchSpecialProducts);
}


export function* deleteProduct({ payload }) {
    try {
        yield handleDeleteProduct(payload);
        yield put(
            fetchProductsStart()
        );

    } catch (err) {
        // console.log(err);
    }
}

export function* onDeleteProductStart() {
    yield takeLatest(productsTypes.DELETE_PRODUCT_START, deleteProduct);
}

export function* fetchProduct({ payload }) {
    try {
        const product = yield handleFetchProduct(payload);
        yield put(
            setProduct(product)
        );

    } catch (err) {
        // console.log(err);
    }
}

export function* fecthProductSku({ payload }) {
    try {
        const product = yield handleFetchProductSku(payload);
        yield put(
            setProduct(product)
        );
    } catch (err) {
        console.lor(err);
    }
}

export function* onFetchProductStart() {
    yield takeLatest(productsTypes.FETCH_PRODUCT_START, fetchProduct);
}

export function* onFetchProductStartSku() {
    yield takeLatest(productsTypes.FETCH_PRODUCT_START_SKU, fecthProductSku);
}

export default function* productsSagas() {
    yield all([
        call(onAddProductStart),
        call(onUpdateProductStart),
        call(onFetchProductsStart),
        call(onFetchSpecialProductsStart),
        call(onDeleteProductStart),
        call(onFetchProductStart),
        call(onFetchProductStartSku),
    ])
}