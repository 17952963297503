import React, { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCartItems, selectCartTotal, selectCartItemsCount } from './../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';
import './styles.scss';
import Item from './Item';

const mapState = createStructuredSelector({
    cartItems: selectCartItems,
    total: selectCartTotal,
    itemCount: selectCartItemsCount
});

const Checkout = () => {
    const history = useHistory();
    const { cartItems, total, itemCount } = useSelector(mapState);
    const [buttonShop, setButtonShop] = useState(true);

    useEffect(() => {
        if (itemCount < 1) {
            setButtonShop(false);
        }

        if (window.pageYOffset > 300) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }, [itemCount])

    const handleFormSubmit = async e => {
        e.preventDefault();
    }

    return (
        <React.Fragment>
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Carrito</h1>
                    </div>
                    <div className="column">
                        <ul className="breadcrumbs">
                            <li><NavLink to="/">Inicio</NavLink>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Carrito</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container padding-bottom-3x mb-1">
                <div className="table-responsive shopping-cart">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th className="text-center">Cantidad</th>
                                <th className="text-center"> Precio</th>
                                {/* <th className="text-center">Descuento</th> */}
                                <th className="text-center">Subtotal</th>
                                <th className="text-center">Quitar</th>
                            </tr>
                        </thead>

                        {cartItems.map((item, pos) => {
                            return (
                                <tbody key={pos}>

                                    <Item {...item} />

                                </tbody>
                            );
                        })}

                    </table>
                </div>

                <div className="shopping-cart-footer">
                    <div className="column">
                        <form className="coupon-form" onSubmit={handleFormSubmit} action>
                            <input className="form-control form-control-sm" type="text" placeholder="Código Cupon" required />
                            <button className="btn btn-outline-primary btn-sm" type="submit">Aplicar Cupon</button>
                        </form>
                    </div>
                    <div className="column text-lg">Total: <span className="text-medium">Q. {total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span></div>
                </div>

                <div className="shopping-cart-footer">
                    <div className="column"><NavLink to="/search" className="btn btn-outline-secondary"><i className="icon-arrow-left" onClick={() => history.goBack()}></i>&nbsp;Volver a Tienda</NavLink></div>
                    {buttonShop && <>
                        <div className="column"><NavLink to="/payment" className="btn btn-success" >Comprar</NavLink></div>
                    </>
                    }
                </div>
            </div>

        </React.Fragment>
    );
};

export default Checkout;