import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FormInput from './../../components/Forms/FormInput';
import { addCategoryStart, fetchCategorysStart } from './../../redux/Categorys/categorys.actions';

const CreateCategory = props => {
    const [categoryName, setCategoryName] = useState('');
    const dispatch = useDispatch();

    const resetForm = () => {
        setCategoryName('');
    }

    useEffect(() => {
        dispatch(
            fetchCategorysStart()
        );
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        dispatch(
            addCategoryStart({
                categoryName
            })
        );
        resetForm();
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <h2>Agregar Categoría</h2>
                <FormInput
                    label="Name"
                    type="text"
                    value={categoryName}
                    handleChange={e => setCategoryName(e.target.value)}
                />
                <button className="btn btn-primary btn-sm" type="submit">Agregar Categoria</button>
            </form>
        </div>
    );

}

export default CreateCategory;