import React, { useState } from 'react';

const ImageWithFallback = ({ src, fallbackSrc, alt }) => {
    const [imageSrc, setImageSrc] = useState(src);

    const handleImageError = () => {
        console.log('llega a handleImageError');
        setImageSrc(fallbackSrc);
    };

    return (
        <img
            src={imageSrc}
            alt={alt}
            onError={handleImageError}
            style={{ maxWidth: '100%' }}
        />
    );
};

export default ImageWithFallback;