import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProductsStart, deleteProductStart } from './../../redux/Products/products.actions';
import LoadMore from './../../components/LoadMore';
import './styles.scss';

const mapState = ({ productsData }) => ({
    products: productsData.products
});

const Admin = props => {
    const { products } = useSelector(mapState);
    const dispatch = useDispatch();

    const { data, queryDoc, isLastPage } = products;

    useEffect(() => {
        dispatch(
            fetchProductsStart()
        );
    }, []);


    const handleLoadMore = () => {
        dispatch(
            fetchProductsStart({
                startAfterDoc: queryDoc,
                persistProducts: data
            })
        );
    };

    const configLoadMore = {
        onLoadMoreEvt: handleLoadMore,
    };

    return (
        <div>
            <div className="col-lg-8">
                <div className="table-responsive wishlist-table margin-bottom-none">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre Producto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(Array.isArray(data) && data.length > 0) && data.map((product, index) => {
                                const {
                                    productName,
                                    productThumbnail,
                                    productPrice,
                                    documentID
                                } = product;


                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="product-item"><a className="product-thumb" href="shop-single.html"><img src={productThumbnail} alt="Product" /></a>
                                                <div className="product-info">
                                                    <h4 className="product-title"><a href="shop-single.html">{productName}</a></h4>
                                                    <div className="text-lg text-medium text-muted">{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center"><Link className="remove-from-cart" to="/" data-toggle="tooltip" title="Remove item"><i className="icon-cross" onClick={() => dispatch(deleteProductStart(documentID))}></i></Link></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {!isLastPage && (
                                        <LoadMore {...configLoadMore} />
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

export default Admin;