import { createSelector } from 'reselect';

export const selectWishData = state => state.wishData;

export const selectWishItems = createSelector(
    [selectWishData],
    wishData => wishData.wishItems
);

export const selectWishItemsCount = createSelector(
    [selectWishItems],
    wishItems =>
        wishItems.reduce(
            (quantity, wishItem) =>
                quantity + wishItem.quantity
            , 0)
);

export const selectWishTotal = createSelector(
    [selectWishItems],
    wishItems =>
        wishItems.reduce(
            (quantity, wishItem) =>
                quantity + wishItem.quantity * wishItem.productPrice,
            0)
);