import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from './../../Forms/Button';
import { useDispatch } from 'react-redux';
import { addProduct } from './../../../redux/Cart/cart.actions';
import { addWishProduct } from './../../../redux/Wish/wish.actions';

const ProductList = (product) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        documentID,
        productThumbnail,
        productName,
        productPrice,
        productNormalPrice,
        shortDesc
    } = product;
    if (!documentID || !productThumbnail || !productName ||
        typeof productPrice === 'undefined') return null;

    const configAddToCartBtn = {
        type: 'button'
    };

    const configAddToWishBtn = {
        type: 'button'

    };

    const handleAddToWish = (product) => {
        if (!product) return;
        dispatch(
            addWishProduct(product)
        );
        history.push('/wish');
    };


    const handleAddToCart = (product) => {
        if (!product) return;
        dispatch(
            addProduct(product)
        );
        history.push('/cart');
    };


    return (

        <React.Fragment>

            <div className="product-card product-list">
                <Link to={`/product/${documentID}`} className="product-thumb">
                    <img src={productThumbnail} alt={productName} />
                </Link>
                <div className="product-info">
                    <h3 className="product-title">
                        <Link to={`/product/${documentID}`}>
                            {productName}
                        </Link></h3>
                    <h4 className="product-price">
                        {productNormalPrice && <del>{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productNormalPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</del>}{(Intl.NumberFormat('es-gt', { style: 'currency', currency: 'GTQ', minimumFractionDigits: 2 }).format(productPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h4>
                    <span dangerouslySetInnerHTML={{ __html: shortDesc }} />
                    <div className="product-buttons">
                        {/* <button className="btn btn-outline-secondary btn-sm btn-wishlist" data-toggle="tooltip" title="Whishlist"><i className="icon-heart"></i></button>
                        <button className="btn btn-outline-primary btn-sm" data-toast data-toast-type="success" data-toast-position="topRight" data-toast-icon="icon-circle-check" data-toast-title="Product" data-toast-message="successfuly added to cart!">Add to Cart</button> */}
                        <Button {...configAddToWishBtn} onClick={() => handleAddToWish(product)} className="btn btn-outline-secondary btn-sm btn-wishlist" data-toggle="tooltip" title="Whishlist"><i className="icon-heart"></i></Button>
                        <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)} className="btn btn-outline-primary btn-sm" data-toast data-toast-type="success" data-toast-position="topRight" data-toast-icon="icon-circle-check" data-toast-title="Product" data-toast-message="agregado al carrito">
                            Agregar al Carrito
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
};

export default ProductList;