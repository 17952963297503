import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, fetchProductStartSku, updateProductStart } from './../../redux/Products/products.actions';
import FormInput from '../../components/Forms/FormInput';
import CKEditor from 'ckeditor4-react';
import Swal from 'sweetalert2';
import { storage } from '../../firebase/utils';
import { ImageSharp } from '@material-ui/icons';


const mapState = state => ({
    product: state.productsData.product
});

const UpdateProductSKU = () => {
    const dispatch = useDispatch();
    const [productId, setProductId] = useState('');
    const { product } = useSelector(mapState);
    const [productName, setProductName] = useState('');
    const [documentID, setDocumentID] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [productDesc, setProductDesc] = useState('');
    const [localProductVideo, setLocalProductVideo] = useState('');
    const [localProductTmp, setLocalProductTmp] = useState('');
    const [localProductTmp2, setLocalProductTmp2] = useState('');
    const [localProductTmp3, setLocalProductTmp3] = useState('');
    const [localProductTmp4, setLocalProductTmp4] = useState('');
    const [localProductTmp5, setLocalProductTmp5] = useState('');
    const [localProductTmp6, setLocalProductTmp6] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('llega a handleFormSubmit', productId);
        if (productId) {
            dispatch(
                fetchProductStartSku(productId)
            );
        }
    }

    useEffect(() => {
        setProductName('');
        console.log('product', product);
        if (product) {
            const { productName,
                documentID,
                shortDesc,
                productDesc,
                localProductVideo,
                localProductURL,
                localProductURL2,
                localProductURL3,
                localProductURL4,
                localProductURL5,
                localProductURL6
            } = product;
            if (productName) { setProductName(productName) };
            if (documentID) { setDocumentID(documentID) };
            if (shortDesc) { setShortDesc(shortDesc) };
            if (productDesc) { setProductDesc(productDesc) };
            if (localProductVideo) { setLocalProductVideo(localProductVideo) };
            if (localProductURL) { setLocalProductTmp(localProductURL) };
            if (localProductURL2) { setLocalProductTmp2(localProductURL2) };
            if (localProductURL3) { setLocalProductTmp3(localProductURL3) };
            if (localProductURL4) { setLocalProductTmp4(localProductURL4) };
            if (localProductURL5) { setLocalProductTmp5(localProductURL5) };
            if (localProductURL6) { setLocalProductTmp6(localProductURL6) };
        }
    }, [product])

    const saveImageToFirebase = (documentID, productCategory, productTmp, count) => {
        let image = '';

        return new Promise((resolve, reject) => {
            const uploadTask = storage.ref(`images/${productCategory}/${documentID + count}`).put(productTmp)
            uploadTask.on(
                "state_changed",
                _snapshot => { },
                error => {
                    console.log(error);
                },
                () => {
                    storage
                        .ref(`images/${productCategory}`)
                        .child(documentID + count)
                        .getDownloadURL()
                        .then(url => {
                            resolve(url);
                        })
                }
            )
        })

    }

    function checkUrl(string) {
        let givenURL;
        try {
            givenURL = new URL(string);
        } catch (error) {
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { documentID, productCategory } = product;

        let localProductURL = '';
        let localProductURL2 = '';
        let localProductURL3 = '';
        let localProductURL4 = '';
        let localProductURL5 = '';
        let localProductURL6 = '';

        dispatch(
            updateProductStart({
                documentID,
                productName,
                shortDesc,
                productDesc,
                localProductVideo
            })
        )

        if (!checkUrl(localProductTmp)) {
            if (documentID && localProductTmp) {
                saveImageToFirebase(documentID, productCategory, localProductTmp, '1')
                    .then(result => {
                        localProductURL = result
                        dispatch(
                            updateProductStart({
                                documentID,
                                localProductURL
                            })
                        )

                    })
            }
        }

        if (!checkUrl(localProductTmp2)) {
            if (documentID && localProductTmp2) {
                saveImageToFirebase(documentID, productCategory, localProductTmp2, '2')
                    .then(result => {
                        localProductURL2 = result
                        dispatch(
                            updateProductStart({
                                documentID,
                                localProductURL2
                            })
                        )

                    })
            }
        }

        if (documentID && localProductTmp3) {
            saveImageToFirebase(documentID, productCategory, localProductTmp3, '3')
                .then(result => {
                    localProductURL3 = result
                    dispatch(
                        updateProductStart({
                            documentID,
                            localProductURL3
                        })
                    )

                })
        }

        if (documentID && localProductTmp4) {
            saveImageToFirebase(documentID, productCategory, localProductTmp4, '4')
                .then(result => {
                    localProductURL4 = result
                    dispatch(
                        updateProductStart({
                            documentID,
                            localProductURL4
                        })
                    )

                })
        }

        if (documentID && localProductTmp5) {
            saveImageToFirebase(documentID, productCategory, localProductTmp5, '5')
                .then(result => {
                    localProductURL5 = result
                    dispatch(
                        updateProductStart({
                            documentID,
                            localProductURL5
                        })
                    )

                })
        }

        if (documentID && localProductTmp6) {
            saveImageToFirebase(documentID, productCategory, localProductTmp6, '6')
                .then(result => {
                    localProductURL6 = result
                    dispatch(
                        updateProductStart({
                            documentID,
                            localProductURL6
                        })
                    )

                })
        }

        showSuccess();
        resetForm();
    }

    const resetForm = () => {
        setProductId('');
        fetchProductStart();
        setProductName('');
        setShortDesc('');
        setProductDesc('');
        setDocumentID('');
        setLocalProductVideo('');
        setLocalProductTmp('');
        setLocalProductTmp2('');
        setLocalProductTmp3('');
        setLocalProductTmp4('');
        setLocalProductTmp5('');
        setLocalProductTmp6('');
    }

    const showSuccess = () => {
        Swal.fire('El producto fué creado correctamente', 'Guardado Correctamente', 'success');
    };

    return (
        <>
            <div>
                <h3>Ingresar Id Producto:</h3>
                <form onSubmit={handleFormSubmit} className='needs-validations'>
                    <div className='row'>
                        <FormInput
                            label="Codigo Interno de Producto"
                            placeholder="Ingresar Codigo de Producto"
                            type="text"
                            name="productId"
                            value={productId}
                            handleChange={e => setProductId(e.target.value)}
                            required
                        />
                    </div>
                </form>
                <div className='row'>
                    <button type="submit" className="btn btn-primary btn-sm">
                        <span className="hidden-xs-down" onClick={(e) => handleFormSubmit(e)}>Buscar&nbsp;</span><i className="icon-search"></i>
                    </button>
                    <button type="submit" className="btn btn-danger btn-sm">
                        <span className="hidden-xs-down" onClick={() => resetForm()}>Limpiar&nbsp;</span><i className="icon-close"></i>
                    </button>
                </div>
            </div>
            {product &&
                <div>
                    <form onSubmit={handleSubmit}>
                        <FormInput
                            label="Name"
                            type="text"
                            value={productName}
                            handleChange={e => setProductName(e.target.value)}
                            required
                        />
                        <label>Descripción Corta</label>
                        <CKEditor
                            label="Descripción Corta"
                            data={shortDesc}
                            onChange={evt => setShortDesc(evt.editor.getData())}
                        /><br />
                        <label>Características</label>
                        <CKEditor
                            label="Características"
                            data={productDesc}
                            onChange={evt => setProductDesc(evt.editor.getData())}
                        />
                        <br />
                        <FormInput
                            label="ID Video Youtube"
                            type="text"
                            value={localProductVideo}
                            handleChange={e => setLocalProductVideo(e.target.value)}
                        />

                        <div className='çard'>
                            <div className="form-group row">
                                <label>Imagen Producto 1</label>
                                <div className="col-10">
                                    {localProductTmp &&
                                        <img src={localProductTmp} alt="" width={200} />
                                    }
                                    <div className="custom-file">
                                        <input className="custom-file-input" type="file" id="file-input" onChange={(e) => setLocalProductTmp(e.target.files[0])} />
                                        {/* <label className="custom-file-label" htmlFor="file-input">Seleccionar archivo</label> */}
                                        <label className="custom-file-label" htmlFor="file-input">{localProductTmp.name ? localProductTmp.name : 'Seleccionar archivo'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='çard'>
                            <div className="form-group row">
                                <label>Imagen Producto 2</label>
                                <div className="col-10">
                                    {localProductTmp2 &&
                                        <img src={localProductTmp2} alt="" width={200} />
                                    }
                                    <div className="custom-file">
                                        <input className="custom-file-input" type="file" id="file-input2" onChange={(e) => setLocalProductTmp2(e.target.files[0])} />
                                        <label className="custom-file-label" htmlFor="file-input2">{localProductTmp2.name ? localProductTmp2.name : 'Seleccionar archivo'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='çard'>
                            <div className="form-group row">
                                <label>Imagen Producto 3</label>
                                <div className="col-10">
                                    {localProductTmp3 &&
                                        <img src={localProductTmp3} alt="" width={200} />
                                    }
                                    <div className="custom-file">
                                        <input className="custom-file-input" type="file" id="file-input3" onChange={(e) => setLocalProductTmp3(e.target.files[0])} />
                                        <label className="custom-file-label" htmlFor="file-input3">{localProductTmp3.name ? localProductTmp3.name : 'Seleccionar archivo'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='çard'>
                            <div className="form-group row">
                                <label>Imagen Producto 4</label>
                                <div className="col-10">
                                    {localProductTmp4 &&
                                        <img src={localProductTmp4} alt="" width={200} />
                                    }
                                    <div className="custom-file">
                                        <input className="custom-file-input" type="file" id="file-input4" onChange={(e) => setLocalProductTmp4(e.target.files[0])} />
                                        <label className="custom-file-label" htmlFor="file-input4">{localProductTmp4.name ? localProductTmp4.name : 'Seleccionar archivo'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='çard'>
                            <div className="form-group row">
                                <label>Imagen Producto 5</label>
                                <div className="col-10">
                                    {localProductTmp5 &&
                                        <img src={localProductTmp5} alt="" width={200} />
                                    }
                                    <div className="custom-file">
                                        <input className="custom-file-input" type="file" id="file-input5" onChange={(e) => setLocalProductTmp5(e.target.files[0])} />
                                        <label className="custom-file-label" htmlFor="file-input5">{localProductTmp5.name ? localProductTmp5.name : 'Seleccionar archivo'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />

                        <div className='çard'>
                            <div className="form-group row">
                                <label>Imagen Producto 6</label>
                                <div className="col-10">
                                    {localProductTmp6 &&
                                        <img src={localProductTmp6} alt="" width={200} />
                                    }
                                    <div className="custom-file">
                                        <input className="custom-file-input" type="file" id="file-input6" onChange={(e) => setLocalProductTmp6(e.target.files[0])} />
                                        <label className="custom-file-label" htmlFor="file-input6">{localProductTmp6.name ? localProductTmp6.name : 'Seleccionar archivo'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <div className="col-10">
                                <div className="custom-file">
                                    <input className="custom-file-input" type="file" id="file-input" />
                                    <label className="custom-file-label" htmlFor="file-input">Choose file 2...</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-10">
                                <div className="custom-file">
                                    <input className="custom-file-input" type="file" id="file-input" />
                                    <label className="custom-file-label" htmlFor="file-input">Choose file 3...</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-10">
                                <div className="custom-file">
                                    <input className="custom-file-input" type="file" id="file-input" />
                                    <label className="custom-file-label" htmlFor="file-input">Choose file 4...</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-10">
                                <div className="custom-file">
                                    <input className="custom-file-input" type="file" id="file-input" />
                                    <label className="custom-file-label" htmlFor="file-input">Choose file 5...</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-10">
                                <div className="custom-file">
                                    <input className="custom-file-input" type="file" id="file-input" />
                                    <label className="custom-file-label" htmlFor="file-input">Choose file 6...</label>
                                </div>
                            </div>
                        </div> */}
                        {/* <FormInput
                            label="Imagen Producto 1"
                            type="url"
                            value={localProductURL}
                            handleChange={e => setLocalProductURL(e.target.value)}
                        /> */}
                        {/* <FormInput
                            label="Imagen Producto 1"
                            type="url"
                            value={localProductURL}
                            handleChange={e => setLocalProductURL(e.target.value)}
                        />
                        <FormInput
                            label="Imagen Producto 2"
                            type="url"
                            value={localProductURL2}
                            handleChange={e => setLocalProductURL2(e.target.value)}
                        />
                        <FormInput
                            label="Imagen Producto 3"
                            type="url"
                            value={localProductURL3}
                            handleChange={e => setLocalProductURL3(e.target.value)}
                        />
                        <FormInput
                            label="Imagen Producto 4"
                            type="url"
                            value={localProductURL4}
                            handleChange={e => setLocalProductURL4(e.target.value)}
                        />
                        <FormInput
                            label="Imagen Producto 5"
                            type="url"
                            value={localProductURL5}
                            handleChange={e => setLocalProductURL5(e.target.value)}
                        />
                        <FormInput
                            label="Imagen Producto 6"
                            type="url"
                            value={localProductURL6}
                            handleChange={e => setLocalProductURL6(e.target.value)}
                        /> */}
                        <button className="btn btn-primary btn-sm" type="submit" disabled={!product}>Actualizar producto</button>
                    </form>
                </div>
            }
        </>
    )
}

export default UpdateProductSKU;