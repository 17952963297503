import React from 'react';
import AboutDetail from './../../components/AboutDetail';

const About = () => {
    return (

        <AboutDetail />

    );
}
export default About;
