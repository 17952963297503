
import React from 'react';
import ProductCard from './../../components/ProductCard';

const ProductDetails = ({ }) => {

    return (
        <>
            <ProductCard />
        </>
    );
}

export default ProductDetails;