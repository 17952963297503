import React from 'react';

const AddressFormSelect = ({ options, defaultValue, handleChange, label, ...otherProps }) => {
    if (!Array.isArray(options) || options.length < 1) return null;

    return (
        <div className="col-sm-6 form-group">
            {label && (
                <label htmlFor="sorting">
                    {label}
                </label>
            )}

            <select className="form-control" value={defaultValue} onChange={handleChange} {...otherProps}>
                {options.map((option, index) => {
                    const { value, name } = option;

                    return (
                        <option key={index} value={value}>{name}</option>
                    );
                })}
            </select>
        </div>
    );
}

export default AddressFormSelect;