import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Directory from "../../components/Directory";


const Homepage = () => {
  return (
    <section className="homepage">
      <Directory />
    </section>
  )
}
  ;
export default Homepage;
