import React from "react";
import "./styles.scss";

const FormInput = ({ handleChange, label, ...otherProps }) => {
  return (
    <div className="col-sm-6">
      <div className="form-group">
        {label && <label>{label}</label>}
        <input
          className="form-control"
          onChange={handleChange}
          {...otherProps}
        />
      </div>
    </div>
  );
};

export default FormInput;
