import React, { useState, useEffect } from 'react';
import { data } from '../../assets/data/departments';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';

const initialAddressState = {
    line1: '',
    line2: '',
    nit: '',
    city: '',
    state: '',
    postal_code: '',
    email: '',
    phone: ''
};

const AddressDetails = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionMunicipio, setSelectedOptionMunicipio] = useState(null);
    const [municipios, setMunicipios] = useState(['']);
    const [disableMunicipio, setDisableMunicipio] = useState(true);
    const [shippingAddress, setShippingAddress] = useState({ ...initialAddressState });

    const handleShippingDepartment = evt => {
        const { label, value } = evt;
        setSelectedOption(evt);
        setMunicipios(['']);
        setSelectedOptionMunicipio(null);

        setDisableMunicipio(false);

        setShippingAddress({
            ...shippingAddress,
            ['city']: label,
            ['state']: ''
        });

        getMunicipios(value);
    };

    const colourStyles = {
        control: styles => ({
            ...styles,
            padding: '0 18px 3px',
            border: '1px solid #dbe2e8',
            borderRadius: '22px',
            backgroundColor: '#ffffff',
            color: '#606975',
            fontFamily: '"Maven Pro", Helvetica, Arial, sans-serif',
            fontSize: '14px',
            webkitAppearance: 'none',
            mozAppearance: 'none',
            appearance: 'none'
        })
    }

    const handleShippingMunicipio = evt => {

        const { label } = evt;

        setSelectedOptionMunicipio(evt);

        setShippingAddress({
            ...shippingAddress,
            ['state']: label
        });

    };

    const getMunicipios = async (idDepto) => {
        return axios.get('assets/data/municipios.json')
            .then(res => res.data.data)
            .then(data => {
                let infoMuni = data.filter(x => x.city == idDepto);
                setMunicipios(infoMuni);
            })

    }

    return (
        <React.Fragment>



            <h4>Dirección de Contacto</h4>
            <hr className="padding-bottom-1x" />
            <form className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-company">Compañia</label>
                        <input className="form-control" type="text" id="account-company" value="" />
                    </div>
                </div>
                <div className="col-sm-6 form-group">
                    <label htmlFor="sorting">
                        * Departamento
                    </label>
                    <CreatableSelect
                        value={selectedOption}
                        rules={{ required: true }}
                        name="city"
                        styles={colourStyles}
                        options={data}
                        onChange={evt => handleShippingDepartment(evt)}
                    />
                </div>
                <div className="col-sm-6 form-group">
                    <label htmlFor="sorting">
                        * Municipio
                    </label>
                    <CreatableSelect
                        value={selectedOptionMunicipio}
                        rules={{ required: true }}
                        name="state"
                        styles={colourStyles}
                        options={municipios}
                        isDisabled={disableMunicipio}
                        onChange={evt => handleShippingMunicipio(evt)}

                    />
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-zip">Código Postal</label>
                        <input className="form-control" type="text" id="account-zip" required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-address1">Dirección 1</label>
                        <input className="form-control" type="text" id="account-address1" required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="account-address2">Dirección 2</label>
                        <input className="form-control" type="text" id="account-address2" />
                    </div>
                </div>
                <div className="col-12 padding-top-1x">
                    <h4>Dirección de Envío</h4>
                    <hr className="padding-bottom-1x" />
                    <div className="custom-control custom-checkbox d-block">
                        <input className="custom-control-input" type="checkbox" id="same_address" />
                        <label className="custom-control-label" htmlFor="same_address">Usar la misma que Dirección de Contacto</label>
                    </div>
                    <hr className="margin-top-1x margin-bottom-1x" />
                    <div className="text-right">
                        <button className="btn btn-primary margin-bottom-none" type="button" data-toast data-toast-position="topRight" data-toast-type="success" data-toast-icon="icon-circle-check" data-toast-title="Success!" data-toast-message="Your address updated successfuly.">Actualizar Dirección</button>
                    </div>
                </div>
            </form>

        </React.Fragment>
    )
}

export default AddressDetails;