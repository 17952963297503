import React from 'react';
import CheckoutWish from '../../components/CheckoutWish';

const Wish = () => {


    return (

        <div>
            <CheckoutWish />
        </div>

    );

}

export default Wish;