import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link, NavLink } from 'react-router-dom';
import { fetchProductsStart } from './../../redux/Products/products.actions';
import Product from './Product';
import ProductList from './ProductList';
import ProductSearchList from './ProductList';
import FormSelect from './../Forms/FormSelect';
import LoadMore from './../LoadMore';
import algoliasearch from 'algoliasearch/lite';
import ProductSearch from './ProductSearch';
import InfiniteScroll from 'react-infinite-scroll-component';

const mapState = ({ productsData }) => ({
    products: productsData.products
});

const searchClient = algoliasearch('KDYSV5MBNR', '5cd08e207c3ed90ce0f281da4e17a979');

const searchIndex = searchClient.initIndex('mishop.gt');

const ProductResults = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { filterType } = useParams();
    const { products } = useSelector(mapState);
    const [searchText, setSearchText] = useState('');
    const [searchResult, setSearchResult] = useState(null);

    const { data, queryDoc, isLastPage } = products;
    const [productViewGrid, setProductViewGrid] = useState('grid-view active');
    const [productViewList, setProductViewList] = useState('list-view')

    const handleView = ((option) => {
        if (option === 'GRID') {
            setProductViewGrid('grid-view active');
            setProductViewList('list-view');
        }
        if (option === 'LIST') {
            setProductViewGrid('grid-view');
            setProductViewList('list-view active');
        }
    })

    useEffect(() => {
        let urlText = filterType;
        let searchValue = "";

        if (urlText && data) {
            urlText = urlText.substr(0, 2);
            searchValue = filterType.replace("p=", "");
        } else {
            dispatch(
                fetchProductsStart({ filterType })
            )
        }

        if (urlText == undefined) {
            history.push('/');
        }

        if (urlText == "p=") {
            handleSearch1(searchValue)
        } else {
            if (searchValue == '') {
                history.push('/search');
            } else {
                dispatch(
                    fetchProductsStart({ filterType })
                )
            }
        }

        if (searchValue == '') setSearchResult(null);
    }, [filterType]);

    // useEffect(() => {
    //     if (window.pageYOffset > 300) {
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //     }
    // })


    const handleSearch1 = async (queryText) => {
        try {
            const result = await searchIndex.search(queryText);
            result.hits.sort((a, b) => {
                if (parseInt(a.productAvailability) > 0) {
                    if (parseFloat(a.productPrice) < parseFloat(b.productPrice)) {
                        return -1;
                    }
                    if (parseFloat(a.productPrice) > parseFloat(b.productPrice)) {
                        return 1;
                    }
                    return 0;
                }
            })
            setSearchResults(result.hits);
        } catch (error) {
            console.log('error');
        }
    }

    const setSearchResults = (valor) => {
        setSearchResult(valor);
    }

    const handleFilter = (e) => {
        setSearchResult(null);
        const nextFilter = e.target.value;
        history.push(`/search/${nextFilter}`);
    };

    const configFilters = {
        defaultValue: filterType,
        options: [{
            name: 'Elige Categoría',
            value: ''
        },
        {
            name: 'Promociones',
            value: 'promotions'
        },
        {
            value: "accesories",
            name: "Accesorios de Computadora"
        }, {
            value: "networkaccesories",
            name: "Accesorios y Conectores de Red"
        }, {
            value: "tvaccesories",
            name: "Accesorios para TV y Monitores"
        }, {
            value: "storage",
            name: "Almacenamiento"
        }, {
            value: "audio",
            name: "Audio y Video"
        },
        // {
        //     value: "headphones",
        //     name: "Auriculares"
        // }, 
        {
            value: "cameras",
            name: "Cámaras y VideoCámaras"
        }, {
            value: "components",
            name: "Componentes Informáticos"
        }, {
            value: "computers",
            name: "Computadoras de Escritorio"
        }, {
            value: "notebooks",
            name: "Computadoras Portátiles"
        }, {
            value: "communications",
            name: "Comunicaciones"
        }, {
            value: "consumables",
            name: "Consumibles y Media"
        }, {
            value: "personalcare",
            name: "Cuidado Personal"
        },
        {
            value: "accesscontrol",
            name: "Control de Acceso"
        },
        // {
        //     value: "digitaldownloads",
        //     name: "Descargas Digitales"
        // }, 
        {
            value: "detectiondevices",
            name: "Detección de Incendios"
        }, {
            value: "techtoys",
            name: "Drones y Accesorios"
        }, {
            value: "homeappliances",
            name: "Electrodomésticos"
        },
        // {
        //     value: "enterprise",
        //     name: "Enterprise"
        // }, 
        {
            value: "printers",
            name: "Impresoras y Escáneres"
        }, {
            value: "intrusion",
            name: "Intrusión"
        }, {
            value: "memories",
            name: "Memorias"
        }, {
            value: "backpacks",
            name: "Mochilas y Estuches"
        }, {
            value: "monitors",
            name: "Monitores"
        }, {
            value: "forniture",
            name: "Muebles"
        }, {
            value: "powerprotection",
            name: "Protectores de Poder"
        }, {
            value: "projectors",
            name: "Proyectores"
        }, {
            value: "pointsofsale",
            name: "Puntos de Venta"
        }, {
            value: "Periféricos",
            name: "Periféricos"
        },
        // {
        //     value: "Random",
        //     name: "Random"
        // }, 
        {
            value: "networks",
            name: "Redes"
        },
        // {
        //     value: "servers",
        //     name: "Servidores"
        // }, 
        {
            value: "chairs",
            name: "Sillas"
        }, {
            value: "Software",
            name: "Software"
        }, {
            value: "tablets",
            name: "Tablets"
        }, {
            value: "phones",
            name: "Teléfonos y Accesorios"
        }, {
            value: "television",
            name: "Televisores"
        }, {
            value: "videogames",
            name: "Videojuegos"
        }, {
            value: "videosurveillance",
            name: "Vigilancia de Video"
        },
        // , {
        //     value: "sports",
        //     name: "Accesorios Deportivos"
        // }, 
        {
            value: "home",
            name: "Hogar"
        }
        ],
        handleChange: handleFilter
    };

    if (!Array.isArray(data)) return null;
    if (data.length < 1) {
        return (
            <>
                <div className="page-title">
                    <div className="container">
                        <div className="column">
                            <h1>Productos</h1>
                        </div>
                        <div className="column">
                            <ul className="breadcrumbs">
                                <li><Link to="/">Inicio</Link>
                                </li>
                                <li className="separator">&nbsp;</li>
                                <li>Productos </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container padding-bottom-3x mb-1">
                    <div className="shop-toolbar padding-bottom-1x mb-2">
                        <div className="column col-sm-6 col-lg-6">
                            <FormSelect {...configFilters} />
                        </div>
                        <div className="column">
                            <div className="shop-view">
                                <a className={productViewGrid} onClick={() => handleView('GRID')}><span></span><span></span><span></span></a>
                                <a className={productViewList} onClick={() => handleView('LIST')}><span></span><span></span><span></span></a>
                            </div>
                        </div>

                    </div>

                    <div className="products">
                        <p>
                            Por el momento no tenemos productos para esta categoría, estamos trabajando para que puedas encontrar el producto que buscas.
                        </p>
                    </div>

                </div>

            </>
        );
    }

    const handleLoadMore = () => {
        dispatch(
            fetchProductsStart({
                filterType,
                startAfterDoc: queryDoc,
                persistProducts: data
            })
        )
    };

    const configLoadMore = {
        onLoadMoreEvt: handleLoadMore,
    };

    const handleSearch = async (queryText) => {
        try {
            const result = await searchIndex.search(queryText);
            setSearchResult(result.hits);
        } catch (error) {
            setSearchResult(null);

        }
    }

    return (
        <React.Fragment>
            <div className="page-title">
                <div className="container">
                    <div className="column">
                        <h1>Productos</h1>
                    </div>
                    <div className="column">
                        <ul className="breadcrumbs">
                            <li><Link to="/">Inicio</Link>
                            </li>
                            <li className="separator">&nbsp;</li>
                            <li>Productos </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* BANNER PROMOCIONES */}
            {/* <section className="section-web">
                <div className="container padding-bottom-2x mb-2">
                    <div className="row">
                        <div className="col-lg-12">
                            <NavLink to="/search/promotions"><div className="alert alert-image-bg alert-dismissible fade show text-center mb-4" style={{ backgroundImage: "url(" + "assets/img/banners/black_friday.jpg" + ")", height: "108px" }}>
                                
                            </div></NavLink>
                        </div>
                    </div>
                </div>
            </section> */}
            <div className="container padding-bottom-3x mb-1">
                {/* <div className="row" style={{ justifyContent: "center", display: "flex" }}>
                    <div className="column col-sm-6 cols-6 margin-bottom-1x" style={{ justifyContent: "center", display: "flex" }}>
                        <div className="input-group" style={{ width: "100%" }}>
                            <span className="input-group-btn"><button type="submit"><i className="icon-search"></i></button></span>
                            <input type="search" className="form-control" placeholder="Busca tu producto y presiona Enter" onChange={e => setSearchText(e.target.value)} value={searchText} onKeyDown={(e) => {
                                if (e.key === 'Enter') handleSearch(searchText)
                            }} />
                        </div>
                    </div>
                </div> */}
                <div className="shop-toolbar padding-bottom-1x mb-2">
                    <div className="column col-sm-6 col-lg-6">
                        <FormSelect {...configFilters} />
                    </div>
                    <div className="column">
                        <div className="shop-view">
                            <a className={productViewGrid} onClick={() => handleView('GRID')}><span></span><span></span><span></span></a>
                            <a className={productViewList} onClick={() => handleView('LIST')}><span></span><span></span><span></span></a>
                        </div>
                    </div>

                </div>
                <div className="isotope-grid cols-3 mb-2">
                    <div className="gutter-sizer"></div>
                    <div className="grid-sizer"></div>

                    <InfiniteScroll
                        dataLength={data.length}
                        next={handleLoadMore}
                        hasMore={true}
                    >
                        {searchResult && searchResult.length > 0 ? (
                            searchResult.map((product, pos) => {
                                const { productThumbnail, productName, productPrice, localProductURL } = product;
                                if (!productName ||
                                    // typeof productPrice === 'undefined' || !productThumbnail || productThumbnail == null || productThumbnail === "") return null;
                                    // typeof productPrice === 'undefined' || (!productThumbnail && !localProductURL) || (productThumbnail == null && localProductURL == null) || (productThumbnail === "" && localProductURL === "") || (productThumbnail === undefined && localProductURL === undefined)) return null;
                                    typeof productPrice === 'undefined') return null;
                                const configProduct = {
                                    ...product
                                };

                                if (productViewGrid === 'grid-view active') {
                                    return (
                                        <ProductSearch key={pos} {...configProduct} />
                                    );
                                }
                                if (productViewGrid === 'grid-view') {
                                    return (
                                        <ProductSearchList key={pos} {...configProduct} />
                                    );
                                }


                            }))
                            : data.length < 1 ? (
                                <h2>No hay productos</h2>
                            ) : (

                                data.map((product, pos) => {
                                    const { productThumbnail, productName, productPrice, localProductURL, productCategory } = product;
                                    if (filterType && (filterType != productCategory)) {
                                        return null;
                                    }
                                    if (!productName ||
                                        // typeof productPrice === 'undefined' || !productThumbnail || productThumbnail == null || productThumbnail === "" || productThumbnail === undefined) return null;
                                        // typeof productPrice === 'undefined' || (!productThumbnail && !localProductURL) || (productThumbnail == null && localProductURL == null) || (productThumbnail === "" && localProductURL === "") || (productThumbnail === undefined && localProductURL === undefined)) return null;
                                        // if (!productThumbnail || !productName ||
                                        typeof productPrice === 'undefined') return null;
                                    const configProduct = {
                                        ...product
                                    };

                                    if (productViewGrid === 'grid-view active') {
                                        return (
                                            <Product key={pos} {...configProduct} />
                                        );
                                    }
                                    if (productViewGrid === 'grid-view') {
                                        return (
                                            <ProductList key={pos} {...configProduct} />
                                        );
                                    }
                                })
                            )}
                    </InfiniteScroll>

                </div>
                {/* {!searchResult && <nav className="pagination">

                    {!isLastPage && (
                        <LoadMore className="btn btn-outline-secondary btn-sm" {...configLoadMore} />
                    )}
                </nav>} */}
                {searchResult &&
                    <div className="margin-bottom-1x text-right">
                        <img src="assets/img/logo/algolia-blue-mark.png" alt="Algolia" />
                    </div>
                }

            </div>
        </React.Fragment >
    );
};

export default ProductResults;