import React from 'react';
import ProductResultsAl from '../../components/ProductResultsAl';

const SearchAl = () => {
    return (

        <ProductResultsAl />

    );
}
export default SearchAl;
