import wishTypes from './wish.types';
import {
    handleAddToWish, handleRemoveWishItem,
    handleReduceWishItem
} from './wish.utils';

const INITIAL_STATE = {
    wishItems: []
};

const wishReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case wishTypes.ADD_TO_WISH:
            return {
                ...state,
                wishItems: handleAddToWish({
                    prevWishItems: state.wishItems,
                    nextWishItem: action.payload
                })
            };
        case wishTypes.REDUCE_WISH_ITEM:
            return {
                ...state,
                wishItems: handleReduceWishItem({
                    prevWishItems: state.wishItems,
                    wishItemToReduce: action.payload
                })
            };
        case wishTypes.REMOVE_WISH_ITEM:
            return {
                ...state,
                wishItems: handleRemoveWishItem({
                    prevWishItems: state.wishItems,
                    wishItemToRemove: action.payload
                })
            };
        default:
            return state;
    }
};

export default wishReducer;
