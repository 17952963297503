import { firestore } from './../../firebase/utils';

export const handleSaveOrder = order => {
    return new Promise((resolve, reject) => {
        let ref = firestore.collection('orders');

        ref.add(order)
            .then(function (docRef) {
                resolve(docRef.id);
            })
            .catch(function (error) {
                // console.log("Error adding document: ", error);
            })
    });
}

export const handleGetUserOrderHistory = uid => {
    return new Promise((resolve, reject) => {
        let ref = firestore.collection('orders').orderBy('orderCreatedDate');
        ref = ref.where('orderUserID', '==', uid);

        ref
            .get()
            .then(snap => {
                const data = [
                    ...snap.docs.map(doc => {
                        return {
                            ...doc.data(),
                            documentID: doc.id
                        }
                    })
                ];

                resolve({ data });
            })
            .catch(err => {
                reject(err);
            });


    });
};

export const handleGetOrder = orderID => {
    return new Promise((resolve, reject) => {
        firestore
            .collection('orders')
            .doc(orderID)
            .get()
            .then(snap => {
                if (snap.exists) {
                    resolve({
                        ...snap.data(),
                        documentID: orderID
                    })
                }
            })
            .catch(err => {
                reject(err);
            })
    })
}